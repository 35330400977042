import { PROPS } from './approvals.const';
import { updateObject } from '../utils/updateObject';

export const setRequestLoading = (state, flag) => {
  const obj = {
    [PROPS.LOADING] : flag
  };
  return updateObject(state, obj);
};


export const bookingDataSuccess = (state, data) => {
  let obj = {
    [PROPS.BOOKING_REQUEST]: data.bookingRequest,
    [PROPS.PLACE_LIST]: data.approvablePlaceList,
    [PROPS.LOADING] : false
  };
  return updateObject(state, obj);
};

export const bookingDataError = (state, error) => {
  let obj = {
    [PROPS.ERROR]: error,
    [PROPS.LOADING] : false
  };
  return updateObject(state, obj);
};

export const bookingApprovalSuccess = (state) => {
  let obj = {
    [PROPS.APPROVAL_RESPONSE]: true,
    [PROPS.LOADING] : false
  };
  return updateObject(state, obj);
};