import { put, takeEvery, all } from 'redux-saga/effects';
import { LOGIN_REQUEST } from '../auth.actionTypes';
import { loginSuccess, decodeAuthToken } from '../auth.actions';
import { saveToken } from '../../session/session.actions';
import { requestConfig } from '../../config/config.actions';

export function * login(action) {
  const tokens = action.tokens;
  yield all ([
    put(loginSuccess(tokens)),
    put(decodeAuthToken(tokens)),
    put(saveToken(tokens)),
    put(requestConfig())
  ]);
}

function * loginSaga() {
  yield takeEvery(LOGIN_REQUEST, login);
}


export default loginSaga;