import { createSelector } from 'reselect';
import { PROPS } from './resources.const';

const getResourceSelector = state => state[PROPS.ROOT] || {};

export const getResourceListSelector = createSelector(
  [getResourceSelector], (resource) => resource[PROPS.HIERARCHY_LIST] || []
);

export const getResourceErrorSelector = createSelector(
  [getResourceSelector], (resource) => resource[PROPS.ERROR]
);

export const getResourceLoadingSelector = createSelector(
  [getResourceSelector], (resource) => resource[PROPS.LOADING]
);

export const getResourceSearchSelector = createSelector(
  [getResourceSelector], (resource) => resource[PROPS.SEARCH_RESULTS] || {}
);

export const getSchoolSelector = createSelector(
  [getResourceSelector], (resource) => resource[PROPS.SCHOOLS] || []
);

export const getDistrictObjectSelector = createSelector(
  [getResourceSelector], (resource) => resource[PROPS.DISTRICT]
);


export const getFetchLatestListSelector = createSelector(
  [getResourceSelector], (resource) => resource[PROPS.FETCH_LATEST_LIST]
);