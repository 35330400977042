import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DesktopComponent from './desktop/header.desktop';
import MobileComponent from './mobile/header.mobile';
import {requestLogout} from '../../store/auth/auth.actions';
import {requestChangeTheme} from '../../store/config/config.actions';
import {getDecodedTokenSelector} from '../../store/auth/auth.selector';
import {getIsMobileSelector, getCurrentTheme} from '../../store/config/config.selector';
import selectDeviceLayout from '../../utils/selectDeviceLayout/selectDeviceLayout';

const mapStateToProps = (state) => {
  return {
    profileInfo: getDecodedTokenSelector(state),
    isMobile: getIsMobileSelector(state),
    currentTheme: getCurrentTheme(state)
  };
};

const mapDispatchToProps = {
  requestLogout,
  requestChangeTheme
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(selectDeviceLayout(DesktopComponent, MobileComponent)));
