import { createSelector } from 'reselect';
import { PROPS } from './microFrontend.const';

export const getMicroFrontendSelector = state => state[PROPS.ROOT] || {};

export const getMicroFrontendLoaderSelector = createSelector(
  [getMicroFrontendSelector], (mf) => mf[PROPS.LOADING]
);

export const getMicroFrontendManifestSelector = createSelector(
  [getMicroFrontendSelector], (mf) => mf[PROPS.MANIFESTS] || {}
);
