import React from 'react';
import HeaderContainer from '../../header/header.container';
import BrandingContainer from '../../branding/branding.container';
import If from '../../ifComponent/ifComponent';
import BaseComponent from '../importUtility.base';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import RoomOutlined from '@material-ui/icons/RoomOutlined';
import BackupIcon from '@material-ui/icons/Backup';
import ListIcon from '@material-ui/icons/List';
import IconButton from '@material-ui/core/IconButton';
import Publish from '@material-ui/icons/Publish';

import ArrowBack from '@material-ui/icons/ArrowBack';
import { REQUEST_TYPES } from '../importUtility.const';
import { IconSizes } from '../../../app.const';

export default class ImportutilityMobileComponent extends BaseComponent {

  renderImportTypeDropdown = () => {
    const { importType } = this.state;
    const menuItems = Object.values(REQUEST_TYPES).map(obj => {
      return <MenuItem key={obj.value} value={obj.value}>{obj.label}</MenuItem>;
    });
    return (
      <ListItem>
        <ListItemAvatar>
          <ListIcon color='primary' fontSize={IconSizes.Large} />
        </ListItemAvatar>
        <FormControl>
          <InputLabel id='importType-label' className='select-dropdown-label' >Request Type</InputLabel>
          <Select labelId='importType-label' variant='filled' name='importType' required fullWidth
            value={importType} onChange={this.fieldUpdateHandler}>
            <MenuItem value='' disabled> Select request type </MenuItem>
            {menuItems}
          </Select>
        </FormControl>
      </ListItem>
    );
  }

  renderResourceDropdown = () => {
    return (
      <ListItem>
        <ListItemAvatar>
          <RoomOutlined color='primary' fontSize={IconSizes.Large} />
        </ListItemAvatar>
        <FormControl>
          <InputLabel id='placeId-label' className='select-dropdown-label'>Applicable to*</InputLabel>
          {this.getPlacesDropdown()}
        </FormControl>
      </ListItem>
    );
  }

  renderCsvUpload = () => {
    return (
      <ListItem>
        <ListItemAvatar>
          <BackupIcon color='primary' fontSize={IconSizes.Large} />
        </ListItemAvatar>
        <div className='import-utility-dropzone-container'>
          { this.getDropzone() }
        </div>
      </ListItem>
    );
  }

  renderSubmitButton = () => {
    const { disableButton } = this.state;
    return (
      <Grid item className='submit-button-container'>
        <Button endIcon={<Publish />} color='primary' variant='contained'
          className='submit-button' type='submit' disabled = {disableButton}>
          Upload
        </Button>
      </Grid>
    );
  }

  render() {
    const { importType } = this.state;
    const { resourceLoading, uploadLoading } = this.props;
    let loaderContent;
    if (resourceLoading || uploadLoading) {
      loaderContent = this.getLoaderView();
    }
    return (
      <form onSubmit={this.confirmSubmit}>
        <Container disableGutters className='container'>
          <HeaderContainer>
            Import utility
          </HeaderContainer>
          <div className='content'>
            {loaderContent}
            <Grid container direction='column' justify='flex-start' alignItems='stretch' className='import-utility'>
              <Grid item>
                <Typography component='div' className='sub-header'>
                  <IconButton color='primary' className='back-icon' onClick={this.goToHome}>
                    <ArrowBack />
                  </IconButton>
                  <Typography component='div' className='sub-header-text'>
                    Upload CSV
                  </Typography>
                </Typography>
              </Grid>
              <Grid item className='import-utility-form-container'>
                <List>
                  {this.renderImportTypeDropdown()}
                  <If condition={ importType === REQUEST_TYPES.BELL_SCHEDULE.value ||
                      importType === REQUEST_TYPES.ZONE_APPROVERS.value || importType === REQUEST_TYPES.USERS.value ||
                      importType === REQUEST_TYPES.VENSTAR_CONFIG.value}>
                    { this.renderResourceDropdown() }
                  </If>
                  {this.renderCsvUpload()}
                </List>
              </Grid>
            </Grid>
          </div>
          <div className='footer'>
            {this.renderSubmitButton()}
          </div>
          <BrandingContainer />
          {this.getSuccessDialog()}
          {this.getConfirmDialog()}
        </Container>
      </form>
    );
  }
}