import React from 'react';
import BaseComponent from '../scheduleDetails.base';
import HeaderContainer from '../../header/header.container';
import BrandingContainer from '../../branding/branding.container';
import DateTimeComponent from '../../dateTime/desktop/dateTime.desktop';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Send from '@material-ui/icons/Send';
import Help from '@material-ui/icons/Help';
import Divider from '@material-ui/core/Divider';

import { SUBJECT_MAXLENGTH } from '../../../app.const';
import { REQUEST_SUBMISSION_TEXT, HIGH_OCCUPANCY_MESSAGE } from '../scheduleDetails.const';

export default class RequestDetailsDesktopComponent extends BaseComponent {

  renderRoomList = () => {
    const { savedResources } = this.props;
    let roomsText;
    if (savedResources) {
      roomsText = savedResources.length > 1 ?
        `${savedResources[0].displayName} and ${savedResources.length - 1} more` :
        `${savedResources[0].displayName}`;
    }
    return (
      <Grid container spacing={4}>
        <Grid item xs={3} className='label'>
          <label>Room(s) requested</label>
        </Grid>
        <Grid item xs={8}>
          <span>{roomsText}</span>
        </Grid>
      </Grid>
    );
  }

  renderSubject = () => {
    const { subject }=this.state;
    return (
      <Grid container spacing={4}>
        <Grid item xs={3} className='label'>
          <label>Subject*</label>
        </Grid>
        <Grid item xs={8}>
          <TextField variant='filled' required fullWidth autoFocus placeholder='Enter Subject'
            inputProps={{className:'input-text', maxLength: SUBJECT_MAXLENGTH }} value={subject}
            name='subject' onChange={this.fieldUpdateHandler}
          />
        </Grid>
      </Grid>
    );
  }

  renderDateTimeComponent = () => {
    const { districtTimezone } = this.props;
    const { startDate, startTime, endDate, endTime, timezone, repeat, repeatEndDate, daysOfWeek } = this.state;
    return <DateTimeComponent
      startDate = { startDate }
      startTime = { startTime }
      endDate = { endDate }
      endTime = { endTime }
      timezone = { timezone }
      repeat = { repeat }
      repeatEndDate = { repeatEndDate }
      daysOfWeek = { daysOfWeek }
      districtTimezone = { districtTimezone }
      setFieldValue = {this.setFieldValue}
      fieldUpdateHandler = {this.fieldUpdateHandler}
    />;
  }

  renderHighOccupancyCheckbox() {
    const { highOccupancy, showHighOccupancy } = this.state;
    if (showHighOccupancy) {
      return (
        <Grid container spacing={4}>
          <Grid item xs={3} className='label'>
            <label>High occupancy</label>
          </Grid>
          <Grid item xs={8}>
            <Checkbox
              name='highOccupancy'
              checked={highOccupancy}
              onChange = {this.checkboxClickHandler}
            />
            <Tooltip title={HIGH_OCCUPANCY_MESSAGE} arrow >
              <IconButton>
                <Help color='primary'></Help>
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      );
    }
  }

  renderNotes = () => {
    const { notes } = this.state;
    return (
      <Grid container spacing={4}>
        <Grid item xs={3} className='label' style={{marginTop: '0'}}>
          <label>Notes</label>
        </Grid>
        <Grid item xs={8}>
          <TextField variant='filled' multiline rows={4} fullWidth
            placeholder='Notes' inputProps={{className:'input-text'}}
            value={notes} name='notes' onChange={this.fieldUpdateHandler}/>
        </Grid>
      </Grid>
    );
  }

  renderActionButtons = () => {
    const { disableButton } = this.state;
    return (
      <Grid container direction="row" justify="space-between" alignItems="center">
        <Grid item>
          <Button onClick={this.goToPreviousPage} variant='outlined'>
            Back
          </Button>
        </Grid>
        <Grid item>
          <Button color='primary' endIcon={<Send />} variant='contained' type='submit' disabled = {disableButton}>
            {REQUEST_SUBMISSION_TEXT}
          </Button>
        </Grid>
      </Grid>
    );
  }
  renderDivider = () => {
    return (
      <Grid container spacing={4}>
        <Grid item xs={2} className='label'>
          <span>EVENT DETAILS</span>
        </Grid>
        <Grid item xs={10} className='label'>
          <Divider/>
        </Grid>
      </Grid>
    );
  }

  render() {
    const { scheduleLoading } = this.props;
    let loaderContent;

    if (scheduleLoading) {
      loaderContent = this.getLoaderView();
    }

    return (
      <form onSubmit={this.requestSubmitHandler}>
        <div className='container'>
          <HeaderContainer>SCHEDULER</HeaderContainer>
          <Container className='schedule-details' maxWidth="md">
            <div className='content'>
              {loaderContent}
              <Grid container direction="column" justify="center" alignItems="stretch">
                <Grid item className='title-container'>
                  <span className='title'>Reservation request details</span>
                  <span className='sub-title'>Enter reservation details to submit the request.</span>
                </Grid>
                <Grid item>
                  <Card className='card schedule-details-card'>
                    <CardContent>
                      {this.renderRoomList()}
                      {this.renderSubject()}
                      {this.renderDivider()}
                      {this.renderDateTimeComponent()}
                      {this.renderHighOccupancyCheckbox()}
                      {this.renderNotes()}
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </div>
            <div className='footer'>
              {this.renderActionButtons()}
            </div>
            {this.getSuccessDialog()}
          </Container>
          <div className='grow'></div>
          <BrandingContainer />
        </div>
      </form>
    );
  }
}