import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { requestCreateVacateSchedule } from '../../store/schedules/schedules.actions';
import { requestSchools, requestDistrict } from '../../store/resources/resources.actions';
import { getScheduleLoaderSelector, getScheduleListSelector } from '../../store/schedules/schedules.selector';
import { getDistrictObjectSelector, getSchoolSelector, getResourceLoadingSelector, getResourceListSelector } from '../../store/resources/resources.selector';
import { getIsMobileSelector, getDistrictSelector, getTimezoneSelector } from '../../store/config/config.selector';
import { getDecodedTokenSelector } from '../../store/auth/auth.selector';
import selectDeviceLayout from '../../utils/selectDeviceLayout/selectDeviceLayout';
import DesktopComponent from './desktop/vacateRequest.desktop';
import MobileComponent from './mobile/vacateRequest.mobile';

const mapStateToProps = (state) => {
  return {
    isMobile: getIsMobileSelector(state),
    districtName: getDistrictSelector(state),
    districtTimezone: getTimezoneSelector(state),
    userProfile: getDecodedTokenSelector(state),
    districtList: getDistrictObjectSelector(state),
    schoolList: getSchoolSelector(state),
    topLevelResources: getResourceListSelector(state),
    resourceLoading: getResourceLoadingSelector(state),
    vacateRequestLoading: getScheduleLoaderSelector(state),
    scheduleList: getScheduleListSelector(state)
  };
};

const mapDispatchToProps = {
  requestDistrict,
  requestSchools,
  requestCreateVacateSchedule
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(selectDeviceLayout(DesktopComponent, MobileComponent)));
