import React, { Component } from 'react';

const selectDeviceLayout = (DesktopComponent, MobileComponent) => {
  return class DeviceLayoutSelector extends Component {
    render() {
      const {isMobile} = this.props;
      if (isMobile == undefined) {
        return null;
      }
      if (isMobile && MobileComponent) {
        return <MobileComponent {...this.props} />;
      }
      return <DesktopComponent {...this.props} />;
    }
  };
};

export default selectDeviceLayout;