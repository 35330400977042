import React, { Component } from 'react';
import _ from 'lodash';
import searchTree from '../../utils/searchTree/searchTree';
import getListofParamsFromResourceHierarchy from '../../utils/getListofParamsFromResourceHierarchy/getListofParamsFromResourceHierarchy';
import ApartmentOutlined from '@material-ui/icons/ApartmentOutlined';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckboxUnselectedIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import ArrowRight from '@material-ui/icons/ArrowRight';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import SvgIcon from '@material-ui/core/SvgIcon';
import Tooltip from '@material-ui/core/Tooltip';
import CheckboxTree from 'react-checkbox-tree';
import Loader from '../loader/loader';
import MapIcon from '../../../images/icon-map-48.svg';
import { PLACE_TYPES } from '../../app.const';
import { SCHEDULE_DETAILS } from '../../router/routes.const';

import './selectRooms.scss';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';

export default class SelectRoomBaseComponent extends Component {

  state = {
    resources: [],
    showSearch: false,
    roomsCount: 0
  }

  componentDidMount() {
    const { resourceList, requestAllResources, savedResources, clearResources,
      fetchLatestListFlag, decodedToken } = this.props;
    if (!resourceList || resourceList.length === 0 || fetchLatestListFlag) {
      requestAllResources(decodedToken ? decodedToken.email: '');
    }
    if (savedResources) {
      const filteredList = savedResources.filter(resource => !!(resource && resource.id));
      const resourceIds = filteredList.map(resource => resource.id);
      this.setState({
        resources: resourceIds,
        roomsCount: resourceIds.length
      }, () => {
        clearResources();
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { resourceList: newList } = this.props;
    const { resourceList: oldList } = prevProps;
    const { resources } = this.state;

    if (!_.isEqual(newList, oldList)) {
      // update the checked resources, if any
      let selected = _.cloneDeep(resources);
      resources.forEach((resourceId) => {
        const resource = searchTree(newList, resourceId);
        if (resource && resource.hasMembers) {
          let idList = getListofParamsFromResourceHierarchy(resource);
          selected = _.union(selected, idList);
        }
      });
      this.setState({ resources: selected }, this.setRoomCount);
    }
  }

  toggleSearch = () => {
    this.setState({ showSearch: !this.state.showSearch });
  }

  setSelectedResources = (newResourceList, selectedResource) => {
    const { resourceList, requestChildResources } = this.props;
    if (selectedResource && selectedResource.isLeaf) {
      const originalResourceObj = searchTree(resourceList, selectedResource.value);
      if (originalResourceObj.hasMembers && originalResourceObj.members.length === 0  ) {
        requestChildResources(selectedResource.value, { includeNestedPlaces: true });
      }
    }
    this.setState({ resources: newResourceList }, this.setRoomCount);
  }

  setRoomCount = () => {
    const { resourceList } = this.props;
    const { resources } = this.state;
    let count = 0;

    resources.forEach((resourceId) => {
      const resource = searchTree(resourceList, resourceId);
      if (resource && !resource.hasMembers) {
        count++;
      }
    });
    this.setState({ roomsCount: count });
  }

  goToScheduleDetails = () => {
    const { history, saveResources, updateRecentSearch, resourceList } = this.props;
    const { resources } = this.state;
    const list = resources.map(id => {
      const resource = searchTree(resourceList, id);
      if (resource) {
        return {
          id: resource.id,
          displayName: resource.displayName,
          displayFQN: resource.displayFQN,
          hasMembers: resource.hasMembers,
          supportsHighOccupancy:  resource.supportsHighOccupancy
        };
      } else {
        return {};
      }
    });
    saveResources(list);
    updateRecentSearch(list);
    history.push(SCHEDULE_DETAILS);
  }

  getHeader = () => {
    const { resourceList } = this.props;
    let headerText;
    let headerIcon= <SvgIcon className='mr' component={MapIcon} fontSize='small' color='primary' viewBox='0 0 48 48' />;
    if (resourceList.length > 0 && resourceList[0].members.length > 0) {
      if (resourceList.length === 1) {
        headerText = resourceList[0].displayName;
        const placeType = PLACE_TYPES[resourceList[0].placeType];
        if ( placeType ) {
          headerIcon = <SvgIcon className='mr' color='primary' component={placeType.icon} viewBox='0 0 24 24' />;
        }
      } else {
        headerText = 'Click here to search rooms';
      }
    }
    return {
      headerIcon,
      headerText
    };
  }

  defineObjectForTreeView = (resource) => {
    const label = resource.hasMembers ? 'Click to expand or collapse': '';
    resource.label = (
      <Tooltip title={label}>
        <div className='title-inner'>
          {resource.displayName}
        </div>
      </Tooltip>
    );
    resource.value = resource.id.toString();
    const icon = PLACE_TYPES[resource.placeType] ? PLACE_TYPES[resource.placeType].icon : ApartmentOutlined;
    resource.disabled = resource.placeType === PLACE_TYPES.district.id;

    let dummyArrowIcon;
    if (resource.hasMembers && resource.members.length === 0) {
      dummyArrowIcon = <Tooltip title='Click to fetch the rooms'>
        <SvgIcon component={ArrowRight} color='primary' className='dummy-arrow'/>
      </Tooltip>;
    }

    resource.icon = (
      <>
        { dummyArrowIcon }
        <Tooltip title={resource.placeType}>
          <SvgIcon component={icon} color='primary' />
        </Tooltip>
      </>
    );
    if (resource.hasMembers && resource.members && resource.members.length > 0) {
      resource.children = resource.members;
      resource.children.map(child => this.defineObjectForTreeView(child));
    }
    return resource;
  }

  setExpandedNode = (expandedNode) => {
    this.setState({ expanded: expandedNode });
  }

  getTreeView = () => {
    const { resourceList } = this.props;
    const { resources, expanded } = this.state;
    if (resourceList.length === 0) {
      return null;
    }
    const cloneList = _.cloneDeep(resourceList);
    const list = cloneList.map(resource => this.defineObjectForTreeView(resource));

    return (
      <CheckboxTree
        className='list-view'
        icons={{
          check: (
            <SvgIcon component={CheckBoxIcon} color='primary' />
          ),
          uncheck: (
            <SvgIcon component={CheckboxUnselectedIcon} color='primary' />
          ),
          halfCheck: (
            <SvgIcon component={IndeterminateCheckBoxIcon} color='primary' />
          ),
          expandClose: (
            <SvgIcon component={ArrowRight} color='primary' />
          ),
          expandOpen: (
            <SvgIcon component={ArrowDropDown} color='primary' />
          )
        }}
        name='resources'
        nodes={list}
        checkModel='all'
        checked={resources}
        expanded={expanded}
        onClick={this.onResourceClick}
        onCheck={this.setSelectedResources}
        onExpand={this.setExpandedNode}
      />
    );
  }

  onResourceClick = (resource) => {
    const { resourceList, requestChildResources } = this.props;
    const { expanded } = this.state;
    if (resource.isLeaf) {
      const originalResourceObj = searchTree(resourceList, resource.value);
      if (originalResourceObj.hasMembers && originalResourceObj.members.length === 0  ) {
        requestChildResources(resource.value, { includeNestedPlaces: true });
        let expandedCopy = expanded && Array.isArray(expanded) ? [...expanded]: [];
        expandedCopy.push(resource.value);
        this.setState({ expanded: expandedCopy });
      }
    }
  }

  getLoaderView = () => {
    return <Loader />;
  }

}