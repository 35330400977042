import React from 'react';
import BaseComponent from '../dateTime.base';
import If from '../../ifComponent/ifComponent';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import { Radio } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import EventNote from '@material-ui/icons/EventNote';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import { REPEAT_OPTIONS } from '../dateTime.const';
import { DATE_FORMAT, TIME_FORMAT } from '../../../app.const';
import combineDateTime from '../../../utils/combineDateTime/combineDateTime';
import humanizeDuration from 'humanize-duration';
export default class BookingDateTimeDesktopComponent extends BaseComponent {

  constructor(props) {
    super(props);
    this.renderAllDayCheckbox.bind(this);
    this.renderStartDateTime.bind(this);
    this.renderStartEndTime.bind(this);
    this.renderRepeatDropdown.bind(this);
    this.renderRecurringSection.bind(this);
    this.renderDaysOfWeek.bind(this);
    this.renderDuration.bind(this);
    this.renderRepeatRadio.bind(this);
  }

  renderStartDateTime() {
    const { startDate, startTime, timezone } = this.props;
    const { disableDateTime } = this.state;
    return (
      <Grid container spacing={4}>
        <Grid item xs={3} className='label'>
          <label>Event starts on*</label>
        </Grid>
        <Grid item xs={3}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker inputVariant='filled' inputValue={startDate} name='startDate'
              InputAdornmentProps={{className: 'picker-icon-button'}} format={DATE_FORMAT}
              required disablePast onChange={this.startDateChangeHandler}
              keyboardIcon={<EventNote className='primary' />}
              rightArrowButtonProps={{color:'primary'}}
              leftArrowButtonProps={{color:'primary'}}
              placeholder='Select start date'
              helperText={timezone}/>
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={1} className='label center-label'>
          <label>at</label>
        </Grid>
        <Grid item xs={3}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardTimePicker inputVariant='filled' inputValue={startTime} name='startTime' required
              InputAdornmentProps={{className: 'picker-icon-button'}}
              keyboardIcon={<AccessTimeIcon className={!disableDateTime? 'enabled' : 'disabled'}/>}
              disabled={disableDateTime} format={TIME_FORMAT} disablePast onChange={this.startTimeChangeHandler}
              placeholder='Select time'/>
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={2}>
          {this.renderAllDayCheckbox()}
        </Grid>
      </Grid>
    );
  }

  renderStartEndTime() {
    const { endDate, endTime, timezone, repeat} = this.props;
    const { disableDateTime} = this.state;
    return (
      <Grid container spacing={4}>
        <Grid item xs={3} className='label'>
          <label>Event ends on*</label>
        </Grid>
        <Grid item xs={3}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker inputVariant='filled' name='endDate'
              inputValue={endDate}
              InputAdornmentProps={{className: 'picker-icon-button'}} format={DATE_FORMAT}
              required disablePast onChange={this.endDateChangeHandler}
              keyboardIcon={<EventNote className={repeat === REPEAT_OPTIONS.NEVER? 'enabled': 'disabled'} />}
              rightArrowButtonProps={{color:'primary'}}
              leftArrowButtonProps={{color:'primary'}}
              disabled={repeat !== REPEAT_OPTIONS.NEVER}
              placeholder='Select end date'
              helperText={timezone}/>
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={1} className='label center-label'>
          <label>at</label>
        </Grid>
        <Grid item xs={3}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardTimePicker inputVariant='filled' inputValue={endTime} name='endTime' required
              InputAdornmentProps={{className: 'picker-icon-button'}}
              keyboardIcon={<AccessTimeIcon className={!disableDateTime? 'enabled' : 'disabled'}/>}
              disabled={disableDateTime}
              format={TIME_FORMAT} disablePast onChange={this.endTimeChangeHandler}
              placeholder='Select time'/>
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={2}>
          {this.renderDuration()}
        </Grid>
      </Grid>
    );
  }

  renderAllDayCheckbox() {
    const { allDayFlag } = this.state;
    return (
      <Grid container>
        <Grid item xs={1}>
          <Checkbox
            checked={allDayFlag}
            onChange = {this.allDayFlagChangeHandler.bind(this)}
          />
        </Grid>
        <Grid item xs={10} className='label center-label'>
          <label>all-day</label>
        </Grid>
      </Grid>
    );
  }

  renderRepeatDropdown() {
    const { repeat, fieldUpdateHandler } = this.props;
    const menuItems = Object.keys(REPEAT_OPTIONS).map(item =>
      <MenuItem key={item} className='repeat-select' value={REPEAT_OPTIONS[item]}>{REPEAT_OPTIONS[item]}</MenuItem>
    );
    return (
      <Grid container spacing={4}>
        <Grid item xs={3} className='label'>
          <label>Event repeats</label>
        </Grid>
        <Grid item xs={8}>
          <Select value={repeat} variant='filled' name='repeat' classes={{select: 'repeat-select'}}
            fullWidth required onChange={fieldUpdateHandler}>
            {menuItems}
          </Select>
        </Grid>
      </Grid>
    );
  }

  renderRecurringSection() {
    const { startDate, repeatEndDate } = this.props;
    return (
      <Grid container spacing={4}>
        <Grid item xs={2} className='label'>
        </Grid>
        <Grid item xs={3} className='label'>
          <label>Repeat until</label>
        </Grid>
        <Grid item xs={3}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker inputVariant='filled' fullWidth inputValue={repeatEndDate}
              name='repeatEndDate' InputAdornmentProps={{className: 'picker-icon-button'}}
              format={DATE_FORMAT} required disablePast onChange={this.repeatEndDateChangeHandler}
              rightArrowButtonProps={{color:'primary'}}
              leftArrowButtonProps={{color:'primary'}}
              minDate={startDate}
              keyboardIcon={<EventNote color='primary' />}/>
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
    );
  }

  renderRepeatRadio() {
    const menuItems = Object.keys(REPEAT_OPTIONS).map(item =>
      <FormControlLabel key={item} value={item.toLocaleLowerCase()}
        control={<Radio color="default"/>}
        label={item.charAt(0).toUpperCase() + item.slice(1).toLocaleLowerCase()}
      />
    );
    return (
      <Grid container spacing={4}>
        <Grid item xs={3} className='label'>
          <label>Event repeats</label>
        </Grid>
        <Grid item xs={8}>
          <FormControl>
            <RadioGroup
              row
              name="repeat"
              defaultValue="never"
              onChange={this.repeatChangeHandler}
            >
              {menuItems}
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={1}>
        </Grid>
      </Grid>
    );
  }

  renderDuration() {
    const {startTime, endTime, startDate, endDate} = this.props;
    const startDateTime = combineDateTime(startDate, startTime);
    const endDateTime = combineDateTime(endDate, endTime);
    return (
      <Grid container>
        <Grid item xs={12} className='label center-label'>
          {startTime && endTime ?endDateTime > startDateTime ? humanizeDuration((endDateTime - startDateTime)): '': ''}
        </Grid>
      </Grid>
    );
  }

  renderDaysOfWeek() {
    const { daysOfWeek } = this.props;
    if (daysOfWeek && Array.isArray(daysOfWeek)) {
      const values = this.getDaysOfWeekItems();
      return (
        <Grid container spacing={4}>
          <Grid item xs={2} className='label'>
          </Grid>
          <Grid item xs={3} className='label'>
            <label>On day(s)</label>
          </Grid>
          <Grid item xs={6}>
            { values }
          </Grid>
        </Grid>
      );
    }
  }

  render() {
    const { repeat } = this.props;
    return (
      <>
        { this.renderStartDateTime() }
        { this.renderStartEndTime() }
        <Box  className="box">
          { this.renderRepeatRadio() }
          <If condition={repeat === REPEAT_OPTIONS.WEEKLY}>
            { this.renderDaysOfWeek() }
          </If>
          <If condition={repeat !== REPEAT_OPTIONS.NEVER}>
            { this.renderRecurringSection() }
          </If>
        </Box>
      </>
    );
  }
}