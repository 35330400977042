import fetchAllResourcesSaga from './sagas/fetchAllResources.saga';
import fetchTopLevelResourcesSaga from './sagas/fetchTopLevelResources.saga';
import fetchChildResourcesSaga from './sagas/fetchChildResources.saga';
import fetchSearchedResourcesSaga from './sagas/fetchSearchedResources.saga';
import fetchSchoolsSaga from './sagas/fetchSchools.saga';
import fetchDistrictSaga from './sagas/fetchDistrict.saga';

export default [
  fetchAllResourcesSaga,
  fetchTopLevelResourcesSaga,
  fetchChildResourcesSaga,
  fetchSearchedResourcesSaga,
  fetchSchoolsSaga,
  fetchDistrictSaga
];