import scssConsts from '../styles/consts.scss';

const DARK_THEME = {
  palette: {
    type: 'dark',
    background: {
      default: scssConsts.background,
      paper: scssConsts.background
    },
    action: {
      hover: scssConsts.riverbed,
      active: scssConsts.bunker,
      selected: scssConsts.active
    },
    typography: {
      htmlFontSize: 16,
      fontFamily: scssConsts.fontFamily
    },
    default: {
      main: scssConsts.ebonyclayDark
    },
    primary: {
      main: scssConsts.amber,
      contrastText: scssConsts.amberLight
    },
    secondary: {
      main: scssConsts.amber,
      contrastText: scssConsts.amberLight
    },
    text: {
      primary: scssConsts.amberLight
    }
  },
  overrides: {
    MuiSvgIcon: {
      fontSizeLarge: {
        fontSize: scssConsts.largeIconFont
      }
    },
    MuiButton: {
      contained: {
        backgroundColor: scssConsts.amber,
        color: 'white'
      },
      outlined: {
        borderColor: scssConsts.amber
      },
      containedPrimary: {
        backgroundImage: scssConsts.buttonBackgroundImage,
        backgroundColor: 'transparent'
      },
      outlinedPrimary: {
        borderColor: scssConsts.amber,
        color: scssConsts.amberLight
      }
    },
    MuiFilledInput: {
      root: {
        backgroundColor: 'black'
      }
    },
    MuiChip: {
      root: {
        backgroundColor: scssConsts.backgroundLight,
        color: scssConsts.amber
      },
      deleteIcon: {
        color: scssConsts.amber
      }
    },
    MuiSnackbarContent: {
      root: {
        backgroundColor: scssConsts.background
      }
    },
    MuiAutocomplete: {
      listbox: {
        backgroundColor: 'black'
      }
    },
    MuiBackdrop: {
      root: {
        zIndex: 101
      }
    },
    MuiTableCell: {
      head: {
        fontSize: 16
      }
    }
  }
};


const LIGHT_THEME = {
  palette: {
    type: 'light',
    typography: {
      htmlFontSize: 16,
      fontFamily: scssConsts.fontFamily
    }
  },
  overrides: {
    MuiSvgIcon: {
      colorPrimary: {
        color: scssConsts.riverbed
      },
      fontSizeLarge: {
        fontSize: scssConsts.largeIconFont
      }
    },
    MuiIconButton: {
      colorPrimary: {
        color: scssConsts.riverbed
      }
    },
    MuiButton: {
      contained: {
        backgroundColor: scssConsts.keyBlue,
        color: 'white'
      },
      outlined: {
        borderColor: scssConsts.keyBlue
      },
      containedPrimary: {
        backgroundColor: scssConsts.keyBlue
      },
      outlinedPrimary: {
        borderColor: scssConsts.keyBlue,
        color: scssConsts.keyBlue
      }
    },
    MuiFilledInput: {
      root: {
        backgroundColor: scssConsts.lightGray
      }
    },
    MuiChip: {
      root: {
        backgroundColor: scssConsts.lightGray,
        color: scssConsts.backgroundLight
      }
      // deleteIcon: {
      //   color: scssConsts.amber
      // }
    },
    MuiSnackbarContent: {
      root: {
        backgroundColor: scssConsts.lightGray
      }
    },
    MuiAutocomplete: {
      listbox: {
        backgroundColor: scssConsts.lightGray
      }
    },
    MuiBackdrop: {
      root: {
        zIndex: 101
      }
    },
    MuiTableCell: {
      head: {
        fontSize: 16
      }
    }
  }
};


const THEMES = {
  DARK:  {
    id: 'DARK',
    theme: DARK_THEME,
    className: 'theme-dark'
  },
  LIGHT: {
    id: 'LIGHT',
    theme: LIGHT_THEME,
    className: 'theme-light'
  }
};

export default THEMES;