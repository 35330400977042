import { connect } from 'react-redux';
import { getIsMobileSelector } from '../../store/config/config.selector';
import selectDeviceLayout from '../../utils/selectDeviceLayout/selectDeviceLayout';
import DesktopComponent from './desktop/contactUs.desktop';
import MobileComponent from './mobile/contactUs.mobile';


const mapStateToProps = (state) => ({
  isMobile: getIsMobileSelector(state)
});

export default connect(
  mapStateToProps,
  {}
)(selectDeviceLayout(DesktopComponent, MobileComponent));