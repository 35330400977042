import { PROPS } from './microFrontend.const';
import { updateObject } from '../utils/updateObject';

export const setRequestLoading = (state, flag) => {
  const obj = {
    [PROPS.LOADING] : flag
  };
  return updateObject(state, obj);
};

export const setMicroFrontendSuccess = (state, data) => {
  const manifests = state[PROPS.MANIFESTS] || {};
  const newManifests = Object.assign({}, manifests, { [data.host]: data.manifest });
  const obj = {
    [PROPS.MANIFESTS]: newManifests,
    [PROPS.LOADING] : false
  };
  return updateObject(state, obj);
};
