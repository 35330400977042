import axios from 'axios';

const BASE_URL = '/v1/import';
const SERVICE_TIMEOUT_FOR_IMPORT = 60 * 5 * 1000;  //timeout for import service to 5 mins

export const uploadImportUtilityApi = async (uploadObj) => {
  const url = `${BASE_URL}`;
  return await axios.post(url, uploadObj, {
    headers: {'Content-Type': 'multipart/form-data' },
    timeout: SERVICE_TIMEOUT_FOR_IMPORT
  });
};

export const validateImportUtilityApi = async (uploadObj) => {
  const url = `${BASE_URL}/validate`;
  return await axios.post(url, uploadObj, {
    headers: {'Content-Type': 'multipart/form-data' },
    timeout: SERVICE_TIMEOUT_FOR_IMPORT
  });
};
