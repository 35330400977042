import axios from 'axios';
import {getStore} from '../index';
import { requestLogout } from '../auth/auth.actions';
import {getSavedTokens} from '../session/session.selector';

const HTTP_UNAUTHORIZED = 401;
const SESSION_EXPIRED_MESSAGE = 'Your session has expired. Please login again to continue.';
let requestInterceptorId;
let responseInterceptorId;

export function requestInterceptor(config) {
  //set Authorization header for each request
  const tokens = getSavedTokens();
  if (tokens && tokens.access_token) {
    let accessToken = tokens.access_token;
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
}

export function responseSuccessInterceptor(response) {
  return response;
}

export function responseErrorInterceptor(error) {
  let { response } = error;
  const store = getStore();
  if (response && response.status === HTTP_UNAUTHORIZED) {
    error = new Error(SESSION_EXPIRED_MESSAGE);
    store.dispatch(requestLogout());
  }
  return Promise.reject(error);
}


export function ejectInterceptors() {
  axios.interceptors.request.eject(requestInterceptorId);
  axios.interceptors.response.eject(responseInterceptorId);
  return;
}


export function addInterceptors() {

  requestInterceptorId = axios.interceptors.request.use(requestInterceptor);
  responseInterceptorId = axios.interceptors.response.use(
    responseSuccessInterceptor,
    responseErrorInterceptor
  );
  return;
}