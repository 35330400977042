import { createSelector } from 'reselect';
import { PROPS } from './auth.const';

export const getAuthSelector = state => state[PROPS.ROOT] || {};

export const getAuthTokenSelector = createSelector(
  [getAuthSelector], (auth) => auth[PROPS.TOKENS]
);

export const getAuthErrorSelector = createSelector(
  [getAuthSelector], (auth) => auth[PROPS.ERROR]
);

export const getAuthLoadingSelector = createSelector(
  [getAuthSelector], (auth) => auth[PROPS.LOADING]
);

export const getDecodedTokenSelector = createSelector(
  [getAuthTokenSelector], (tokens) => tokens ? tokens.decodedToken : undefined
);
