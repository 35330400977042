import React from 'react';
import HeaderContainer from '../../header/header.container';
import BrandingContainer from '../../branding/branding.container';
import BaseComponent from '../scheduleDetails.base';
import DateTimeComponent from '../../dateTime/mobile/dateTime.mobile';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import ArrowBack from '@material-ui/icons/ArrowBack';
import RoomOutlined from '@material-ui/icons/RoomOutlined';
import SubjectOutlined from '@material-ui/icons/SubjectOutlined';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import Create from '@material-ui/icons/Create';
import IconButton from '@material-ui/core/IconButton';
import Send from '@material-ui/icons/Send';
import Help from '@material-ui/icons/Help';

import { SUBJECT_MAXLENGTH, IconSizes } from '../../../app.const';
import { REQUEST_SUBMISSION_TEXT, HIGH_OCCUPANCY_MESSAGE } from '../scheduleDetails.const';

export default class ScheduleDetailsMobileComponent extends BaseComponent {

  renderRoomList = () => {
    const { savedResources } = this.props;
    let roomsText;
    if (savedResources) {
      roomsText = savedResources.length > 1 ?
        `${savedResources[0].displayName} and ${savedResources.length - 1} more` :
        `${savedResources[0].displayName}`;
    }
    return (
      <ListItem>
        <ListItemAvatar>
          <RoomOutlined color='primary' fontSize={IconSizes.Large} />
        </ListItemAvatar>
        <ListItemText className='info-item' primary='Rooms requested'
          secondary={roomsText} />
      </ListItem>
    );
  }

  renderSubject = () => {
    const { subject } = this.state;
    return (
      <ListItem>
        <ListItemAvatar>
          <Create color='primary' fontSize={IconSizes.Large} />
        </ListItemAvatar>
        <TextField
          required
          autoFocus
          name='subject'
          label="Subject"
          variant="filled"
          inputProps={{ maxLength: SUBJECT_MAXLENGTH }}
          value={subject}
          onChange={this.fieldUpdateHandler}
        />
      </ListItem>
    );
  }

  renderDateTimeComponent = () => {
    const { districtTimezone } = this.props;
    const { startDate, startTime, endDate, endTime, timezone, repeat, repeatEndDate, daysOfWeek } = this.state;
    return <DateTimeComponent
      startDate={startDate}
      startTime={startTime}
      endDate={endDate}
      endTime={endTime}
      timezone={timezone}
      repeat={repeat}
      repeatEndDate={repeatEndDate}
      daysOfWeek={daysOfWeek}
      districtTimezone = { districtTimezone }
      setFieldValue={this.setFieldValue}
      fieldUpdateHandler={this.fieldUpdateHandler}
    />;
  }

  renderHighOccupancyCheckbox() {
    const { highOccupancy, showHighOccupancy } = this.state;
    if (showHighOccupancy) {
      return (
        <ListItem>
          <ListItemAvatar>
            <SupervisedUserCircleIcon color='primary' fontSize={IconSizes.Large} />
          </ListItemAvatar>
          <Typography>
            <Checkbox
              style={{ marginRight: 8 }}
              name='highOccupancy'
              checked={highOccupancy}
              onChange={this.checkboxClickHandler}
            />
            High occupancy
            <Tooltip title={HIGH_OCCUPANCY_MESSAGE} arrow enterTouchDelay={0}>
              <IconButton>
                <Help color='primary'></Help>
              </IconButton>
            </Tooltip>
          </Typography>
        </ListItem>
      );
    }
  }

  renderNotes = () => {
    const { notes } = this.state;
    return (
      <ListItem>
        <ListItemAvatar>
          <SubjectOutlined color='primary' fontSize={IconSizes.Large} />
        </ListItemAvatar>
        <TextField
          label="Notes"
          name='notes'
          multiline
          rows={4}
          variant="filled"
          value={notes}
          onChange={this.fieldUpdateHandler}
        />
      </ListItem>
    );
  }

  renderSubmitButton = () => {
    const { disableButton } = this.state;
    return (
      <Grid item className='submit-button-container'>
        <Button color='primary'  endIcon={<Send />}  variant='contained' className='submit-button'
          type='submit' disabled={disableButton}>
          {REQUEST_SUBMISSION_TEXT}
        </Button>
      </Grid>
    );
  }

  render() {
    const { scheduleLoading } = this.props;
    let loaderContent;
    if (scheduleLoading) {
      loaderContent = this.getLoaderView();
    }
    return (
      <form onSubmit={this.requestSubmitHandler}>
        <Container disableGutters className='container'>
          <HeaderContainer>
            Reserve a room
          </HeaderContainer>
          <div className='content'>
            {loaderContent}
            <Grid container direction='column' justify='flex-end' alignItems='stretch' className='schedule-details'>
              <Grid item>
                <Typography component='div' className='sub-header'>
                  <IconButton color='primary' className='back-icon'>
                    <ArrowBack onClick={this.goToPreviousPage}/>
                  </IconButton>
                  <Typography component='div' className='sub-header-text'>
                    Set up request
                  </Typography>
                </Typography>
              </Grid>
              <Grid item className='form-container'>
                <List>
                  {this.renderRoomList()}
                  {this.renderSubject()}
                  {this.renderDateTimeComponent()}
                  {this.renderHighOccupancyCheckbox()}
                  {this.renderNotes()}
                </List>
              </Grid>
            </Grid>
          </div>
          <div className='footer'>
            {this.renderSubmitButton()}
          </div>
          <BrandingContainer />
          {this.getSuccessDialog()}
        </Container>
      </form>
    );
  }
}