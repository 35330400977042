import {
  IMPORT_UTILITY_LOADING,
  IMPORT_UTILITY_SUCCESS,
  IMPORT_UTILITY_ERROR,
  IMPORT_UTILITY_RESET_DATA,
  IMPORT_UTILITY_VALIDATE_SUCCESS
} from './importUtility.actionTypes';

import {
  setLoadingState,
  setImportUtilitySuccess,
  setImportUtilityValidationSuccess,
  resetStoreData
} from './importUtility.reducer.utils';

const ImportUtilityReducer = (state = {}, action) => {
  switch (action.type) {
    case IMPORT_UTILITY_LOADING:
      return setLoadingState(state, true);
    case IMPORT_UTILITY_VALIDATE_SUCCESS:
      return setImportUtilityValidationSuccess(state, action.data);
    case IMPORT_UTILITY_SUCCESS:
      return setImportUtilitySuccess(state, action.data);
    case IMPORT_UTILITY_ERROR:
      return setLoadingState(state, false);
    case IMPORT_UTILITY_RESET_DATA:
      return resetStoreData();
    default:
      return state;
  }
};

export default ImportUtilityReducer;