import {
  ALL_RESOURCES_FETCH,
  All_RESOURCES_SUCCESS,
  TOP_RESOURCES_FETCH,
  TOP_RESOURCES_SUCCESS,
  CHILD_RESOURCES_FETCH,
  CHILD_RESOURCES_SUCCESS,
  SEARCH_RESOURCES_FETCH,
  SEARCH_RESOURCES_SUCCESS,
  SCHOOLS_FETCH,
  SCHOOLS_SUCCESS,
  DISTRICT_FETCH,
  DISTRICT_SUCCESS,
  SET_FETCH_LATEST_LIST_FLAG,
  RESOURCES_LOADING,
  RESOURCES_ERROR
} from './resources.actionTypes';

export const requestAllResources = (payload) => {
  return {
    type: ALL_RESOURCES_FETCH,
    payload: payload
  };
};

export const fetchAllResourcesSuccess = (data) => {
  return {
    type: All_RESOURCES_SUCCESS,
    data: data
  };
};

export const requestTopLevelResources = () => {
  return {
    type: TOP_RESOURCES_FETCH
  };
};

export const fetchTopLevelResourcesSuccess = (data) => {
  return {
    type: TOP_RESOURCES_SUCCESS,
    data: data
  };
};

export const requestChildResources = (parentId, props) => {
  return {
    type: CHILD_RESOURCES_FETCH,
    parentId,
    props
  };
};

export const fetchChildResourcesSuccess = (data, parentId) => {
  return {
    type: CHILD_RESOURCES_SUCCESS,
    parentId,
    data: data
  };
};

export const requestSearchedResources = (searchString) => {
  return {
    type: SEARCH_RESOURCES_FETCH,
    searchString
  };
};

export const fetchSearchedResourcesSuccess = (data, searchString) => {
  return {
    type: SEARCH_RESOURCES_SUCCESS,
    searchString,
    data: data
  };
};

export const requestSchools = () => {
  return {
    type: SCHOOLS_FETCH
  };
};

export const fetchSchoolsSuccess = (data) => {
  return {
    type: SCHOOLS_SUCCESS,
    data: data
  };
};

export const requestDistrict = () => {
  return {
    type: DISTRICT_FETCH
  };
};

export const fetchDistrictSuccess = (data) => {
  return {
    type: DISTRICT_SUCCESS,
    data: data
  };
};

export const resourcesLoading = () => {
  return {
    type: RESOURCES_LOADING
  };
};

export const fetchResourcesError = (error) => {
  return {
    type: RESOURCES_ERROR,
    error: error
  };
};

export const setFetchLatestListFlag = (flag) => {
  return {
    type: SET_FETCH_LATEST_LIST_FLAG,
    data: flag
  };
};
