import React, {Component, Fragment} from 'react';
import moment from 'moment';
import 'moment-timezone';
import Dialog from '../dialog/dialog';
import notification from '../notification/notification';
import isDistrictAdmin from '../../utils/isDistrictAdmin/isDistrictAdmin';
import combineDateTime from '../../utils/combineDateTime/combineDateTime';
import Loader from '../loader/loader';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CloseIcon from '@material-ui/icons/Close';
import { DATE_TIME_FORMAT, SUCCESS_MODAL_PROPS, CONFIRM_MODAL_PROPS, BOOKING_STATUSES } from '../../app.const';
import { SELECT_ROOMS } from '../../router/routes.const';
import { REPEAT_OPTIONS, RECURRENCE_TYPE } from '../dateTime/dateTime.const';
import { RESOURCE_SELECTION_TYPES } from './vacateRequest.const';

import './vacateRequest.scss';

export default class VacateRequestBaseComponent extends Component{

  state = {
    resources: [],
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
    timezone: '',
    subject: '',
    repeat: '',
    repeatEndDate: '',
    repeatEndTime: '11:59 PM',
    daysOfWeek: [],
    notes: '',
    disableButton: true,
    showConfirmDialog: false,
    showSuccessDialog: false,
    resourceSelectionType: '',
    isDistrictAdminFlag: undefined
  };

  componentDidMount() {
    const { requestSchools } = this.props;
    requestSchools();
    this.setDistrictAdminFlagAndGetDistrict();
  }

  componentDidUpdate(oldProps) {
    const { scheduleList: newList } = this.props;
    const { scheduleList: oldList } = oldProps;
    const { isDistrictAdminFlag } = this.state;

    if (newList.length === oldList.length + 1) {
      this.toggleSuccessDialog();
    }

    if (isDistrictAdminFlag === undefined) {
      this.setDistrictAdminFlagAndGetDistrict();
    }
  }

  setDistrictAdminFlagAndGetDistrict = () => {
    const { districtName, userProfile, requestDistrict } = this.props;
    if (districtName && userProfile) {
      const isDistrictAdminFlag = isDistrictAdmin(userProfile, districtName);
      const resourceSelectionType = isDistrictAdminFlag ?
        RESOURCE_SELECTION_TYPES.DISTRICT : RESOURCE_SELECTION_TYPES.SCHOOL;
      this.setState({
        isDistrictAdminFlag : isDistrictAdminFlag,
        resourceSelectionType: resourceSelectionType
      }, () => {
        if (isDistrictAdminFlag) {
          requestDistrict();
        }
      });
    }
  }

  toggleSuccessDialog = () => {
    this.setState({showSuccessDialog: !this.state.showSuccessDialog});
  }

  createVacateScheduleSuccessHandler = () => {
    this.toggleSuccessDialog();
    this.goToHome();
  }

  fieldUpdateHandler = (event) => {
    const el = event.target;
    this.setState({
      [el.name] : el.value
    }, () => {
      this.toggleSaveButton();
    });
  }

  resourceSelectionTypeChangeHandler = (event) => {
    this.fieldUpdateHandler(event);
    const value = event.target.value;
    if (value === RESOURCE_SELECTION_TYPES.DISTRICT) {
      this.setState({ resources: [] });
    }
  }

  setFieldValue = (name, value, callback) => {
    this.setState({
      [name] : value
    }, () => {
      this.toggleSaveButton();
      if (callback && typeof callback === 'function') {
        callback();
      }
    });
  }

  getResourceDropDownInput = (params) => {
    const { resources } = this.state;
    return (
      <TextField
        {...params}
        placeholder={ resources.length === 0 ? 'Select a school' : '' }
        variant='filled'
        fullWidth
      />
    );
  }

  getResourceOptionLabel = (option) => {
    return option ? option.displayName : '';
  }

  getResourceOption = (option, state) => {
    return (
      <Fragment>
        <Checkbox style={{ marginRight: 8 }} checked={ state ? state.selected: false } />
        {option ? option.displayName : ''}
      </Fragment>
    );
  }

  getResourceDropDown = () => {
    const { schoolList } = this.props;
    const { resourceSelectionType, resources } = this.state;
    return (
      <Autocomplete multiple fullWidth required options={schoolList}
        value={resources}
        disabled={resourceSelectionType !== RESOURCE_SELECTION_TYPES.SCHOOL}
        onChange={this.resourceListChangeHandler}
        renderOption={this.getResourceOption}
        getOptionLabel={this.getResourceOptionLabel}
        popupIcon={<ArrowDropDownIcon color='primary'/>}
        closeIcon={<CloseIcon color='primary' fontSize='small' />}
        renderInput={this.getResourceDropDownInput}
      />
    );
  }

  resourceListChangeHandler = (event, newValue) => {
    this.setFieldValue('resources', newValue);
  }

  getDistrictName = () => {
    const { districtList } = this.props;
    return districtList && districtList.length > 0 ? districtList[0].displayName : '';
  }

  toggleSaveButton = () => {
    const { startDate, startTime, endDate, endTime, subject, timezone, repeat, repeatEndDate, daysOfWeek } = this.state;
    const resources = this.getFinalResourceList();
    let dataFlag = resources.length && startDate && startTime &&
      endDate && endTime && subject && timezone && repeat;
    if (dataFlag) {
      switch (repeat) {
        case REPEAT_OPTIONS.NEVER:
          break;
        case REPEAT_OPTIONS.WEEKLY:
          dataFlag = dataFlag && repeatEndDate && daysOfWeek.length > 0;
          break;
        default:
          dataFlag = dataFlag && repeatEndDate;
          break;
      }
    }
    this.setState({ disableButton : !dataFlag });
  }

  goToHome = () => {
    this.props.history.push(SELECT_ROOMS);
  }

  getSuccessDialog = () => {
    const { showSuccessDialog } = this.state;
    const content = <div className='fh-dialog-content'>{SUCCESS_MODAL_PROPS.content}</div>;
    const footer = <Button onClick={this.createVacateScheduleSuccessHandler} color="primary">
      {SUCCESS_MODAL_PROPS.buttonText}
    </Button>;
    return (
      <Dialog
        open={showSuccessDialog}
        disableBackdropClick = {SUCCESS_MODAL_PROPS.disableBackdropClick}
        disableEscapeKeyDown = {SUCCESS_MODAL_PROPS.disableEscapeKeyDown}
        header = { SUCCESS_MODAL_PROPS.title }
        content = { content }
        footer = { footer }
        onClose={this.toggleSuccessDialog}
      />
    );
  }

  validateDates = () => {
    const { startDate, startTime, endDate, endTime } = this.state;
    const startDateTime = combineDateTime(startDate, startTime);
    const endDateTime = combineDateTime(endDate, endTime);
    if (moment().isAfter(startDateTime, 'day')) {
      return 'Start date should not be less than current date';
    }
    if (endDateTime.isSameOrBefore(startDateTime)) {
      return 'End date/time should be greater than start date/time';
    }
  }

  validateRepeatSection = () => {
    const { repeat, repeatEndDate, endDate } = this.state;
    if (repeat === REPEAT_OPTIONS.NEVER) {
      return;
    }

    const repeatEndDateObj = moment(repeatEndDate);
    const endDateObj = moment(endDate);
    if (repeatEndDateObj.isSameOrBefore(endDateObj)) {
      return 'Repeat End date should be greater than event end date';
    }
  }

  validateRequest = () => {
    const dateValidationMessage = this.validateDates();
    if (dateValidationMessage) {
      return dateValidationMessage;
    }
    const repititionValidationMessage = this.validateRepeatSection();
    if (repititionValidationMessage) {
      return repititionValidationMessage;
    }
  }

  getConfirmDialog = () => {
    const { showConfirmDialog } = this.state;
    const content = <div className='fh-dialog-content'>{CONFIRM_MODAL_PROPS.content}</div>;
    const footer = <>
      <Button onClick={this.toggleConfirmDialog} color="secondary">No</Button>
      <Button onClick={this.requestSubmitHandler} color="primary">Yes</Button>
    </>;
    return (
      <Dialog
        open={showConfirmDialog}
        disableBackdropClick = {CONFIRM_MODAL_PROPS.disableBackdropClick}
        disableEscapeKeyDown = {CONFIRM_MODAL_PROPS.disableEscapeKeyDown}
        header = { CONFIRM_MODAL_PROPS.title }
        content = { content }
        footer = { footer }
        onClose={this.toggleSuccessDialog}
      />
    );
  }

  toggleConfirmDialog = () => {
    this.setState({showConfirmDialog: !this.state.showConfirmDialog});
  }

  confirmSubmit = (event) => {
    event.stopPropagation();
    event.preventDefault();
    this.toggleConfirmDialog();
  }

  getFinalResourceList = () => {
    const { schoolList } = this.props;
    const { resources, resourceSelectionType } = this.state;
    return resourceSelectionType === RESOURCE_SELECTION_TYPES.DISTRICT ? schoolList : resources;
  }

  requestSubmitHandler = () => {
    const { startDate, startTime, endDate, endTime, subject, timezone, repeat, notes } = this.state;

    this.toggleConfirmDialog();
    const validationMessage = this.validateRequest();
    if (validationMessage) {
      notification.error(validationMessage);
      return;
    }

    const selectedResources = this.getFinalResourceList();
    const startDateTime = combineDateTime(startDate, startTime, DATE_TIME_FORMAT);
    const endDateTime = combineDateTime(endDate, endTime, DATE_TIME_FORMAT);
    let recurrenceType;
    switch (repeat) {
      case REPEAT_OPTIONS.DAILY:
        recurrenceType = this.getDailyRecurrenceObject();
        break;
      case REPEAT_OPTIONS.WEEKLY:
        recurrenceType = this.getWeeklyRecurrenceObject();
        break;
      case REPEAT_OPTIONS.MONTHLY:
        recurrenceType = this.getMonthlyRecurrenceObject();
        break;
      case REPEAT_OPTIONS.NEVER:
        recurrenceType = this.getNeverRecurrenceObject();
        break;
    }

    const scheduleObj = {
      status: BOOKING_STATUSES.UNOCCUPIED,
      subject: subject,
      start: startDateTime,
      end: endDateTime,
      timeZone: timezone,
      resources : selectedResources,
      note: notes,
      recurrence: recurrenceType
    };
    this.props.requestCreateVacateSchedule(scheduleObj);
  }

  getDailyRecurrenceObject = () => {
    const { repeat, repeatEndDate, repeatEndTime } = this.state;
    return {
      type: repeat,
      recurrenceRange: {
        type: RECURRENCE_TYPE.ENDDATE,
        endDate: combineDateTime(repeatEndDate, repeatEndTime, DATE_TIME_FORMAT)
      }
    };
  }

  getWeeklyRecurrenceObject = () => {
    const { repeat, repeatEndDate, repeatEndTime, daysOfWeek } = this.state;
    return {
      type: repeat,
      recurrenceRange: {
        type: RECURRENCE_TYPE.ENDDATE,
        endDate: combineDateTime(repeatEndDate, repeatEndTime, DATE_TIME_FORMAT)
      },
      weekly: {
        daysOfWeek: daysOfWeek
      }
    };
  }

  getMonthlyRecurrenceObject = () => {
    const { repeat, repeatEndDate, repeatEndTime } = this.state;
    return {
      type: repeat,
      recurrenceRange: {
        type: RECURRENCE_TYPE.ENDDATE,
        endDate: combineDateTime(repeatEndDate, repeatEndTime, DATE_TIME_FORMAT)
      }
    };
  }

  getNeverRecurrenceObject = () => {
    const { repeat } = this.state;
    return {
      type: repeat
    };
  }

  getLoaderView = () => {
    return <Loader />;
  }

}