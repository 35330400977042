import axios from 'axios';
const BASE_URL = '/v1/places';

export const getResourceHierarchyApi = async (payload) => {
  const url = `${BASE_URL}/byCategory?filterForUser=${payload}&&activeAdhocSchedule=true`;
  const response = await axios.get(url);
  return response;
};

export const getAllResourcesApi = async () => {
  const response = await axios.get(BASE_URL);
  return response;
};

export const getTopLevelResourcesApi = async () => {
  const response = await axios.get(BASE_URL);
  return response;
};

export const getChildResourcesApi = async (id, includeNestedPlacesFlag) => {
  const url = `${BASE_URL}/${id}/members?includeNestedPlaces=${includeNestedPlacesFlag}`;
  const response = await axios.get(url);
  return response;
};

export const getResourceSearchApi = async (searchString) => {
  const url = `${BASE_URL}?nameQuery=*${searchString}*`;
  const response = await axios.get(url);
  return response;
};

export const getResourceByTypeApi = async (type) => {
  const url = `${BASE_URL}?typeQuery=${type}`;
  const response = await axios.get(url);
  return response;
};
