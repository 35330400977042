import { call, put, takeEvery } from 'redux-saga/effects';
import { IMPORT_UTILITY_REQUEST } from '../importUtility.actionTypes';
import { uploadImportUtilityApi } from '../importUtility.apis';
import { importUtilityUploadLoading, importUtilityUploadSuccess, importUtilityUploadError } from '../importUtility.actions';
import { setFetchLatestListFlag } from '../../resources/resources.actions';
import notification from '../../../components/notification/notification';

export function * uploadImportUtility(action) {
  try {
    yield put(importUtilityUploadLoading());
    const { data } = yield call(uploadImportUtilityApi, action.data);
    yield put(importUtilityUploadSuccess(data));
    // set the flag to true so that the UI can fetch the resources again
    yield put(setFetchLatestListFlag(true));
  } catch (e) {
    // TODO:- HTML code should not be mixed with redux code. Need to find a better place for this.
    const message = e.response && e.response.data ? e.response.data.message : e.message;
    yield call(notification.error, message);
    yield put(importUtilityUploadError(message));
  }
}

function * uploadImportUtilitySaga() {
  yield takeEvery(IMPORT_UTILITY_REQUEST, uploadImportUtility);
}


export default uploadImportUtilitySaga;