import { call, put, takeEvery } from 'redux-saga/effects';
import { CREATE_ADHOC_SCHEDULE_REQUEST } from '../schedules.actionTypes';
import { createAdhocScheduleApi } from '../schedules.apis';
import { createAdhocScheduleLoading, createAdhocScheduleSuccess, createAdhocScheduleError } from '../schedules.actions';
import notification from '../../../components/notification/notification';

export function * createAdhocSchedule(action) {
  try {
    yield put(createAdhocScheduleLoading());
    const { data } = yield call(createAdhocScheduleApi, action.scheduleObj);
    yield put(createAdhocScheduleSuccess(data));
  } catch (e) {
    // TODO:- HTML code should not be mixed with redux code. Need to find a better place for this.
    const message = e.response && e.response.data ? e.response.data.message : e.message;
    yield call(notification.error, message);
    yield put(createAdhocScheduleError(message));
  }
}

function * createAdhocScheduleSaga() {
  yield takeEvery(CREATE_ADHOC_SCHEDULE_REQUEST, createAdhocSchedule);
}


export default createAdhocScheduleSaga;