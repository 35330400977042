import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import SearchOutlined from '@material-ui/icons/SearchOutlined';
import ArrowForward from '@material-ui/icons/ArrowForward';

import BaseComponent from '../selectRooms.base';
import HeaderContainer from '../../header/header.container';
import BrandingContainer from '../../branding/branding.container';
import RoomSearch from '../roomSearch/roomSearch';
import Dialog from '../../dialog/dialog';

export default class SelectRoomsMobileComponent extends BaseComponent {

  renderSubheader = () => {
    const { headerIcon, headerText } = this.getHeader();
    return (
      <Grid item>
        <Typography component='div' className='sub-header'>
          <Typography component='span' className='sub-header-title'>
            <IconButton color='primary'> { headerIcon } </IconButton>
            <span className='va-top'>{ headerText } </span>
          </Typography>
          <Typography component='span' className='search-icon'
            onClick={this.toggleSearch.bind(this)}>
            <IconButton color='primary'>
              <SearchOutlined />
            </IconButton>
          </Typography>
        </Typography>
      </Grid>
    );
  }

  renderRoomsList = () => {
    const { resourceList } = this.props;
    if (resourceList.length === 0) {
      return null;
    }
    return (
      <Grid item className='rooms-list'>
        {this.getTreeView()}
      </Grid>
    );
  }

  renderSubmitButton = () => {
    const { roomsCount } = this.state;
    let roomsText = roomsCount > 1 ? 'rooms' : 'room';
    return (
      <Grid item className='submit-button-container'>
        <Button variant='contained' color='primary' className='submit-button'
          endIcon={<ArrowForward />}
          disabled={roomsCount === 0} onClick={this.goToScheduleDetails}>
          Select {roomsCount} {roomsText}
        </Button>
      </Grid>
    );
  }

  renderSearchDialog = () => {
    const { showSearch, resources } = this.state;
    const { searchResults, resourceList, requestSearchedResources, requestChildResources, recentSearches } = this.props;
    return (
      <Dialog
        open={showSearch}
        className='search-dialog'
        contentClassName='search-dialog-content'
        disableBackdropClick={false}
        disableEscapeKeyDown={true}
        content={<RoomSearch
          resourceList={resourceList}
          selectedResources={resources}
          searchResults={searchResults}
          recentSearches={recentSearches}
          requestSearchedResources={requestSearchedResources}
          requestChildResources={requestChildResources}
          toggleSearch={this.toggleSearch}
          setSelectedResources={this.setSelectedResources}
        />}
        onClose={this.toggleSearch}
      />
    );
  }

  render() {
    const { resourceLoading } = this.props;
    let loaderContent;
    if (resourceLoading) {
      loaderContent = this.getLoaderView();
    }

    return (
      <Container disableGutters className='container'>
        <HeaderContainer>
          Reserve a room
        </HeaderContainer>
        <div className='content'>
          {loaderContent}
          <Grid container direction="column" justify="flex-end" alignItems="stretch" className='select-rooms'>
            {this.renderSubheader()}
            {this.renderRoomsList()}
          </Grid>
        </div>
        <div className='footer'>
          {this.renderSubmitButton()}
        </div>
        <BrandingContainer />
        {this.renderSearchDialog()}
      </Container>
    );
  }
}