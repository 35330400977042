import moment from 'moment';
import { DATE_FORMAT, TIME_FORMAT } from '../../app.const';

const combineDateTime = (dateString, timeString, format) => {
  if (format) {
    return moment(dateString+ ' ' + timeString, DATE_FORMAT+ ' '+ TIME_FORMAT).format(format);
  }
  return moment(dateString+ ' ' + timeString, DATE_FORMAT+ ' '+ TIME_FORMAT);
};

export default combineDateTime;