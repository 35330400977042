import React, { Component } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import './loader.scss';

export default class Loader extends Component {
  state = {
    open : false
  };

  componentDidMount() {
    this.setState({open: true});
  }

  render() {
    const {open} = this.state;
    return (
      <Backdrop open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
}
