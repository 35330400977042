import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import HeaderContainer from '../../header/header.container';
import BrandingContainer from '../../branding/branding.container';
import getClientConfig from '../../../utils/getClientConfig/getClientConfig';

import '../contactUs.scss';

export default class ContactUsMobileComponent extends Component {
  render() {

    const clientConfig = getClientConfig() || {};

    return (
      <div className='container'>
        <HeaderContainer>SCHEDULER</HeaderContainer>
        <Container className={`contact-us mobile`} maxWidth="md">
          <div className='content'>
            <Grid container direction='column' justify='center' alignItems='center'>
              <Grid item>
                <img src="/envelope-128.svg" />
                <h2>Contact Support</h2>
                <p>
                  For help and support, please get in touch with us at <a href={`mailto:${clientConfig.supportEmail}`}>
                    {clientConfig.supportEmail}
                  </a>
                  <br />
                  <br />
                  It will help us serve you better, if you can include the following information in your email
                </p>
                <ul>
                  <li>What operation you were performing with this application.</li>
                  <li>What issues you encountered.</li>
                  <li>Screenshots of what happened as applicable.</li>
                  <li>Information about the computer or the mobile device your were using.</li>
                </ul>
                <div className='footer'>
                  <a onClick={() => history.back()}>Back</a>
                  {/* <Button color='primary' variant='contained' fullWidth onClick={() => history.back()}>
                    Back
                  </Button> */}
                </div>
              </Grid>
            </Grid>
          </div>
        </Container>
        <BrandingContainer />
      </div>
    );
  }
}