import { call, put, takeEvery } from 'redux-saga/effects';
import { SEARCH_RESOURCES_FETCH } from '../resources.actionTypes';
import { getResourceSearchApi } from '../resources.apis';
import { fetchSearchedResourcesSuccess, fetchResourcesError } from '../resources.actions';
import notification from '../../../components/notification/notification';

export function * fetchSearchedResources(action) {
  try {
    const { data } = yield call(getResourceSearchApi, action.searchString);
    yield put(fetchSearchedResourcesSuccess(data, action.searchString));
  } catch (e) {
    // TODO:- HTML code should not be mixed with redux code. Need to find a better place for this.
    const message = e.response && e.response.data ? e.response.data.message : e.message;
    yield call(notification.error, message);
    yield put(fetchResourcesError(message));
  }
}

function * fetchSearchedResourcesSaga() {
  yield takeEvery(SEARCH_RESOURCES_FETCH, fetchSearchedResources);
}


export default fetchSearchedResourcesSaga;