import { call, put, takeEvery } from 'redux-saga/effects';
import { CREATE_VACATE_SCHEDULE_REQUEST } from '../schedules.actionTypes';
import { createVacateScheduleApi } from '../schedules.apis';
import { createVacateScheduleLoading, createVacateScheduleSuccess, createVacateScheduleError } from '../schedules.actions';
import notification from '../../../components/notification/notification';

export function * createVacateSchedule(action) {
  try {
    yield put(createVacateScheduleLoading());
    const { data } = yield call(createVacateScheduleApi, action.scheduleObj);
    yield put(createVacateScheduleSuccess(data));
  } catch (e) {
    // TODO:- HTML code should not be mixed with redux code. Need to find a better place for this.
    const message = e.response && e.response.data ? e.response.data.message : e.message;
    yield call(notification.error, message);
    yield put(createVacateScheduleError(message));
  }
}

function * createVacateScheduleSaga() {
  yield takeEvery(CREATE_VACATE_SCHEDULE_REQUEST, createVacateSchedule);
}

export default createVacateScheduleSaga;