import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DesktopComponent from './desktop/branding.desktop';
import MobileComponent from './mobile/branding.mobile';
import {getIsMobileSelector} from '../../store/config/config.selector';
import selectDeviceLayout from '../../utils/selectDeviceLayout/selectDeviceLayout';

const mapStateToProps = (state) => {
  return {
    isMobile: getIsMobileSelector(state)
  };
};

const mapDispatchToProps = {
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(selectDeviceLayout(DesktopComponent, MobileComponent)));
