import React from 'react';
import HeaderContainer from '../../header/header.container';
import BrandingContainer from '../../branding/branding.container';
import BaseComponent from '../vacateRequest.base';
import DateTimeComponent from '../../dateTime/mobile/dateTime.mobile';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import IconButton from '@material-ui/core/IconButton';
import RoomOutlined from '@material-ui/icons/RoomOutlined';
import SubjectOutlined from '@material-ui/icons/SubjectOutlined';
import Create from '@material-ui/icons/Create';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { SUBJECT_MAXLENGTH, IconSizes } from '../../../app.const';
import { RESOURCE_SELECTION_TYPES, REQUEST_SUBMISSION_TEXT } from '../vacateRequest.const';

export default class VacateRequestMobileComponent extends BaseComponent {

  renderSubject = () => {
    const { subject }= this.state;
    return (
      <ListItem>
        <ListItemAvatar>
          <Create color='primary' fontSize={IconSizes.Large} />
        </ListItemAvatar>
        <TextField
          required
          autoFocus
          name='subject'
          label="Subject"
          variant="filled"
          inputProps = {{maxLength: SUBJECT_MAXLENGTH}}
          value={subject}
          onChange={this.fieldUpdateHandler}
        />
      </ListItem>
    );
  }

  renderResourceList = () => {
    const { resourceSelectionType, isDistrictAdminFlag } = this.state;
    if (isDistrictAdminFlag) {
      return (
        <>
          <ListItem>
            <ListItemAvatar className='places-icon'>
              <RoomOutlined  color='primary' fontSize={IconSizes.Large}/>
            </ListItemAvatar>
            <Radio checked={resourceSelectionType === RESOURCE_SELECTION_TYPES.DISTRICT} name='resourceSelectionType'
              value={RESOURCE_SELECTION_TYPES.DISTRICT} onChange={this.resourceSelectionTypeChangeHandler}/>
            <span>{this.getDistrictName()}</span>
          </ListItem>
          <ListItem>
            <ListItemAvatar />
            <Radio checked={resourceSelectionType === RESOURCE_SELECTION_TYPES.SCHOOL} name='resourceSelectionType'
              value={RESOURCE_SELECTION_TYPES.SCHOOL} onChange={this.resourceSelectionTypeChangeHandler}/>
            {this.getResourceDropDown('Schools*')}
          </ListItem>
        </>
      );
    } else {
      return (
        <>
          <ListItem>
            <ListItemAvatar>
              <RoomOutlined  color='primary' fontSize={IconSizes.Large}/>
            </ListItemAvatar>
            {this.getResourceDropDown('Schools*')}
          </ListItem>
        </>
      );
    }
  }

  renderDateTimeComponent = () => {
    const { districtTimezone } = this.props;
    const { startDate, startTime, endDate, endTime, timezone, repeat, repeatEndDate, daysOfWeek } = this.state;
    return <DateTimeComponent
      startDate = { startDate }
      startTime = { startTime }
      endDate = { endDate }
      endTime = { endTime }
      timezone = { timezone }
      repeat = { repeat }
      repeatEndDate = { repeatEndDate }
      daysOfWeek = { daysOfWeek }
      districtTimezone = { districtTimezone }
      setFieldValue = {this.setFieldValue}
      fieldUpdateHandler = {this.fieldUpdateHandler}
    />;
  }

  renderNotes = () => {
    const { notes } = this.state;
    return (
      <ListItem>
        <ListItemAvatar>
          <SubjectOutlined  color='primary' fontSize={IconSizes.Large}/>
        </ListItemAvatar>
        <TextField
          label="Notes"
          name='notes'
          multiline
          rows={4}
          variant="filled"
          value={notes}
          onChange={this.fieldUpdateHandler}
        />
      </ListItem>
    );
  }

  renderSubmitButton = () => {
    const { disableButton } = this.state;
    return (
      <Grid item className='submit-button-container'>
        <Button color='primary' variant='contained' className='submit-button'
          type='submit' disabled = {disableButton}>
          {REQUEST_SUBMISSION_TEXT}
        </Button>
      </Grid>
    );
  }

  render() {
    const { vacateRequestLoading, resourceLoading } = this.props;
    let loaderContent;
    if (vacateRequestLoading || resourceLoading) {
      loaderContent = this.getLoaderView();
    }
    return (
      <form onSubmit={this.confirmSubmit}>
        <Container disableGutters className='container'>
          <HeaderContainer>
            Schedule Change
          </HeaderContainer>
          <div className='content'>
            {loaderContent}
            <Grid container direction='column' justify='flex-start' alignItems='stretch' className='vacate-schedule'>
              <Grid item>
                <Typography component='div' className='sub-header'>
                  <IconButton color='primary' className='back-icon' onClick={this.goToHome}>
                    <ArrowBack/>
                  </IconButton>
                  <Typography component='div' className='sub-header-text'>
                    Schedule request details
                  </Typography>
                </Typography>
              </Grid>
              <Grid item className='vacate-schedule-form-container'>
                <List>
                  {this.renderSubject()}
                  {this.renderResourceList()}
                  {this.renderDateTimeComponent()}
                  {this.renderNotes()}
                </List>
              </Grid>
            </Grid>
          </div>
          <div className='footer'>
            {this.renderSubmitButton()}
          </div>
          <BrandingContainer />
          {this.getSuccessDialog()}
          {this.getConfirmDialog()}
        </Container>
      </form>
    );
  }
}