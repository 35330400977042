import { call, put, takeEvery, all } from 'redux-saga/effects';
import { REFRESH_TOKEN_REQUEST } from '../auth.actionTypes';
import { refreshTokenApi } from '../auth.apis';
import { refreshTokenSuccess, decodeAuthToken, requestLogout } from '../auth.actions';
import { saveToken } from '../../session/session.actions';

export function * refreshToken(action) {
  try {
    const { data } = yield call(refreshTokenApi, action.refreshToken);
    yield all ([
      put(refreshTokenSuccess(data)),
      put(decodeAuthToken(data)),
      put(saveToken(data))
    ]);
  } catch (e) {
    // Logout the user
    yield put(requestLogout());

  }
}

function * refreshTokenSaga() {
  yield takeEvery(REFRESH_TOKEN_REQUEST, refreshToken);
}


export default refreshTokenSaga;