import {
  BOOKING_DATA_LOADING,
  BOOKING_DATA_SUCCESS,
  BOOKING_DATA_ERROR,
  BOOKING_APPROVAL_LOADING,
  BOOKING_APPROVAL_SUCCESS,
  BOOKING_APPROVAL_ERROR
} from './approvals.actionTypes';

import {
  setRequestLoading,
  bookingDataSuccess,
  bookingDataError,
  bookingApprovalSuccess
} from './approvals.reducer.utils';

const scheduleReducer = (state = {}, action) => {
  switch (action.type) {
    case BOOKING_DATA_LOADING:
      return setRequestLoading(state, true);
    case BOOKING_DATA_SUCCESS:
      return bookingDataSuccess(state, action.data);
    case BOOKING_DATA_ERROR:
      return bookingDataError(state, action.error);
    case BOOKING_APPROVAL_LOADING:
      return setRequestLoading(state, true);
    case BOOKING_APPROVAL_SUCCESS:
      return bookingApprovalSuccess(state, action.data);
    case BOOKING_APPROVAL_ERROR:
      return setRequestLoading(state, false);
    default: return state;
  }
};

export default scheduleReducer;