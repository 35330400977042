import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import './dialog.scss';

export default class DialogComponent extends Component {
  state = {
    open: false
  };

  componentDidUpdate(prevProps) {
    const { open: newOpen } = this.props;
    const { open: oldOpen } = prevProps;
    if (newOpen !== oldOpen) {
      this.setState({open : newOpen});
    }
  }

  handleClose = () => {
    this.setState({ open: false }, () => {
      const { onClose } = this.props;
      if (onClose && typeof onClose === 'function') {
        onClose();
      }
    });
  }

  getHeader = () => {
    const { header } = this.props;
    if (!header) {
      return null;
    }
    return <DialogTitle>{header}</DialogTitle>;
  }

  getContent = () => {
    const { content, contentClassName  } = this.props;
    if (!content) {
      return null;
    }
    const classes = contentClassName || '';
    return (
      <DialogContent className={classes}>
        {content}
      </DialogContent>
    );
  }

  getFooter = () => {
    const { footer } = this.props;
    if (!footer) {
      return null;
    }
    return <DialogActions> {footer} </DialogActions>;
  }

  render() {
    const { open } = this.state;
    const { disableBackdropClick, disableEscapeKeyDown, className } = this.props;
    return (
      <Dialog className='fh-dialog' open={open} onClose={this.handleClose} PaperProps={{className:className}}
        disableBackdropClick={disableBackdropClick} disableEscapeKeyDown={disableEscapeKeyDown} >
        {this.getHeader()}
        {this.getContent()}
        {this.getFooter()}
      </Dialog>
    );
  }
}