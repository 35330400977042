import { call, put, takeEvery } from 'redux-saga/effects';
import { REQUEST_MICRO_FRONTEND } from '../microFrontends.actionTypes';
import { getManifestApi } from '../microFrontends.apis';
import { microFrontendLoading, microFrontendSuccess, microFrontendError } from '../microFrontends.actions';
import notification from '../../../components/notification/notification';

export function * fetchMicroFrontend(action) {
  try {
    yield put(microFrontendLoading());
    const { data } = yield call(getManifestApi, action.host);
    yield put(microFrontendSuccess(data, action.host));
  } catch (e) {
    // TODO:- HTML code should not be mixed with redux code. Need to find a better place for this.
    const message = e.response && e.response.data ? e.response.data.message : e.message;
    yield call(notification.error, message);
    yield put(microFrontendError(message));
  }
}

function * fetchMicroFrontendSaga() {
  yield takeEvery(REQUEST_MICRO_FRONTEND, fetchMicroFrontend);
}


export default fetchMicroFrontendSaga;