import { updateObject } from '../utils/updateObject';
import { PROPS } from './importUtility.const';

export const setLoadingState = (state, flag) => {
  const obj = {
    [PROPS.LOADING] : flag
  };
  return updateObject(state, obj);
};

export const setImportUtilitySuccess = (state, data) => {
  return updateObject(state, {
    [PROPS.UPLOAD_SUCCESS]: data,
    [PROPS.VALIDATION_MESSAGES]: undefined,
    [PROPS.LOADING] : false
  });
};

export const setImportUtilityValidationSuccess = (state, data) => {
  return updateObject(state, {
    [PROPS.VALIDATION_MESSAGES]: data,
    [PROPS.LOADING] : false
  });
};

export const resetStoreData = (state) => {
  return updateObject(state, {
    [PROPS.UPLOAD_SUCCESS]: undefined,
    [PROPS.VALIDATION_MESSAGES]: undefined,
    [PROPS.LOADING] : false
  });
};