import React, { Component } from 'react';
import Loader from '../loader/loader';
import {SELECT_ROOMS, LOGIN, DEFAULT} from '../../router/routes.const';
import './main.scss';

export default class IndexComponent extends Component {

  componentDidMount() {
    this.identifyRequestToBeMade();
  }

  componentDidUpdate(oldProps) {
    const { tokens: newToken } = this.props;
    const { tokens: oldToken } = oldProps;
    if ( oldToken && !newToken ) {
      this.goToLogin();
    }
  }

  identifyRequestToBeMade = () => {
    const { tokens, history, loginSuccess, decodeAuthToken, requestConfig } = this.props;
    if (tokens) {
      loginSuccess(tokens);
      decodeAuthToken(tokens);
      requestConfig();
      if ([DEFAULT, LOGIN].includes(history.location.pathname)) {
        this.goToSelectRooms();
      }
      return;
    }
    this.goToLogin();
  }

  goToSelectRooms = () => {
    this.props.history.push(SELECT_ROOMS);
  }

  goToLogin = () => {
    this.props.history.push(LOGIN);
  }

  render() {
    const { children } = this.props;
    if (children) {
      return <> {children} </>;
    }
    return <Loader />;
  }
}
