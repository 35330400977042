import React, { Component } from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import {getStore} from '../../store';
import { getCurrentTheme } from '../../store/config/config.selector';
import { AUTO_HIDE_DURATION, MESSAGE_TYPES } from './notification.const';
import './notification.scss';

export default class Notification extends Component {

  state = {
    open: true
  }

  handleClose = () => {
    this.setState({
      open: false
    }, () => {
      const { onClose } = this.props;
      if (onClose && typeof onClose === 'function') {
        onClose();
      }
    });
  }

  renderAction = () => {
    return (
      <React.Fragment>
        <IconButton className = 'notification-close' size='small'  color='primary' onClick={this.handleClose}>
          <Close />
        </IconButton>
      </React.Fragment>
    );
  }

  render() {
    const { open } = this.state;
    if (open) {
      const store = getStore().getState();
      const themeObj = getCurrentTheme(store);
      const theme = themeObj ? createMuiTheme(themeObj.theme): null;
      const { message, type } = this.props;
      let classes = `${type || MESSAGE_TYPES.INFO }`;
      return (
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Snackbar
            className={classes}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            open={open}
            autoHideDuration={AUTO_HIDE_DURATION}
            onClose={this.handleClose}
            message={message}
            action={this.renderAction()}
          />
        </ThemeProvider>
      );
    }
    return null;
  }
}