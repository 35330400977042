import flatten from 'lodash/flatten';

const getListofParamsFromResourceHierarchy = (resource, parameter = 'id') => {
  // get the list of parameters of all resources (schools, buildings, rooms ) from the tree structure
  if (!resource) {
    return [];
  }
  let list = [];
  if (Array.isArray(resource.members)) {
    list =  resource.members.map( item => {
      if (item.hasMembers && item.members && item.members.length > 0) {
        return getListofParamsFromResourceHierarchy(item);
      }
      return item[parameter].toString();
    });
    list = flatten(list);
  }

  return [resource[parameter].toString()].concat(list);
};

export default getListofParamsFromResourceHierarchy;