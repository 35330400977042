import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { requestCreateAdhocSchedule } from '../../store/schedules/schedules.actions';
import { saveScheduleDetails, clearScheduleDetails, clearResources } from '../../store/session/session.actions';
import { getScheduleListSelector, getScheduleLoaderSelector } from '../../store/schedules/schedules.selector';
import { getSavedscheduleDetails, getSavedResources } from '../../store/session/session.selector';
import { getIsMobileSelector, getTimezoneSelector } from '../../store/config/config.selector';
import selectDeviceLayout from '../../utils/selectDeviceLayout/selectDeviceLayout';
import DesktopComponent from './desktop/scheduleDetails.desktop';
import MobileComponent from './mobile/scheduleDetails.mobile';

const mapStateToProps = (state) => {
  return {
    isMobile: getIsMobileSelector(state),
    districtTimezone: getTimezoneSelector(state),
    savedResources: getSavedResources(),
    savedScheduleDetails: getSavedscheduleDetails(),
    scheduleLoading: getScheduleLoaderSelector(state),
    scheduleList: getScheduleListSelector(state)
  };
};

const mapDispatchToProps = {
  requestCreateAdhocSchedule,
  saveScheduleDetails,
  clearScheduleDetails,
  clearResources
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(selectDeviceLayout(DesktopComponent, MobileComponent)));
