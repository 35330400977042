import React from 'react';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
// import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import BaseHeaderComponent from '../header.base';
import DrawerComponent from './drawer/drawer';
// import AppLogo from '../../../../images/logo-mobile.svg';

// Keeping the commented code as this might change in the near future
class MobileHeader extends BaseHeaderComponent {

  state = {
    drawerOpen: false
  };

  toggleDrawer = (drawerOpen) => {
    if (typeof drawerOpen === 'boolean') {
      this.setState({drawerOpen});
    } else {
      this.setState({drawerOpen: !this.state.drawerOpen});
    }
  }

  render() {
    const {drawerOpen} = this.state;
    const {children, profileInfo} = this.props;
    let visibility;
    // let clickHandler;
    if (profileInfo) {
      visibility = 'visible';
      // clickHandler = this.showHome;
    } else {
      visibility = 'hidden';
      // clickHandler = this.showLogin;
    }
    return (
      <AppBar className='header' position='relative' color='transparent'>
        <Toolbar>
          {/* <IconButton color='primary' aria-label='menu' onClick={clickHandler}>
            <Avatar alt='FogHorn' component={AppLogo}/>
          </IconButton> */}
          <Typography variant='subtitle1' className='title'>
            {children}
          </Typography>
          <div style={{visibility: visibility}}>
            <IconButton onClick={this.toggleDrawer} color='primary' className='header-menu-openner'>
              <AccountCircle />
            </IconButton>
            <DrawerComponent
              drawerOpen = { drawerOpen }
              toggleDrawer = { this.toggleDrawer }
              profileInfo = { profileInfo }
              getMenuItemsForUser = {this.getMenuItemsForUser}
            />
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}

export default MobileHeader;