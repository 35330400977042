import RequestorToolStorage from './requestorToolStorage';
import {FIELDS, ACTION_TYPE} from './session.const';

const action = {
  type: ACTION_TYPE
};

export const saveToken = (tokens) => {
  RequestorToolStorage.setProperty(FIELDS.TOKEN, tokens);
  return action;
};

export const clearToken = () => {
  RequestorToolStorage.removeProperty(FIELDS.TOKEN);
  return action;
};

export const saveResources = (resources) => {
  RequestorToolStorage.setProperty(FIELDS.RESOURCES, resources);
  return action;
};

export const clearResources = () => {
  RequestorToolStorage.removeProperty(FIELDS.RESOURCES);
  return action;
};

export const saveScheduleDetails = (scheduleDetails) => {
  RequestorToolStorage.setProperty(FIELDS.SCHEDULE_DETAILS, scheduleDetails);
  return action;
};

export const clearScheduleDetails = () => {
  RequestorToolStorage.removeProperty(FIELDS.SCHEDULE_DETAILS);
  return action;
};

export const clearStorage = () => {
  RequestorToolStorage.removeAll();
  return action;
};