import React from 'react';
import BaseComponent from '../vacateRequest.base';
import HeaderContainer from '../../header/header.container';
import BrandingContainer from '../../branding/branding.container';
import DateTimeComponent from '../../dateTime/desktop/dateTime.desktop';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import Send from '@material-ui/icons/Send';
import { SUBJECT_MAXLENGTH } from '../../../app.const';
import { RESOURCE_SELECTION_TYPES, REQUEST_SUBMISSION_TEXT } from '../vacateRequest.const';

export default class VacateRequestDesktopComponent extends BaseComponent {

  renderSubject = () => {
    const { subject }=this.state;
    return (
      <Grid container spacing={4}>
        <Grid item xs={3} className='label'>
          <label>Subject*</label>
        </Grid>
        <Grid item xs={8}>
          <TextField variant='filled' required fullWidth autoFocus placeholder='Subject'
            inputProps={{className:'input-text', maxLength: SUBJECT_MAXLENGTH }} value={subject}
            name='subject' onChange={this.fieldUpdateHandler}
          />
        </Grid>
      </Grid>
    );
  }

  renderResourceList = () => {
    const { resourceSelectionType, isDistrictAdminFlag } = this.state;
    if (isDistrictAdminFlag) {
      return (
        <>
          <Grid container spacing={4}>
            <Grid item xs={3} className='label'>
              <label>Applicable to*</label>
            </Grid>
            <Grid item xs={1}>
              <Radio checked={resourceSelectionType === RESOURCE_SELECTION_TYPES.DISTRICT} name='resourceSelectionType'
                value={RESOURCE_SELECTION_TYPES.DISTRICT} onChange={this.resourceSelectionTypeChangeHandler}/>
            </Grid>
            <Grid item xs={7} style={{alignSelf: 'center'}}>
              <span>{this.getDistrictName()}</span>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={3}>
              <label></label>
            </Grid>
            <Grid item xs={1}>
              <Radio checked={resourceSelectionType === RESOURCE_SELECTION_TYPES.SCHOOL} name='resourceSelectionType'
                value={RESOURCE_SELECTION_TYPES.SCHOOL} onChange={this.resourceSelectionTypeChangeHandler}/>
            </Grid>
            <Grid item xs={7}>
              {this.getResourceDropDown()}
            </Grid>
          </Grid>
        </>
      );
    } else {
      return (
        <Grid container spacing={4}>
          <Grid item xs={3} className='label'>
            <label>Applicable to*</label>
          </Grid>
          <Grid item xs={8}>
            {this.getResourceDropDown()}
          </Grid>
        </Grid>
      );
    }
  }

  renderDateTimeComponent = () => {
    const { districtTimezone } = this.props;
    const { startDate, startTime, endDate, endTime, timezone, repeat, repeatEndDate, daysOfWeek } = this.state;
    return <DateTimeComponent
      startDate = { startDate }
      startTime = { startTime }
      endDate = { endDate }
      endTime = { endTime }
      timezone = { timezone }
      repeat = { repeat }
      repeatEndDate = { repeatEndDate }
      daysOfWeek = { daysOfWeek }
      districtTimezone = { districtTimezone }
      setFieldValue = {this.setFieldValue}
      fieldUpdateHandler = {this.fieldUpdateHandler}
    />;
  }

  renderNotes = () => {
    const { notes } = this.state;
    return (
      <Grid container spacing={4}>
        <Grid item xs={3} className='label'>
          <label>Notes</label>
        </Grid>
        <Grid item xs={8}>
          <TextField variant='filled' multiline rows={4} fullWidth placeholder='Notes'
            inputProps={{className:'input-text'}} value={notes}
            name='notes' onChange={this.fieldUpdateHandler}/>
        </Grid>
      </Grid>
    );
  }

  renderActionButtons = () => {
    const { disableButton } = this.state;
    return (
      <Grid container direction="row" justify="space-between" alignItems="center">
        <Grid item>
          <Button onClick={this.goToHome} variant='outlined'>
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button color='primary' endIcon={<Send />} variant='contained' type='submit' disabled = {disableButton}>
            { REQUEST_SUBMISSION_TEXT }
          </Button>
        </Grid>
      </Grid>
    );
  }

  render() {
    const { vacateRequestLoading, resourceLoading } = this.props;
    let loaderContent;

    if (vacateRequestLoading || resourceLoading) {
      loaderContent = this.getLoaderView();
    }

    return (
      <form onSubmit={this.confirmSubmit}>
        <div className='container'>
          <HeaderContainer>SCHEDULER</HeaderContainer>
          <Container className='vacate-schedule' maxWidth="md">
            <div className='content'>
              {loaderContent}
              <Grid container direction="column" justify="center" alignItems="stretch">
                <Grid item className='title-container'>
                  <span className='title'>Schedule Change Request Details</span>
                  <span className='sub-title'>Enter schedule change details and submit the request.</span>
                </Grid>
                <Grid item>
                  <Card className='vacate-schedule-card'>
                    <CardContent>
                      {this.renderSubject()}
                      {this.renderResourceList()}
                      {this.renderDateTimeComponent()}
                      {this.renderNotes()}
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </div>
            <div className='footer'>
              {this.renderActionButtons()}
            </div>
            {this.getSuccessDialog()}
            {this.getConfirmDialog()}
          </Container>
          <div className='grow'></div>
          <BrandingContainer />
        </div>
      </form>
    );
  }
}