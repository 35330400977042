import { createSelector } from 'reselect';
import { PROPS } from './importUtility.const';

export const getImportUtilitySelector = state => state[PROPS.ROOT] || {};

export const getImportUtilityLoaderSelector = createSelector(
  [getImportUtilitySelector], (importUtility) => importUtility[PROPS.LOADING]
);

export const getImportUtilitySuccessSelector = createSelector(
  [getImportUtilitySelector], (importUtility) => importUtility[PROPS.UPLOAD_SUCCESS]
);

export const getImportUtilityValidationMessageSelector = createSelector(
  [getImportUtilitySelector], (importUtility) => importUtility[PROPS.VALIDATION_MESSAGES]
);
