import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';
import MainComponent from './main';
import { loginSuccess, decodeAuthToken} from '../../store/auth/auth.actions';
import { getSavedTokens } from '../../store/session/session.selector';
import { requestConfig } from '../../store/config/config.actions';

const mapStateToProps = (state) => {
  return {
    tokens: getSavedTokens(state)
  };
};

const mapDispatchToProps = {
  loginSuccess,
  decodeAuthToken,
  requestConfig
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MainComponent));
