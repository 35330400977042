export const DEFAULT = '/';
export const LOGIN = '/login';
export const FORGOT_PASSWORD = '/forgot-password';
export const RESET_PASSWORD = '/reset-password';
export const CHANGE_PASSWORD = '/change-password';
export const CHANGE_EXPIRED_PASSWORD = '/change-expired-password';
export const HOME = '/home';
export const SELECT_ROOMS = '/select-rooms';
export const SCHEDULE_DETAILS = '/schedule-details';
export const SCHEDULE_CHANGE = '/schedule-change';
export const IMPORT_UTILITY = '/import-utility';
export const APPROVAL_ITEM = '/approve';
export const PAST_BOOKINGS = '/past-bookings';
export const CONTACT_US = '/support';