import { call, put, takeEvery } from 'redux-saga/effects';
import { CHILD_RESOURCES_FETCH } from '../resources.actionTypes';
import { getChildResourcesApi } from '../resources.apis';
import { resourcesLoading, fetchChildResourcesSuccess, fetchResourcesError, requestChildResources } from '../resources.actions';
import { PLACE_TYPES } from '../../../app.const';
import notification from '../../../components/notification/notification';

export function * fetchChildResources(action) {
  try {
    let props = action.props || {};
    yield put(resourcesLoading());
    const { data } = yield call(getChildResourcesApi, action.parentId, props.includeNestedPlaces || false);
    yield put(fetchChildResourcesSuccess(data, action.parentId));
    if (! props.includeNestedPlaces) {
      for ( let i = 0; i < data.length; i++ ) {
        let resource = data[i];
        if ( props.lastLevel!== resource.placeType && resource.hasMembers) {
          const includeNestedPlacesFlag = resource.placeType === PLACE_TYPES.school.id;
          props = Object.assign({}, props, {includeNestedPlaces: includeNestedPlacesFlag});
          yield put(requestChildResources(resource.id, props));
        }
      }
    }
  } catch (e) {
    // TODO:- HTML code should not be mixed with redux code. Need to find a better place for this.
    const message = e.response && e.response.data ? e.response.data.message : e.message;
    yield call(notification.error, message);
    yield put(fetchResourcesError(message));
  }
}

function * fetchChildResourcesSaga() {
  yield takeEvery(CHILD_RESOURCES_FETCH, fetchChildResources);
}


export default fetchChildResourcesSaga;