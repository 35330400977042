import React, {Component} from 'react';
import _ from 'lodash';
import Dialog from '../dialog/dialog';
import notification from '../notification/notification';
import Loader from '../loader/loader';
import isDistrictAdmin from '../../utils/isDistrictAdmin/isDistrictAdmin';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { DropzoneArea } from 'material-ui-dropzone';
import { SUCCESS_MODAL_PROPS, CONFIRM_MODAL_PROPS } from '../../app.const';
import { SELECT_ROOMS } from '../../router/routes.const';
import { MAX_FILE_SIZE, REQUEST_TYPES } from './importUtility.const';

import './importUtility.scss';

export default class ImportUtilityBaseComponent extends Component{

  state = {
    file: [],
    placeId: '',
    importType: '',
    disableButton: true,
    showConfirmDialog: false,
    showSuccessDialog: false,
    isDistrictAdminFlag: undefined
  };

  componentDidMount() {
    const { requestSchools, resetImportUtilityData } = this.props;
    resetImportUtilityData();
    requestSchools();
    this.setDistrictAdminFlagAndGetDistrict();
  }

  componentDidUpdate(prevProps) {
    const { uploadSuccess: newResponse, validationMessages: newMessages } = this.props;
    const { uploadSuccess: oldResponse, validationMessages: oldMessages } = prevProps;
    const { isDistrictAdminFlag } = this.state;

    if (newResponse && !_.isEqual(newResponse, oldResponse)) {
      this.toggleSuccessDialog();
    }

    if (newMessages && !_.isEqual(newMessages, oldMessages)) {
      if (newMessages.length > 0) {
        this.toggleConfirmDialog();
      } else {
        this.requestSubmitHandler();
      }
    }

    if (isDistrictAdminFlag === undefined) {
      this.setDistrictAdminFlagAndGetDistrict();
    }
  }

  setDistrictAdminFlagAndGetDistrict = () => {
    const { districtName, userProfile, requestDistrict } = this.props;
    if (districtName && userProfile) {
      const isDistrictAdminFlag = isDistrictAdmin(userProfile, districtName);
      this.setState({
        isDistrictAdminFlag : isDistrictAdminFlag
      }, () => {
        if (isDistrictAdminFlag) {
          requestDistrict();
        }
      });
    }
  }

  toggleSuccessDialog = () => {
    this.setState({showSuccessDialog: !this.state.showSuccessDialog});
  }

  importUtilitySuccessHandler = () => {
    this.toggleSuccessDialog();
    this.goToHome();
  }

  fileChangeHandler = (files) => {
    this.setState({
      file: files
    }, () => {
      this.toggleSaveButton();
    });
  }

  fieldUpdateHandler = (event) => {
    const el = event.target;
    this.setState({
      [el.name] : el.value
    }, () => {
      this.toggleSaveButton();
    });
  }

  toggleSaveButton = () => {
    const { file, importType, placeId } = this.state;
    let dataFlag = file.length && importType;
    if (dataFlag && (importType === REQUEST_TYPES.BELL_SCHEDULE.value ||
      importType === REQUEST_TYPES.ZONE_APPROVERS.value)) {
      dataFlag = dataFlag && placeId;
    }
    this.setState({ disableButton : !dataFlag });
  }

  goToHome = () => {
    this.props.history.push(SELECT_ROOMS);
  }

  getDropzone = () => {
    return (
      <DropzoneArea filesLimit={1} showAlerts={['error']}
        acceptedFiles={[
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'application/vnd.ms-excel'
        ]}
        maxFileSize={MAX_FILE_SIZE}
        showPreviews={true} showPreviewsInDropzone={false} useChipsForPreview={true}
        dropzoneText='Drop file here or click to browse.' onChange={this.fileChangeHandler}
      />
    );
  }

  getPlacesDropdown = () => {
    const { placeId } = this.state;
    const { districtList, schoolList } = this.props;
    let menuItems;
    if (schoolList && schoolList.length > 0 ) {
      menuItems = schoolList.map(school => {
        return <MenuItem key={school.id} value={school.id}>{school.displayName}</MenuItem>;
      });
    }
    let firstMenuItem;
    if (districtList && districtList.length > 0) {
      firstMenuItem = districtList.map(district => {
        return <MenuItem key={district.id} value={district.id}>{district.displayName}</MenuItem>;
      });
    }
    return (
      <Select labelId='placeId-label' variant='filled' name='placeId' required fullWidth
        value={placeId} onChange={this.fieldUpdateHandler}>
        {firstMenuItem}
        {menuItems}
      </Select>
    );
  }

  getSuccessDialog = () => {
    const { showSuccessDialog } = this.state;
    const content = <div className='fh-dialog-content'>Upload Successful!</div>;
    const footer = <Button onClick={this.importUtilitySuccessHandler} color="primary">
      {SUCCESS_MODAL_PROPS.buttonText}
    </Button>;
    return (
      <Dialog
        open={showSuccessDialog}
        disableBackdropClick = {SUCCESS_MODAL_PROPS.disableBackdropClick}
        disableEscapeKeyDown = {SUCCESS_MODAL_PROPS.disableEscapeKeyDown}
        header = { SUCCESS_MODAL_PROPS.title }
        content = { content }
        footer = { footer }
        onClose={this.importUtilitySuccessHandler}
      />
    );
  }

  validateRequest = () => {
    const { importType, placeId, file } = this.state;
    if (!importType) {
      return 'Please select a import type';
    }

    if (!file || file.length === 0 ) {
      return 'Please upload a CSV file';
    }

    if ((importType === REQUEST_TYPES.BELL_SCHEDULE.value || importType === REQUEST_TYPES.ZONE_APPROVERS.value)
      && !placeId ) {
      return 'Please select a school';
    }
  }

  getConfirmDialog = () => {
    const { showConfirmDialog } = this.state;
    const { validationMessages } = this.props;
    let contentChildren;
    if (validationMessages && validationMessages.length > 0) {
      contentChildren = validationMessages.map( (message, i) =>
        <div key={i}>{message}</div>
      );
    }
    const content = <div className='fh-dialog-content'>{contentChildren}</div>;
    const footer = <>
      <Button onClick={this.confirmDialogCancelButtonHandler} color="secondary">No</Button>
      <Button onClick={this.requestUploadUtility} color="primary">Yes</Button>
    </>;
    return (
      <Dialog
        open={showConfirmDialog}
        disableBackdropClick = {CONFIRM_MODAL_PROPS.disableBackdropClick}
        disableEscapeKeyDown = {CONFIRM_MODAL_PROPS.disableEscapeKeyDown}
        header = { CONFIRM_MODAL_PROPS.title }
        content = { content }
        footer = { footer }
      />
    );
  }

  toggleConfirmDialog = () => {
    this.setState({showConfirmDialog: !this.state.showConfirmDialog});
  }

  confirmSubmit = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const validationMessage = this.validateRequest();
    if (validationMessage) {
      notification.error(validationMessage);
      return;
    }
    const { importType, placeId, file } = this.state;
    const requestObj = new FormData();
    requestObj.set('importType', importType);
    requestObj.set('placeId', placeId);
    requestObj.set('overrideWarning', false);
    requestObj.append('file', file[0]);
    this.props.resetImportUtilityData();
    this.props.requestValidateImportUtility(requestObj);
  }

  confirmDialogCancelButtonHandler = () => {
    this.toggleConfirmDialog();
    this.props.resetImportUtilityData();
  }

  requestUploadUtility = () => {
    this.toggleConfirmDialog();
    this.requestSubmitHandler();
  }

  requestSubmitHandler = () => {
    const { importType, placeId, file } = this.state;
    const requestObj = new FormData();
    requestObj.set('importType', importType);
    requestObj.set('placeId', placeId);
    requestObj.set('overrideWarning', true);
    requestObj.append('file', file[0]);
    this.props.requestImportUtilityUpload(requestObj);
  }

  getLoaderView = () => {
    return <Loader />;
  }

}