import React from 'react';
import BaseHeaderComponent from '../header.base';
import Themes from '../../../app.theme';
import AppLogo from '../../../../images/logo.svg';
import ThemeIcon from '../../../../images/theme-icon.svg';
import isEqual from 'lodash/isEqual';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import SvgIcon from '@material-ui/core/SvgIcon';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import AccountCircle from '@material-ui/icons/AccountCircle';

class DesktopHeader extends BaseHeaderComponent {

  state = {
    open: false,
    anchorEl: ''
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget});
    this.toggleClick();
  }

  toggleClick = () => {
    this.setState({ open: !this.state.open });
  }

  changeThemeClickHandler = () => {
    const { currentTheme, requestChangeTheme } = this.props;
    const newTheme = isEqual(currentTheme, Themes.DARK) ? Themes.LIGHT : Themes.DARK;
    requestChangeTheme(newTheme);
  }

  getUserDropdown = () => {
    const { open, anchorEl } = this.state;
    const { profileInfo } = this.props;
    const { firstname, lastname } = profileInfo;
    const menuItems = this.getMenuItemsForUser();
    const menuListHtml = menuItems.map((item, i) => {
      return <MenuItem key={i} onClick={item.clickHandler} className = 'header-menu-item'>{item.text}</MenuItem>;
    });
    return (
      <>
        <Button aria-label="user account" aria-controls="menu-appbar" className='header-menu-openner'
          aria-haspopup="true" onClick={this.handleClick}>
          <AccountCircle color='primary'/>
          <span>{firstname} {lastname}</span>
        </Button>
        <Popper open={open} anchorEl={anchorEl} disablePortal transition className='header-menu-popper' >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} style={{ transformOrigin: '0% -10%' }}>
              <Paper className='header-menu-container'>
                <ClickAwayListener onClickAway={this.toggleClick} >
                  <MenuList autoFocusItem={open} className='header-menu'>
                    {menuListHtml}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
    );
  }

  render() {
    const { profileInfo } = this.props;
    let dropDown = null;
    let visibility;
    let clickHandler;
    if (profileInfo) {
      visibility = 'visible';
      dropDown = this.getUserDropdown();
      clickHandler = this.showHome;
    } else {
      visibility = 'hidden';
      clickHandler = this.showLogin;
    }
    return (
      <AppBar className='header' position='relative' color='transparent'>
        <Toolbar variant='dense'>
          <IconButton edge='start' color='inherit' aria-label='menu' onClick={clickHandler}>
            <AppLogo />
          </IconButton>
          <Typography variant='body2' className='header-text'>
            SCHEDULER
          </Typography>
          <div className='header-grow'/>
          <div className='theme-button-container' style={{display: 'none'}}>
            <IconButton onClick={this.changeThemeClickHandler}>
              <SvgIcon color='primary'>
                <ThemeIcon/>
              </SvgIcon>
            </IconButton>
          </div>
          <div style={{visibility: visibility}}>
            {dropDown}
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}
export default DesktopHeader;