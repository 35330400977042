export default class Storage {

  static Types = {
    Session: sessionStorage,
    Local: localStorage
  }

  constructor(name, storage = Storage.Types.Session) {
    this.name = name;

    //default is session storage
    this.storage = storage;
  }

  set(value) {
    if (typeof value === 'object') {
      value = JSON.stringify(value);
    }

    this.storage.setItem(this.name, value);
  }

  setProperty(property, value) {
    let currentValue = this.get() || {};

    if (typeof currentValue === 'object') {
      currentValue[property] = value;
      this.set(currentValue);
    } else {
      throw 'The type of storage is not Object';
    }
  }

  get() {
    try {
      let value = this.storage.getItem(this.name);
      return JSON.parse(value);
    } catch (err) {
      return {};
    }
  }

  getProperty(property) {
    let currentValue = this.get();
    if (typeof currentValue === 'object' && currentValue !== null) {
      return currentValue[property];
    } else {
      return null;
    }
  }

  removeProperty(property) {
    let currentValue = this.get();
    if (typeof currentValue === 'object' && currentValue !== null) {
      delete currentValue[property];
      this.set(currentValue);
    } else {
      return null;
    }
  }

  removeAll() {
    this.storage.removeItem(this.name);
  }

}