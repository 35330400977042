import RequestorToolStorage from './requestorToolStorage';
import {FIELDS} from './session.const';

export const getStore = () => {
  return RequestorToolStorage.get();
};

export const getSavedTokens = () => {
  return RequestorToolStorage.getProperty(FIELDS.TOKEN);
};

export const getSavedResources = () => {
  return RequestorToolStorage.getProperty(FIELDS.RESOURCES);
};

export const getSavedscheduleDetails = () => {
  return RequestorToolStorage.getProperty(FIELDS.SCHEDULE_DETAILS);
};