export const RESOURCES_LOADING = 'RESOURCES_LOADING';
export const RESOURCES_ERROR = 'RESOURCES_ERROR';

export const ALL_RESOURCES_FETCH = 'ALL_RESOURCES_FETCH';
export const All_RESOURCES_SUCCESS = 'All_RESOURCES_SUCCESS';

export const TOP_RESOURCES_FETCH = 'TOP_RESOURCES_FETCH';
export const TOP_RESOURCES_SUCCESS = 'TOP_RESOURCES_SUCCESS';

export const CHILD_RESOURCES_FETCH = 'CHILD_RESOURCES_FETCH';
export const CHILD_RESOURCES_SUCCESS = 'CHILD_RESOURCES_SUCCESS';

export const SEARCH_RESOURCES_FETCH = 'SEARCH_RESOURCES_FETCH';
export const SEARCH_RESOURCES_SUCCESS = 'SEARCH_RESOURCES_SUCCESS';

export const SCHOOLS_FETCH = 'SCHOOLS_FETCH';
export const SCHOOLS_SUCCESS = 'SCHOOLS_SUCCESS';

export const DISTRICT_FETCH = 'DISTRICT_FETCH';
export const DISTRICT_SUCCESS = 'DISTRICT_SUCCESS';

export const SET_FETCH_LATEST_LIST_FLAG = 'SET_FETCH_LATEST_LIST_FLAG';
