import {
  IMPORT_UTILITY_REQUEST,
  IMPORT_UTILITY_LOADING,
  IMPORT_UTILITY_SUCCESS,
  IMPORT_UTILITY_ERROR,
  IMPORT_UTILITY_VALIDATE_REQUEST,
  IMPORT_UTILITY_VALIDATE_SUCCESS,
  IMPORT_UTILITY_RESET_DATA
} from './importUtility.actionTypes';

export const requestImportUtilityUpload = (importObj) => {
  return {
    type: IMPORT_UTILITY_REQUEST,
    data: importObj
  };
};

export const importUtilityUploadLoading = () => {
  return {
    type: IMPORT_UTILITY_LOADING
  };
};

export const importUtilityUploadSuccess = (responseData) => {
  return {
    type: IMPORT_UTILITY_SUCCESS,
    data: responseData
  };
};

export const importUtilityUploadError = (error) => {
  return {
    type: IMPORT_UTILITY_ERROR,
    error: error
  };
};

export const requestValidateImportUtility = (importObj) => {
  return {
    type: IMPORT_UTILITY_VALIDATE_REQUEST,
    data: importObj
  };
};

export const resetImportUtilityData = () => {
  return {
    type: IMPORT_UTILITY_RESET_DATA
  };
};

export const importUtilityValidateSuccess = (responseData) => {
  return {
    type: IMPORT_UTILITY_VALIDATE_SUCCESS,
    data: responseData
  };
};
