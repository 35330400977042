import {
  CREATE_ADHOC_SCHEDULE_LOADING,
  CREATE_ADHOC_SCHEDULE_SUCCESS,
  CREATE_ADHOC_SCHEDULE_ERROR,
  CREATE_VACATE_SCHEDULE_LOADING,
  CREATE_VACATE_SCHEDULE_SUCCESS,
  CREATE_VACATE_SCHEDULE_ERROR,
  GET_SCHEDULES_LOADING,
  GET_SCHEDULES_SUCCESS,
  GET_SCHEDULES_ERROR
} from './schedules.actionTypes';

import {
  setRequestLoading,
  createScheduleSuccess,
  setScheduleList
} from './schedules.reducer.utils';

const scheduleReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_ADHOC_SCHEDULE_LOADING:
      return setRequestLoading(state, true);
    case CREATE_ADHOC_SCHEDULE_SUCCESS:
      return createScheduleSuccess(state, action.data);
    case CREATE_ADHOC_SCHEDULE_ERROR:
      return setRequestLoading(state, false);
    case CREATE_VACATE_SCHEDULE_LOADING:
      return setRequestLoading(state, true);
    case CREATE_VACATE_SCHEDULE_SUCCESS:
      return createScheduleSuccess(state, action.data);
    case CREATE_VACATE_SCHEDULE_ERROR:
      return setRequestLoading(state, false);
    case GET_SCHEDULES_LOADING:
      return setRequestLoading(state, true);
    case GET_SCHEDULES_SUCCESS:
      return setScheduleList(state, action.data);
    case GET_SCHEDULES_ERROR:
      return setRequestLoading(state, false);
    default: return state;
  }
};

export default scheduleReducer;