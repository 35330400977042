export const MIN_TIME = '12:00 AM';
export const MAX_TIME = '11:59 PM';

export const REPEAT_OPTIONS = {
  NEVER: 'never',
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly'
};

export const DAYS_OF_WEEK = {
  Su: 'Su',
  M: 'M',
  Tu: 'Tu',
  W: 'W',
  Th: 'Th',
  F: 'F',
  Sa: 'Sa'
};

export const RECURRENCE_TYPE = {
  ENDDATE: 'endDate',
  OCCURRENCE: 'occurrence'
};

export const DEFAULT_TIMEZONE = 'America/New_York';

export const TIMEZONE_COUNTRY_CODE = 'US';
