import { call, put, takeEvery } from 'redux-saga/effects';
import { SCHOOLS_FETCH } from '../resources.actionTypes';
import { getResourceByTypeApi } from '../resources.apis';
import { resourcesLoading, fetchSchoolsSuccess, fetchResourcesError } from '../resources.actions';
import notification from '../../../components/notification/notification';
import { PLACE_TYPES } from '../../../app.const';

export function * fetchSchools() {
  try {
    yield put(resourcesLoading());
    const { data } = yield call(getResourceByTypeApi, PLACE_TYPES.school.id);
    yield put(fetchSchoolsSuccess(data));
  } catch (e) {
    // TODO:- HTML code should not be mixed with redux code. Need to find a better place for this.
    const message = e.response && e.response.data ? e.response.data.message : e.message;
    yield call(notification.error, message);
    yield put(fetchResourcesError(message));
  }
}

function * fetchSchoolsSaga() {
  yield takeEvery(SCHOOLS_FETCH, fetchSchools);
}


export default fetchSchoolsSaga;