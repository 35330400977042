import axios from 'axios';

const BASE_URL = '/v1/auth';

export const getAuthDetailsApi = async () => {
  const url = `${BASE_URL}/get-auth-details`;
  return await axios.get(url);
};

export const loginApi = async (loginObj) => {
  const url = `${BASE_URL}/login`;
  return await axios.post(url, loginObj);
};

export const refreshTokenApi = async (refresh_token) => {
  const url = `${BASE_URL}/refresh-token`;
  return await axios.post(url, {
    refreshToken: refresh_token
  });
};