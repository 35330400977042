import { call, put, takeEvery } from 'redux-saga/effects';
import { ALL_RESOURCES_FETCH } from '../resources.actionTypes';
import { getResourceHierarchyApi } from '../resources.apis';
import { resourcesLoading, fetchTopLevelResourcesSuccess, fetchResourcesError, setFetchLatestListFlag } from '../resources.actions';
import notification from '../../../components/notification/notification';

export function * fetchAllResources(action) {
  try {
    yield put(resourcesLoading());
    const { data } = yield call(getResourceHierarchyApi, action.payload);
    yield put(fetchTopLevelResourcesSuccess(data));

    // set the flag to false so that the UI does not make the call again
    yield put(setFetchLatestListFlag(false));
  } catch (e) {
    // TODO:- HTML code should not be mixed with redux code. Need to find a better place for this.
    const message = e.response && e.response.data ? e.response.data.message : e.message;
    yield call(notification.error, message);
    yield put(fetchResourcesError(message));
  }
}

function * fetchAllResourcesSaga() {
  yield takeEvery(ALL_RESOURCES_FETCH, fetchAllResources);
}


export default fetchAllResourcesSaga;