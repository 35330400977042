import {
  LOGIN_LOADING,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  SET_DECODED_TOKEN
} from './auth.actionTypes';

import {
  setLoadingState,
  setError,
  setLoginSuccess,
  setDecodedToken
} from './auth.reducer.utils';


const AuthenticationReducer = (state = {}, action) => {
  switch (action.type) {
    case LOGIN_LOADING:
      return setLoadingState(state, true);
    case LOGIN_SUCCESS:
      return setLoginSuccess(state, action.data);
    case LOGIN_ERROR:
      return setError(state, action.error);
    case SET_DECODED_TOKEN:
      return setDecodedToken(state, action.data);
    default:
      return state;
  }
};

export default AuthenticationReducer;