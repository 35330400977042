import {
  SET_MOBILE_FLAG,
  REQUEST_CONFIG,
  REQUEST_CONFIG_LOADING,
  REQUEST_CONFIG_SUCCESS,
  REQUEST_CONFIG_ERROR,
  REQUEST_CHANGE_THEME,
  CHANGE_THEME_SUCCESS
} from './config.actionTypes';

export const setMobileFlag = (mobileFlag) => {
  return {
    type: SET_MOBILE_FLAG,
    data: mobileFlag
  };
};

export const requestConfig = () => {
  return {
    type: REQUEST_CONFIG
  };
};

export const requestConfigLoading = () => {
  return {
    type: REQUEST_CONFIG_LOADING
  };
};

export const requestConfigSuccess = (data) => {
  return {
    type: REQUEST_CONFIG_SUCCESS,
    data
  };
};

export const requestConfigError = (error) => {
  return {
    type: REQUEST_CONFIG_ERROR,
    error: error
  };
};

export const requestChangeTheme = (newTheme) => {
  return {
    type: REQUEST_CHANGE_THEME,
    data: newTheme
  };
};

export const changeThemeSuccess = (newTheme) => {
  return {
    type: CHANGE_THEME_SUCCESS,
    data: newTheme
  };
};