import {
  MICRO_FRONTEND_LOADING,
  MICRO_FRONTEND_SUCCESS,
  MICRO_FRONTEND_ERROR
} from './microFrontends.actionTypes';

import { setRequestLoading, setMicroFrontendSuccess } from './microFrontends.reducer.utils';

const microFrontendReducer = (state = {}, action) => {
  switch (action.type) {
    case MICRO_FRONTEND_LOADING:
      return setRequestLoading(state, true);
    case MICRO_FRONTEND_SUCCESS:
      return setMicroFrontendSuccess(state, action.data);
    case MICRO_FRONTEND_ERROR:
      return setRequestLoading(state, false);
    default: return state;
  }
};

export default microFrontendReducer;