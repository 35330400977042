import React, { Component } from 'react';
import moment from 'moment';
import Loader from '../loader/loader';
import notification from '../notification/notification';
import useQuery from '../../utils/useQuery/useQuery';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import getPlaceHierarchy from '../../utils/getPlaceHierarchy/getPlaceHierarchy';
import { REPEAT_OPTIONS } from '../dateTime/dateTime.const';

import './approval.scss';
import { DATE_FORMAT } from '../../app.const';

export default class ApprovalBaseComponent extends Component {

  state = {
    token: '',
    standby: false,
    approverNotes: '',
    showError: false,
    showSuccess: false
  };

  componentDidMount() {
    const { history, requestBookingData } = this.props;
    const query = useQuery(history.location);
    const token = query.get('token');
    if (token) {
      this.setState({ token: token }, () =>{
        requestBookingData(token);
      });
    } else {
      notification.error('Missing or invalid token');
    }
  }

  componentDidUpdate(prevProps) {
    const { errorMessage: newError, approvalResponse: newResponse } = this.props;
    const { errorMessage: oldError, approvalResponse: oldResponse } = prevProps;
    if ( newError && newError!== oldError) {
      this.setState({ showError: true });
    }

    if (newResponse && newResponse!== oldResponse) {
      this.setState({ showSuccess: true });
    }
  }

  fieldUpdateHandler = (event) => {
    const el = event.target;
    this.setState({
      [el.name] : el.value
    });
  }

  checkboxClickHandler = (event) => {
    const el = event.target;
    this.setState({ [el.name]: !this.state[el.name] });
  }

  getRoomsList = () => {
    const { placeList } = this.props;
    let rooms = placeList.map((place, i) => {
      const hierarchy = getPlaceHierarchy(place.displayFQN);
      return <ListItem key={i} className='p-0'>
        <ListItemText secondary={place.displayName} secondaryTypographyProps={{className: 'hierarchy'}}
          primary={hierarchy} primaryTypographyProps={{className: 'room-name'}} className='room-list' />
      </ListItem>;
    });

    return rooms;
  }

  getRecurrenceText = () => {
    const { bookingRequest } = this.props;
    let repeatText;
    const { recurrence } = bookingRequest;
    if (recurrence) {
      switch (recurrence.type) {
        case REPEAT_OPTIONS.DAILY:
          repeatText = `${recurrence.type} till ${moment(recurrence.recurrenceRange.endDate).format(DATE_FORMAT)}`;
          break;
        case REPEAT_OPTIONS.WEEKLY:
          repeatText = `${recurrence.type} on ${recurrence.weekly.daysOfWeek.join(', ')} till ` +
            `${moment(recurrence.recurrenceRange.endDate).format(DATE_FORMAT)}`;
          break;
        case REPEAT_OPTIONS.MONTHLY:
          repeatText = `${recurrence.type} till ${moment(recurrence.recurrenceRange.endDate).format(DATE_FORMAT)}`;
          break;
        default:
          repeatText = `Never`;
          break;
      }
    }
    return repeatText;
  }

  requestSubmitHandler = (response) => {
    const { standby, approverNotes, token } = this.state;
    const { requestBookingApproval } = this.props;
    const requestObj = {
      approved: response,
      status: standby ? 'standby' : null,
      note: approverNotes
    };
    requestBookingApproval(requestObj, token);
  }

  getLoaderView = () => {
    return <Loader />;
  }
}