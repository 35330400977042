import axios from 'axios';
const BASE_URL = '/v1';

export const createAdhocScheduleApi = async (scheduleRequest) => {
  const url = `${BASE_URL}/requests`;
  return await axios.post(url, scheduleRequest);
};

export const createVacateScheduleApi = async (scheduleRequest) => {
  const url = `${BASE_URL}/vacate`;
  return await axios.post(url, scheduleRequest);
};

export const getSchedulesApi = async () => {
  const url = `${BASE_URL}/requests`;
  return await axios.get(url);
};