// Menus
export const VACATE_SCHEDULE = 'VACATE_SCHEDULE';
export const PAST_BOOKING_REQUESTS = 'PAST_BOOKING_REQUESTS';
export const CHANGE_PASSWORD_REQUESTS = 'CHANGE_PASSWORD_REQUESTS';
export const CALENDAR_IMPORT_UTILITY = 'CALENDAR_IMPORT_UTILITY';
export const CONTACT_US_PAGE = 'CONTACT_US_PAGE';
export const SIGNOUT = 'SIGNOUT';

// Roles
export const CALENDAR_SUPER_ADMIN = 'Calendar_Super_Admin';
export const CALENDAR_ADMIN = 'Calendar_Admin';
export const REQUESTOR = 'Requestor';
export const APPROVER = 'Approver';

const SUPER_ADMIN_MENU = [CALENDAR_IMPORT_UTILITY];
const ADMIN_MENU = [VACATE_SCHEDULE];
const REQUESTOR_MENU = [];
const APPROVER_MENU = [];

export const USER_MENU = {
  [CALENDAR_SUPER_ADMIN]: SUPER_ADMIN_MENU,
  [CALENDAR_ADMIN]: ADMIN_MENU,
  [REQUESTOR]: REQUESTOR_MENU,
  [APPROVER]: APPROVER_MENU
};