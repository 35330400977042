import {
  TOP_RESOURCES_SUCCESS,
  CHILD_RESOURCES_SUCCESS,
  SEARCH_RESOURCES_SUCCESS,
  SCHOOLS_SUCCESS,
  DISTRICT_SUCCESS,
  SET_FETCH_LATEST_LIST_FLAG,
  RESOURCES_LOADING,
  RESOURCES_ERROR
} from './resources.actionTypes';

import Utils from './resources.reducer.utils';

const resourceReducer = (state = {}, action) => {
  switch (action.type) {
    case TOP_RESOURCES_SUCCESS:
      return Utils.setTopResourceSuccess(state, action.data);
    case CHILD_RESOURCES_SUCCESS:
      return Utils.setChildResourceSuccess(state, action.data, action.parentId);
    case SEARCH_RESOURCES_SUCCESS:
      return Utils.setSearchedResourceSuccess(state, action.data, action.searchString);
    case SCHOOLS_SUCCESS:
      return Utils.setSchoolsSuccess(state, action.data);
    case DISTRICT_SUCCESS:
      return Utils.setDistrictSuccess(state, action.data);
    case SET_FETCH_LATEST_LIST_FLAG:
      return Utils.setFetchLatestListFlag(state, action.data);
    case RESOURCES_LOADING:
      return Utils.setResourceLoading(state, true);
    case RESOURCES_ERROR:
      return Utils.setResourceError(state, action.error);
    default: return state;
  }
};



export default resourceReducer;