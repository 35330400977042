import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { requestBookingApproval, requestBookingData } from '../../store/approvals/approvals.actions';
import { getApprovalLoaderSelector, getBookingRequestErrorSelector, getApprovalablePlaceListSelector, getBookingRequestSelector, getApprovalResponseSelector } from '../../store/approvals/approvals.selector';
import { getIsMobileSelector } from '../../store/config/config.selector';
import selectDeviceLayout from '../../utils/selectDeviceLayout/selectDeviceLayout';
import DesktopComponent from './desktop/approval.desktop';
import MobileComponent from './mobile/approval.mobile';

const mapStateToProps = (state) => {
  return {
    isMobile: getIsMobileSelector(state),
    approvalRequestLoading: getApprovalLoaderSelector(state),
    errorMessage: getBookingRequestErrorSelector(state),
    bookingRequest: getBookingRequestSelector(state),
    placeList: getApprovalablePlaceListSelector(state),
    approvalResponse: getApprovalResponseSelector(state)
  };
};

const mapDispatchToProps = {
  requestBookingData,
  requestBookingApproval
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(selectDeviceLayout(DesktopComponent, MobileComponent)));
