import React, { Component } from 'react';
import HeaderContainer from '../header/header.container';
import BrandingContainer from '../branding/branding.container';
import MicroFrontendContainer from '../microFrontend/microFrontend.container';
import { LoginMFName, LoginMFProxyPath, welcomeMessage } from './login.const';
import { SELECT_ROOMS } from '../../router/routes.const';
import { BASE_API_PATH } from '../../app.const';
import './login.scss';

export default class LoginBaseComponent extends Component {

  loginSuccessHandler = ({ token, refreshToken }) => {
    const { requestLogin, history } = this.props;
    const tokens = {
      access_token: token,
      refresh_token: refreshToken
    };
    requestLogin(tokens);
    history.push(SELECT_ROOMS);
  }

  render() {
    const { history, profileInfo, token, currentTheme, requestLogout } = this.props;
    let username;
    let accessToken;
    if (profileInfo) {
      username = profileInfo.username;
    }
    if (token) {
      accessToken = token.access_token;
    }
    return (
      <div className='container'>
        <HeaderContainer>Scheduler</HeaderContainer>
        <div className='content'>
          <div className='login-container fh-login'>
            <MicroFrontendContainer
              history={history}
              name={LoginMFName}
              host={LoginMFProxyPath}
              mfProps={{
                welcomeMessage: welcomeMessage,
                loginSuccess: this.loginSuccessHandler,
                baseApiPath: BASE_API_PATH,
                theme: currentTheme ? currentTheme.theme: null,
                username: username,
                token: accessToken,
                backLinkPath: SELECT_ROOMS,
                logoutFn: requestLogout
              }}
            />
            <div className='version-footer'>
              Version: {process.env.VERSION}
            </div>
          </div>
        </div>
        <BrandingContainer />
      </div>
    );
  }
}