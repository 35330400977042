import {
  SET_MOBILE_FLAG,
  CHANGE_THEME_SUCCESS,
  REQUEST_CONFIG_LOADING,
  REQUEST_CONFIG_SUCCESS,
  REQUEST_CONFIG_ERROR
} from './config.actionTypes';

import {
  setMobileFlag,
  setLoadingFlag,
  setConfig,
  setTheme
} from './config.reducer.utils';

const ConfigReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_MOBILE_FLAG:
      return setMobileFlag(state, action.data);
    case CHANGE_THEME_SUCCESS:
      return setTheme(state, action.data);
    case REQUEST_CONFIG_LOADING:
      return setLoadingFlag(state, true);
    case REQUEST_CONFIG_SUCCESS:
      return setConfig(state, action.data);
    case REQUEST_CONFIG_ERROR:
      return setLoadingFlag(state, false);
    default:
      return state;
  }
};

export default ConfigReducer;