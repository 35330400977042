import React, { Component } from 'react';
import Loader from '../loader/loader';
import If from '../ifComponent/ifComponent';
import injectAssets from '../../utils/injectAssets/injectAssets';

export default class MicroFrontendComponent extends Component {

  componentDidMount() {
    const { host, requestMicroFrontend, name, manifests } = this.props;
    if (manifests[host]) {
      injectAssets({ manifest: manifests[host], name, host }).then(() => {
        this.renderMF();
      });
    } else {
      requestMicroFrontend(host);
    }
  }

  componentDidUpdate(prevProps) {
    const { manifests: newManifests, name, host } = this.props;
    const { manifests: oldManifests } = prevProps;

    if ( !oldManifests[host] && newManifests[host]) {
      injectAssets({ manifest: newManifests[host], name, host }).then(() => {
        this.renderMF();
      });
    }
  }

  renderMF() {
    const { name, history, mfProps } = this.props;
    const renderName = `render${name}`;
    const containerName = `${name}-container`;
    if (window[renderName] && typeof window[renderName] === 'function') {
      window[renderName](containerName, { history, mfProps });
    }
  }

  render() {
    let { name, loading } = this.props;
    return (
      <main id={`${name}-container`} >
        <If condition={loading}>
          <Loader />
        </If>
      </main>
    );
  }

  componentWillUnmount() {
    const { name } = this.props;
    window[`unmount${name}`](`${name}-container`);
  }
}