import RequestorToolLocalStorage from './requestorToolLocalStorage';
import { FIELDS } from './localStorage.const';

export const getRecentSearches = () => {
  return RequestorToolLocalStorage.getProperty(FIELDS.RECENT_SEARCHES) || [];
};

export const getCurrentThemeFromLocalStorage = () => {
  return RequestorToolLocalStorage.getProperty(FIELDS.THEME);
};
