import path from 'path';

const injectScript = ({ filePath, name, host }) => {
  const scriptId = `mf-script-${name}-${filePath}`;

  if (document.getElementById(scriptId)) {
    return;
  }

  return new Promise(resolve => {
    const script = document.createElement('script');
    script.type = 'text/javascript';

    script.id = scriptId;
    script.src = path.join(host, filePath);
    script.defer = true;
    script.async = true;
    script.onload = () => resolve();

    document.body.appendChild(script);
  });
};

const injectStyle = ({ filePath, name, host }) => {
  const linkId = `mf-style-${name}-${filePath}`;

  if (document.getElementById(linkId)) {
    return;
  }

  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.type = 'text/css';

  link.id = linkId;
  link.href = path.join(host, filePath);

  document.head.appendChild(link);
};

const injectAssets = ({ manifest, name, host }) => {
  let entrypoints = manifest? manifest.entrypoints : [];
  let promises = [];
  for (let entry of entrypoints) {
    if (entry.match('.js')) {
      promises.push(injectScript({ filePath: entry, name, host }));
    } else {
      promises.push(injectStyle({ filePath: entry, name, host }));
    }
  }
  return Promise.all(promises);
};

export default injectAssets;