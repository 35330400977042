export const SET_MOBILE_FLAG = 'SET_MOBILE_FLAG';

export const REQUEST_CONFIG = 'REQUEST_CONFIG';
export const REQUEST_CONFIG_LOADING = 'REQUEST_CONFIG_LOADING';
export const REQUEST_CONFIG_SUCCESS = 'REQUEST_CONFIG_SUCCESS';
export const REQUEST_CONFIG_ERROR = 'REQUEST_CONFIG_ERROR';


export const REQUEST_CHANGE_THEME = 'REQUEST_CHANGE_THEME';
export const CHANGE_THEME_SUCCESS = 'CHANGE_THEME_SUCCESS';
export const CHANGE_THEME_ERROR = 'CHANGE_THEME_ERROR';

