import { call, put, takeEvery } from 'redux-saga/effects';
import { IMPORT_UTILITY_VALIDATE_REQUEST } from '../importUtility.actionTypes';
import { validateImportUtilityApi } from '../importUtility.apis';
import { importUtilityUploadLoading, importUtilityValidateSuccess, importUtilityUploadError } from '../importUtility.actions';
import notification from '../../../components/notification/notification';

export function * validateImportUtility(action) {
  try {
    yield put(importUtilityUploadLoading());
    const { data } = yield call(validateImportUtilityApi, action.data);
    yield put(importUtilityValidateSuccess(data));
  } catch (e) {
    // TODO:- HTML code should not be mixed with redux code. Need to find a better place for this.
    const message = e.response && e.response.data ? e.response.data.message : e.message;
    yield call(notification.error, message);
    yield put(importUtilityUploadError(message));
  }
}

function * validateImportUtilitySaga() {
  yield takeEvery(IMPORT_UTILITY_VALIDATE_REQUEST, validateImportUtility);
}


export default validateImportUtilitySaga;