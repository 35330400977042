import { call, put, takeEvery } from 'redux-saga/effects';
import { REQUEST_CHANGE_THEME } from '../config.actionTypes';
import { changeThemeSuccess } from '../config.actions';
import { updateTheme } from '../../localStorage/localStorage.actions';
import notification from '../../../components/notification/notification';

export function * changeTheme(action) {
  if (action.data) {
    yield put(updateTheme(action.data));
    yield put(changeThemeSuccess(action.data));
  } else {
    // TODO:- HTML code should not be mixed with redux code. Need to find a better place for this.
    const message = 'The theme selection has caused some error. Please try again.';
    yield call(notification.error, message);
  }
}

function * changeThemeSaga() {
  yield takeEvery(REQUEST_CHANGE_THEME, changeTheme);
}


export default changeThemeSaga;