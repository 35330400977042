import React from 'react';
import BaseComponent from '../dateTime.base';
import If from '../../ifComponent/ifComponent';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TodayIcon from '@material-ui/icons/Today';
import Event from '@material-ui/icons/Event';
import Loop from '@material-ui/icons/Loop';
import ViewWeekIcon from '@material-ui/icons/ViewWeek';
import Update from '@material-ui/icons/Update';
import AccessTime from '@material-ui/icons/AccessTime';
import EventNote from '@material-ui/icons/EventNote';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import { REPEAT_OPTIONS } from '../dateTime.const';
import { DATE_FORMAT, TIME_FORMAT, IconSizes } from '../../../app.const';
import combineDateTime from '../../../utils/combineDateTime/combineDateTime';
import humanizeDuration from 'humanize-duration';

export default class BookingDateTimeMobileComponent extends BaseComponent {

  constructor(props) {
    super(props);
    this.renderAllDayCheckbox.bind(this);
    this.renderStartDate.bind(this);
    this.renderEndDate.bind(this);
    this.renderStartEndTime.bind(this);
    this.renderRepeatDropdown.bind(this);
    this.renderRecurringSection.bind(this);
    this.renderStartDateTime.bind(this);
    this.renderEndDateTime.bind(this);
  }

  renderStartDate() {
    const { startDate, timezone } = this.props;
    return (
      <ListItem>
        <ListItemAvatar>
          <TodayIcon color='primary' fontSize={IconSizes.Large}/>
        </ListItemAvatar>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker inputVariant='filled' inputValue={startDate} name='startDate'
            InputAdornmentProps={{className: 'picker-icon-button'}} required label="Event Start Date"
            format={DATE_FORMAT} disablePast onChange={this.startDateChangeHandler}
            rightArrowButtonProps={{color:'primary'}} leftArrowButtonProps={{color:'primary'}}
            helperText={timezone} keyboardIcon={<EventNote color='primary' />}/>
        </MuiPickersUtilsProvider>
      </ListItem>
    );
  }

  renderEndDate() {
    const { endDate } = this.props;
    return (
      <ListItem>
        <ListItemAvatar>
          <Event color='primary' fontSize={IconSizes.Large}/>
        </ListItemAvatar>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker inputVariant='filled' inputValue={endDate} name='endDate'
            InputAdornmentProps={{className: 'picker-icon-button'}} required label="Event End Date"
            format={DATE_FORMAT} disablePast onChange={this.endDateChangeHandler}
            rightArrowButtonProps={{color:'primary'}} leftArrowButtonProps={{color:'primary'}}
            keyboardIcon={<EventNote color='primary' />}/>
        </MuiPickersUtilsProvider>
      </ListItem>
    );
  }

  renderStartEndTime() {
    const { startTime, endTime } = this.props;
    const { disableDateTime } = this.state;
    return (
      <ListItem>
        <ListItemAvatar>
          <AccessTime color='primary' fontSize={IconSizes.Large}/>
        </ListItemAvatar>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardTimePicker inputVariant='filled' inputValue={startTime} name='startTime'
            className='time-field' InputAdornmentProps={{className: 'picker-icon-button'}}
            required label="Start Time" keyboardIcon={<AccessTime color='primary' />} disabled = { disableDateTime }
            format={TIME_FORMAT} disablePast onChange={this.startTimeChangeHandler}/>
        </MuiPickersUtilsProvider>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardTimePicker inputVariant='filled' inputValue={endTime} name='endTime'
            className='time-field' InputAdornmentProps={{className: 'picker-icon-button'}}
            required label="End Time" keyboardIcon={<AccessTime color='primary'/>} disabled = { disableDateTime }
            format={TIME_FORMAT} disablePast onChange={this.endTimeChangeHandler}/>
        </MuiPickersUtilsProvider>
      </ListItem>
    );
  }

  renderStartDateTime() {
    const { startTime, startDate } = this.props;
    const { disableDateTime } = this.state;
    return (
      <ListItem>
        <ListItemAvatar>
          <AccessTime color='primary' fontSize={IconSizes.Large}/>
        </ListItemAvatar>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker inputVariant='filled' inputValue={startDate} name='startDate'
            InputAdornmentProps={{className: 'picker-icon-button'}} required label="Event starts on"
            format={DATE_FORMAT} disablePast onChange={this.startDateChangeHandler}
            rightArrowButtonProps={{color:'primary'}} leftArrowButtonProps={{color:'primary'}}
            className='time-field'
            // helperText={timezone}
            keyboardIcon={<EventNote color='primary' />}/>
        </MuiPickersUtilsProvider>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardTimePicker inputVariant='filled' inputValue={startTime} name='startTime'
            className='time-field' InputAdornmentProps={{className: 'picker-icon-button'}}
            required label="Event start time" keyboardIcon={<AccessTime color='primary' />}
            disabled = { disableDateTime }
            format={TIME_FORMAT} disablePast onChange={this.startTimeChangeHandler}/>
        </MuiPickersUtilsProvider>
      </ListItem>
    );
  }

  renderEndDateTime() {
    const { endDate, endTime, repeat} = this.props;
    const { disableDateTime } = this.state;
    return (
      <ListItem>
        <ListItemAvatar>
          <AccessTime color='primary' fontSize={IconSizes.Large}/>
        </ListItemAvatar>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker inputVariant='filled'
            inputValue={endDate}
            name='endDate'
            InputAdornmentProps={{className: 'picker-icon-button'}} required label="Event ends on"
            format={DATE_FORMAT} disablePast onChange={this.endDateChangeHandler}
            rightArrowButtonProps={{color:'primary'}} leftArrowButtonProps={{color:'primary'}}
            className='time-field'
            disabled={repeat !== REPEAT_OPTIONS.NEVER}
            keyboardIcon={<EventNote color='primary' />}/>
        </MuiPickersUtilsProvider>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardTimePicker inputVariant='filled' inputValue={endTime} name='endTime'
            className='time-field' InputAdornmentProps={{className: 'picker-icon-button'}}
            required label="Event end time" keyboardIcon={<AccessTime color='primary'/>}
            disabled = { disableDateTime }
            format={TIME_FORMAT} disablePast onChange={this.endTimeChangeHandler}/>
        </MuiPickersUtilsProvider>
      </ListItem>
    );
  }

  renderDuration() {
    const {startTime, endTime, startDate, endDate} = this.props;
    const startDateTime = combineDateTime(startDate, startTime);
    const endDateTime = combineDateTime(endDate, endTime);
    return (
      <ListItem>
        <ListItemAvatar>
          <Update color='primary' fontSize={IconSizes.Large}/>
        </ListItemAvatar>
        {startTime && endTime ? endDateTime > startDateTime ? humanizeDuration((endDateTime - startDateTime)): '': ''}
      </ListItem>
    );
  }

  renderAllDayCheckbox() {
    const { allDayFlag } = this.state;
    return (
      <ListItem>
        <ListItemAvatar>
          <Update color='primary' fontSize={IconSizes.Large}/>
        </ListItemAvatar>
        <Typography>
          <Checkbox
            style={{ marginRight: 8 }}
            checked={allDayFlag}
            onChange = {this.allDayFlagChangeHandler.bind(this)}
          />
          All day event
        </Typography>
      </ListItem>
    );
  }

  renderRepeatDropdown() {
    const { repeat } = this.props;
    const menuItems = Object.keys(REPEAT_OPTIONS).map(item =>
      <MenuItem key={item} className='repeat-select' value={REPEAT_OPTIONS[item]}>{REPEAT_OPTIONS[item]}</MenuItem>
    );
    return (
      <ListItem>
        <ListItemAvatar>
          <Loop color='primary' fontSize={IconSizes.Large}/>
        </ListItemAvatar>
        <FormControl>
          <InputLabel id='repeat-label' className='select-dropdown-label' >Repeats</InputLabel>
          <Select variant='filled' name='repeat' labelId='repeat-label' required fullWidth
            classes={{select: 'repeat-select'}} value={repeat}
            onChange={this.repeatChangeHandler}
          >
            {menuItems}
          </Select>
        </FormControl>
      </ListItem>
    );
  }

  renderRecurringSection() {
    const { repeatEndDate } = this.props;
    return (
      <ListItem>
        <ListItemAvatar>
          <Event color='primary' fontSize={IconSizes.Large}/>
        </ListItemAvatar>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker inputVariant='filled' fullWidth inputValue={repeatEndDate}
            label="Ends on" name='repeatEndDate' InputAdornmentProps={{className: 'picker-icon-button'}}
            format={DATE_FORMAT} required disablePast onChange={this.repeatEndDateChangeHandler}
            rightArrowButtonProps={{color:'primary'}}
            leftArrowButtonProps={{color:'primary'}}
            keyboardIcon={<EventNote color='primary' />}/>
        </MuiPickersUtilsProvider>
      </ListItem>
    );
  }

  renderDaysOfWeek() {
    const { daysOfWeek } = this.props;
    if (daysOfWeek && Array.isArray(daysOfWeek)) {
      const list = this.getDaysOfWeekItems();
      return (
        <ListItem>
          <ListItemAvatar>
            <ViewWeekIcon color='primary' fontSize={IconSizes.Large}/>
          </ListItemAvatar>
          <div className ='days-container'>
            { list }
          </div>
        </ListItem>
      );
    }
  }

  render() {
    const { repeat } = this.props;
    return (
      <>
        { this.renderStartDateTime()}
        { this.renderEndDateTime()}
        { this.renderDuration() }
        { this.renderAllDayCheckbox() }
        { this.renderRepeatDropdown() }
        <If condition={repeat === REPEAT_OPTIONS.WEEKLY}>
          { this.renderDaysOfWeek() }
        </If>
        <If condition={repeat !== REPEAT_OPTIONS.NEVER}>
          { this.renderRecurringSection() }
        </If>
      </>
    );
  }
}