import React from 'react';
import BaseComponent from '../importUtility.base';
import HeaderContainer from '../../header/header.container';
import BrandingContainer from '../../branding/branding.container';
import If from '../../ifComponent/ifComponent';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Publish from '@material-ui/icons/Publish';

import { REQUEST_TYPES } from '../importUtility.const';

export default class ImportUtilityDesktopComponent extends BaseComponent {

  renderImportTypeDropdown = () => {
    const { importType } = this.state;
    const menuItems = Object.values(REQUEST_TYPES).map(obj => {
      return <MenuItem key={obj.value} value={obj.value}>{obj.label}</MenuItem>;
    });
    return (
      <Grid container spacing={4}>
        <Grid item xs={3} className='label'>
          <label>Request Type*</label>
        </Grid>
        <Grid item xs={8}>
          <Select value={importType} variant='filled' name='importType'
            fullWidth required onChange={this.fieldUpdateHandler}>
            <MenuItem value='' disabled>Select request type</MenuItem>
            { menuItems }
          </Select>
        </Grid>
      </Grid>
    );
  }

  renderResourceDropdown = () => {
    return (
      <Grid container spacing={4}>
        <Grid item xs={3} className='label'>
          <label>Applicable to*</label>
        </Grid>
        <Grid item xs={8}>
          {this.getPlacesDropdown()}
        </Grid>
      </Grid>
    );
  }

  renderCsvUpload = () => {
    return (
      <Grid container spacing={4}>
        <Grid item xs={3} className='label'>
          <label>File*</label>
        </Grid>
        <Grid item xs={8}>
          { this.getDropzone() }
        </Grid>
      </Grid>
    );
  }

  renderActionButtons = () => {
    const { disableButton } = this.state;
    return (
      <Grid container direction="row" justify="space-between" alignItems="center">
        <Grid item>
          <Button onClick={this.goToHome} variant='outlined'>
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button variant='contained' color='primary' endIcon={<Publish />} type='submit' disabled = {disableButton}>
            Upload
          </Button>
        </Grid>
      </Grid>
    );
  }

  render() {
    const { resourceLoading, uploadLoading } = this.props;
    const { importType } = this.state;
    let loaderContent;
    if ( resourceLoading || uploadLoading ) {
      loaderContent = this.getLoaderView();
    }
    return (
      <form onSubmit={this.confirmSubmit}>
        <div className='container'>
          <HeaderContainer>SCHEDULER</HeaderContainer>
          <Container className='import-utility' maxWidth="md">
            <div className='content'>
              {loaderContent}
              <Grid container direction="column" justify="center" alignItems="stretch">
                <Grid item className='title-container'>
                  <span className='title'>Import Utility</span>
                  <span className='sub-title'>Upload your CSV here.</span>
                </Grid>
                <Grid item>
                  <Card className='card import-utility-card'>
                    <CardContent>
                      { this.renderImportTypeDropdown() }
                      <If condition={ importType === REQUEST_TYPES.BELL_SCHEDULE.value ||
                        importType === REQUEST_TYPES.ZONE_APPROVERS.value || importType === REQUEST_TYPES.USERS.value ||
                        importType === REQUEST_TYPES.VENSTAR_CONFIG.value}>
                        { this.renderResourceDropdown() }
                      </If>
                      {this.renderCsvUpload()}
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </div>
            <div className='footer'>
              {this.renderActionButtons()}
            </div>
            {this.getSuccessDialog()}
            {this.getConfirmDialog()}
          </Container>
          <div className='grow'></div>
          <BrandingContainer />
        </div>
      </form>
    );
  }
}