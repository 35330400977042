import CIPLogo from '../../../images/logo-CIP.svg';
import HCPSLogo from '../../../images/logo-HCPS.svg';

const CLIENT_CONFIG = {
  HCPS: {
    supportEmail: 'support@hcps.fhws.io',
    brandingImages: [HCPSLogo, CIPLogo]
  }
};

const DEFAULT_CONFIG = {
  supportEmail: 'email'
};

const getClientConfig = () => {
  let clientName = window.CLIENT_NAME || '';
  clientName = clientName.toUpperCase();
  return CLIENT_CONFIG[clientName] || DEFAULT_CONFIG;
};

export default getClientConfig;