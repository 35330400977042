import { combineReducers } from 'redux';
import {LOGOUT} from './auth/auth.actionTypes';
import ResourcesReducer from './resources/resources.reducer';
import AuthReducer from './auth/auth.reducer';
import ConfigReducer from './config/config.reducer';
import ScheduleReducer from './schedules/schedules.reducer';
import ImportUtilityReducer from './importUtility/importUtility.reducer';
import MicroFrontendReducer from './microFrontends/microFrontends.reducer';
import ApprovalReducer from './approvals/approvals.reducer';

import { PROPS as AuthProps } from './auth/auth.const';
import { PROPS as ImportUtilityProps } from './importUtility/importUtility.const';
import { PROPS as ScheduleProps } from './schedules/schedules.const';
import { PROPS as MicroFrontendProps } from './microFrontends/microFrontend.const';
import { PROPS as ApprovalProps } from './approvals/approvals.const';
import { PROPS as ConfigProps } from './config/config.const';

const reducers = combineReducers({
  [AuthProps.ROOT]: AuthReducer,
  resource: ResourcesReducer,
  [ConfigProps.ROOT]: ConfigReducer,
  [ScheduleProps.ROOT]: ScheduleReducer,
  [ImportUtilityProps.ROOT]: ImportUtilityReducer,
  [MicroFrontendProps.ROOT]: MicroFrontendReducer,
  [ApprovalProps.ROOT]: ApprovalReducer
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    state = undefined;
  }
  return reducers(state, action);
};

export default rootReducer;