import { connect } from 'react-redux';
import RoutesComponent from './routes';
import {getIsMobileSelector, getCurrentTheme} from '../store/config/config.selector';
import {setMobileFlag, requestChangeTheme} from '../store/config/config.actions';

const mapStateToProps = (state, ownProps) => {
  return {
    isMobile: getIsMobileSelector(state),
    currentTheme: getCurrentTheme(state),
    originalTheme: ownProps.originalTheme,
    setTheme: ownProps.setTheme
  };
};

const mapDispatchToProps = {
  setMobileFlag,
  requestChangeTheme
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RoutesComponent);
