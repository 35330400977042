import { createSelector } from 'reselect';
import { PROPS } from './schedules.const';

export const getScheduleSelector = state => state[PROPS.ROOT] || {};

export const getScheduleLoaderSelector = createSelector(
  [getScheduleSelector], (schedule) => schedule[PROPS.REQUEST_LOADING]
);

export const getScheduleListSelector = createSelector(
  [getScheduleSelector], (schedule) => schedule[PROPS.LIST] || []
);
