import { DAYS_OF_WEEK } from '../../components/dateTime/dateTime.const';

const sortWeekDays = (inputArray) => {
  const weekdayOrder = Object.values(DAYS_OF_WEEK);
  const sortedArray = inputArray.sort((a, b) => weekdayOrder.indexOf(a) - weekdayOrder.indexOf(b));
  return sortedArray;
};


export default sortWeekDays;
