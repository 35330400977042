import React from 'react';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import BaseComponent from '../approval.base';
import If from '../../ifComponent/ifComponent';
import HeaderContainer from '../../header/header.container';
import BrandingContainer from '../../branding/branding.container';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { DISPLAY_DATE_TIME_FORMAT } from '../../../app.const';

export default class ApprovalDesktopComponent extends BaseComponent {

  renderRequestor = () => {
    const { bookingRequest } = this.props;
    return (
      <Grid container spacing={4}>
        <Grid item xs={3} className='label'>
          <label>Requestor</label>
        </Grid>
        <Grid item xs={8}>
          <span>{bookingRequest.requesterId}</span>
        </Grid>
      </Grid>
    );
  }

  renderRoomList = () => {
    const rooms = this.getRoomsList();
    return (
      <Grid container spacing={4}>
        <Grid item xs={3} className='label'>
          <label>Room(s) requested</label>
        </Grid>
        <Grid item xs={8}>
          <List className='room-list'>
            {rooms}
          </List>
        </Grid>
      </Grid>
    );
  }

  renderStartDateTime() {
    const { bookingRequest } = this.props;
    return (
      <Grid container spacing={4}>
        <Grid item xs={3} className='label'>
          <label>Starts on</label>
        </Grid>
        <Grid item xs={8}>
          {moment(bookingRequest.start).format(DISPLAY_DATE_TIME_FORMAT)}
        </Grid>
      </Grid>
    );
  }

  renderEndDateTime() {
    const { bookingRequest } = this.props;
    return (
      <Grid container spacing={4}>
        <Grid item xs={3} className='label'>
          <label>Ends on</label>
        </Grid>
        <Grid item xs={8}>
          {moment(bookingRequest.end).format(DISPLAY_DATE_TIME_FORMAT)}
        </Grid>
      </Grid>
    );
  }

  renderTimezone() {
    const { bookingRequest } = this.props;
    return (
      <Grid container spacing={4}>
        <Grid item xs={3} className='label'>
          <label>Timezone</label>
        </Grid>
        <Grid item xs={8}>
          {bookingRequest.timeZone}
        </Grid>
      </Grid>
    );
  }

  renderRepeatSection() {
    const repeatText = this.getRecurrenceText();
    return (
      <Grid container spacing={4}>
        <Grid item xs={3} className='label'>
          <label>Repeat</label>
        </Grid>
        <Grid item xs={8}>
          {repeatText}
        </Grid>
      </Grid>
    );
  }

  renderSubject = () => {
    const { bookingRequest } = this.props;
    return (
      <Grid container spacing={4}>
        <Grid item xs={3} className='label'>
          <label>Subject</label>
        </Grid>
        <Grid item xs={8}>
          <span>{bookingRequest.subject}</span>
        </Grid>
      </Grid>
    );
  }

  renderNotes = () => {
    const { bookingRequest } = this.props;
    return (
      <Grid container spacing={4}>
        <Grid item xs={3} className='label'>
          <label>Note by requestor</label>
        </Grid>
        <Grid item xs={8}>
          {bookingRequest.note || '-'}
        </Grid>
      </Grid>
    );
  }

  renderStandbyCheckbox() {
    const { standby } = this.state;
    return (
      <Grid container spacing={4}>
        <Grid item xs={3} className='label checkbox-label'>
          <label>Opt-in for Stand-by mode</label>
        </Grid>
        <Grid item xs={8}>
          <Checkbox
            name='standby'
            checked={standby}
            className='checkbox'
            color='primary'
            onChange = {this.checkboxClickHandler}
          />
        </Grid>
      </Grid>
    );
  }

  renderApproverCommentInput = () => {
    const { approverNotes } = this.state;
    return (
      <Grid container spacing={4}>
        <Grid item xs={3} className='label'>
          <label>Comments</label>
        </Grid>
        <Grid item xs={8}>
          <TextField variant='filled' multiline rows={4} fullWidth
            inputProps={{className:'input-text'}} value={approverNotes}
            name='approverNotes' onChange={this.fieldUpdateHandler}/>
        </Grid>
      </Grid>
    );
  }

  renderActionButtons = () => {
    return (
      <Grid container direction="row" justify="space-between" alignItems="center">
        <Grid item>
          <Button type='button' variant='outlined' color='primary' endIcon={<ClearIcon />}
            onClick={this.requestSubmitHandler.bind(this, false)} >
            Reject
          </Button>
        </Grid>
        <Grid item>
          <Button type='button' variant='contained' color='primary' endIcon={<CheckIcon />}
            onClick={this.requestSubmitHandler.bind(this, true)}>
            Approve
          </Button>
        </Grid>
      </Grid>
    );
  }

  render() {
    const { approvalRequestLoading, errorMessage, bookingRequest } = this.props;
    const { showError, showSuccess } = this.state;

    return (
      <div className='container'>
        <HeaderContainer>SCHEDULER</HeaderContainer>
        <Container className='approval-container' maxWidth="md">
          <If condition={approvalRequestLoading}>
            <div className='content'>
              { this.getLoaderView() }
            </div>
          </If>
          <If condition={showError}>
            <div className='content'>
              <Grid container direction="column" justify="center" alignItems="stretch" className='text-center'>
                <Grid item className='title-container'>
                  <span className='sub-title'>
                    { errorMessage }
                  </span>
                </Grid>
              </Grid>
            </div>
          </If>
          <If condition={showSuccess}>
            <div className='content'>
              <Grid container direction="column" justify="center" alignItems="stretch" className='text-center'>
                <Grid item className='title-container'>
                  <span className='title'>Booking updated</span>
                  <span className='sub-title'>
                    The booking has been updated with your response and the requestor has been been noted.
                  </span>
                </Grid>
              </Grid>
            </div>
          </If>
          <If condition={!showSuccess && !showError && !isEmpty(bookingRequest)}>
            <div className='content'>
              <Grid container direction="column" justify="center" alignItems="stretch">
                <Grid item className='title-container'>
                  <span className='title'>Review Reservation Request</span>
                  <span className='sub-title'>
                    Approve or reject the reservation request by clicking on the appropriate button.
                  </span>
                </Grid>
                <Grid item className='booking-data'>
                  <Card className='card approval-card'>
                    <CardContent>
                      {this.renderRequestor()}
                      {this.renderRoomList()}
                      {this.renderStartDateTime()}
                      {this.renderEndDateTime()}
                      {this.renderTimezone()}
                      {this.renderRepeatSection()}
                      {this.renderSubject()}
                      {this.renderNotes()}
                      {this.renderStandbyCheckbox()}
                      {this.renderApproverCommentInput()}
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </div>
            <div className='footer'>
              <Grid container direction="row" justify="flex-end" alignItems="stretch">
                {this.renderActionButtons()}
              </Grid>
            </div>
          </If>
        </Container>
        <div className='grow'></div>
        <BrandingContainer />
      </div>
    );
  }
}