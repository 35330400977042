export const AUTH_DETAILS_REQUEST = 'AUTH_DETAILS_REQUEST';
export const AUTH_DETAILS_LOADING = 'AUTH_DETAILS_LOADING';
export const AUTH_DETAILS_SUCCESS = 'AUTH_DETAILS_SUCCESS';
export const AUTH_DETAILS_ERROR = 'AUTH_DETAILS_ERROR';
export const AUTH_DETAILS_CLEAR_ERROR = 'AUTH_DETAILS_CLEAR_ERROR';


export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_LOADING = 'LOGIN_LOADING';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const REFRESH_TOKEN_REQUEST = 'REFRESH_TOKEN_REQUEST';

export const SET_DECODED_TOKEN = 'SET_DECODED_TOKEN';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT = 'LOGOUT';