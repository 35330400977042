export const CREATE_ADHOC_SCHEDULE_REQUEST = 'CREATE_ADHOC_SCHEDULE_REQUEST';
export const CREATE_ADHOC_SCHEDULE_LOADING = 'CREATE_ADHOC_SCHEDULE_LOADING';
export const CREATE_ADHOC_SCHEDULE_SUCCESS = 'CREATE_ADHOC_SCHEDULE_SUCCESS';
export const CREATE_ADHOC_SCHEDULE_ERROR = 'CREATE_ADHOC_SCHEDULE_ERROR';

export const CREATE_VACATE_SCHEDULE_REQUEST = 'CREATE_VACATE_SCHEDULE_REQUEST';
export const CREATE_VACATE_SCHEDULE_LOADING = 'CREATE_VACATE_SCHEDULE_LOADING';
export const CREATE_VACATE_SCHEDULE_SUCCESS = 'CREATE_VACATE_SCHEDULE_SUCCESS';
export const CREATE_VACATE_SCHEDULE_ERROR = 'CREATE_VACATE_SCHEDULE_ERROR';

export const GET_SCHEDULES_REQUEST = 'GET_SCHEDULES_REQUEST';
export const GET_SCHEDULES_LOADING = 'GET_SCHEDULES_LOADING';
export const GET_SCHEDULES_SUCCESS = 'GET_SCHEDULES_SUCCESS';
export const GET_SCHEDULES_ERROR = 'GET_SCHEDULES_ERROR';