import authSagas from './auth/auth.saga';
import resourceSagas from './resources/resources.saga';
import scheduleSagas from './schedules/schedules.saga';
import importUtilitySagas from './importUtility/importUtility.saga';
import microFrontendSagas from './microFrontends/microFrontend.saga';
import approvalSagas from './approvals/approvals.saga';
import configSagas from './config/config.saga';

export default [
  ...authSagas,
  ...resourceSagas,
  ...scheduleSagas,
  ...importUtilitySagas,
  ...microFrontendSagas,
  ...approvalSagas,
  ...configSagas
];