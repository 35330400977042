import {updateObject} from '../utils/updateObject';
import { PROPS } from './auth.const';

export const setLoadingState = (state, flag) => {
  const obj = {
    [PROPS.LOADING] : flag
  };
  return updateObject(state, obj);
};

export const setError = (state, error) => {
  return updateObject(state, {
    [PROPS.ERROR]: error,
    [PROPS.LOADING] : false
  });
};

export const setLoginSuccess = (state, data) => {
  return updateObject(state, {
    [PROPS.TOKENS]: data,
    [PROPS.LOADING] : false
  });
};

export const setDecodedToken = (state, decodedToken) => {
  const tokens = {...state[PROPS.TOKENS]};
  tokens.decodedToken = decodedToken;
  return updateObject(state, {
    [PROPS.TOKENS]: tokens
  });
};

export const setDefaultState = (state) => {
  return updateObject(state, {});
};
