import { call, put, takeEvery } from 'redux-saga/effects';
import { BOOKING_DATA_REQUEST } from '../../approvals/approvals.actionTypes';
import { getBookingRequestApi } from '../../approvals/approvals.apis';
import { bookingDataLoading, bookingDataSuccess, bookingDataError } from '../../approvals/approvals.actions';

export function * fetchBookingRequestForApproval(action) {
  try {
    yield put(bookingDataLoading());
    const { data } = yield call(getBookingRequestApi, action.data);
    yield put(bookingDataSuccess(data));
  } catch (e) {
    const message = e.response && e.response.data ? e.response.data.message : e.message;
    yield put(bookingDataError(message));
  }
}

function * fetchBookingRequestForApprovalSaga() {
  yield takeEvery(BOOKING_DATA_REQUEST, fetchBookingRequestForApproval);
}


export default fetchBookingRequestForApprovalSaga;