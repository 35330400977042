import {
  BOOKING_APPROVAL_REQUEST,
  BOOKING_APPROVAL_LOADING,
  BOOKING_APPROVAL_SUCCESS,
  BOOKING_APPROVAL_ERROR,
  BOOKING_DATA_REQUEST,
  BOOKING_DATA_LOADING,
  BOOKING_DATA_SUCCESS,
  BOOKING_DATA_ERROR
} from './approvals.actionTypes';

export const requestBookingApproval = (approvalObj, token) => {
  return {
    type: BOOKING_APPROVAL_REQUEST,
    data: approvalObj,
    token: token
  };
};

export const bookingApprovalRequestLoading = () => {
  return {
    type: BOOKING_APPROVAL_LOADING
  };
};

export const bookingApprovalRequestSuccess = (data) => {
  return {
    type: BOOKING_APPROVAL_SUCCESS,
    data: data
  };
};

export const bookingApprovalRequestError = (error) => {
  return {
    type: BOOKING_APPROVAL_ERROR,
    error: error
  };
};

export const requestBookingData = (token) => {
  return {
    type: BOOKING_DATA_REQUEST,
    data: token
  };
};

export const bookingDataLoading = () => {
  return {
    type: BOOKING_DATA_LOADING
  };
};

export const bookingDataSuccess = (data) => {
  return {
    type: BOOKING_DATA_SUCCESS,
    data: data
  };
};

export const bookingDataError = (error) => {
  return {
    type: BOOKING_DATA_ERROR,
    error: error
  };
};
