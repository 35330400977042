import {
  CREATE_ADHOC_SCHEDULE_REQUEST,
  CREATE_ADHOC_SCHEDULE_LOADING,
  CREATE_ADHOC_SCHEDULE_SUCCESS,
  CREATE_ADHOC_SCHEDULE_ERROR,
  CREATE_VACATE_SCHEDULE_REQUEST,
  CREATE_VACATE_SCHEDULE_LOADING,
  CREATE_VACATE_SCHEDULE_SUCCESS,
  CREATE_VACATE_SCHEDULE_ERROR,
  GET_SCHEDULES_REQUEST,
  GET_SCHEDULES_LOADING,
  GET_SCHEDULES_SUCCESS,
  GET_SCHEDULES_ERROR
} from './schedules.actionTypes';

export const requestCreateAdhocSchedule = (scheduleObj) => {
  return {
    type: CREATE_ADHOC_SCHEDULE_REQUEST,
    scheduleObj
  };
};

export const createAdhocScheduleLoading = () => {
  return {
    type: CREATE_ADHOC_SCHEDULE_LOADING
  };
};

export const createAdhocScheduleSuccess = (data) => {
  return {
    type: CREATE_ADHOC_SCHEDULE_SUCCESS,
    data: data
  };
};

export const createAdhocScheduleError = (error) => {
  return {
    type: CREATE_ADHOC_SCHEDULE_ERROR,
    error: error
  };
};

export const requestCreateVacateSchedule = (scheduleObj) => {
  return {
    type: CREATE_VACATE_SCHEDULE_REQUEST,
    scheduleObj
  };
};

export const createVacateScheduleLoading = () => {
  return {
    type: CREATE_VACATE_SCHEDULE_LOADING
  };
};

export const createVacateScheduleSuccess = (data) => {
  return {
    type: CREATE_VACATE_SCHEDULE_SUCCESS,
    data: data
  };
};

export const createVacateScheduleError = (error) => {
  return {
    type: CREATE_VACATE_SCHEDULE_ERROR,
    error: error
  };
};

export const requestSchedules = () => {
  return {
    type: GET_SCHEDULES_REQUEST
  };
};

export const getSchedulesLoading = () => {
  return {
    type: GET_SCHEDULES_LOADING
  };
};

export const getSchedulesSuccess = (data) => {
  return {
    type: GET_SCHEDULES_SUCCESS,
    data: data
  };
};

export const getSchedulesError = (error) => {
  return {
    type: GET_SCHEDULES_ERROR,
    error: error
  };
};
