import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { requestMicroFrontend } from '../../store/microFrontends/microFrontends.actions';
import { getMicroFrontendLoaderSelector, getMicroFrontendManifestSelector } from '../../store/microFrontends/microFrontend.selector';
import MicroFrontendComponent from './microFrontend.component';

const mapStateToProps = (state) => {
  return {
    manifests: getMicroFrontendManifestSelector(state),
    loading: getMicroFrontendLoaderSelector(state)
  };
};

const mapDispatchToProps = {
  requestMicroFrontend
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MicroFrontendComponent));
