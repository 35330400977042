import { call, put, takeEvery } from 'redux-saga/effects';
import { BOOKING_APPROVAL_REQUEST } from '../../approvals/approvals.actionTypes';
import { sendApprovalRequestApi } from '../../approvals/approvals.apis';
import { bookingApprovalRequestLoading, bookingApprovalRequestSuccess, bookingApprovalRequestError } from '../../approvals/approvals.actions';
import notification from '../../../components/notification/notification';

export function * bookingRequestApproval(action) {
  try {
    yield put(bookingApprovalRequestLoading());
    const { data } = yield call(sendApprovalRequestApi, action.data, action.token);
    yield put(bookingApprovalRequestSuccess(data));
  } catch (e) {
    // TODO:- HTML code should not be mixed with redux code. Need to find a better place for this.
    const message = e.response && e.response.data ? e.response.data.message : e.message;
    yield call(notification.error, message);
    yield put(bookingApprovalRequestError(message));
  }
}

function * bookingRequestApprovalSaga() {
  yield takeEvery(BOOKING_APPROVAL_REQUEST, bookingRequestApproval);
}


export default bookingRequestApprovalSaga;