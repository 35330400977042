const UNIQUE_NAME_SEPARATOR = '_';
const BREADCRUMB_CONNECTOR = ' > ';

const getPlaceHierarchy = (displayFQN) => {
  if (!displayFQN) {
    return '';
  } else {
    const breadcrumbs = displayFQN.split(UNIQUE_NAME_SEPARATOR);
    // Remove the last element because that is the actual searched result
    breadcrumbs.pop();
    let subText = breadcrumbs.join(BREADCRUMB_CONNECTOR);
    // Do not add an extra chevron right icon for the top level
    if (breadcrumbs.length > 0) {
      subText = subText.concat(BREADCRUMB_CONNECTOR);
    }
    return subText;
  }
};

export default getPlaceHierarchy;