import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LoginComponent from './login.component';
import { requestLogin, requestLogout } from '../../store/auth/auth.actions';
import { getAuthErrorSelector, getAuthTokenSelector, getDecodedTokenSelector } from '../../store/auth/auth.selector';
import { getCurrentTheme } from '../../store/config/config.selector';

const mapStateToProps = (state) => {
  return {
    profileInfo: getDecodedTokenSelector(state),
    token: getAuthTokenSelector(state),
    authError: getAuthErrorSelector(state),
    currentTheme: getCurrentTheme(state)
  };
};

const mapDispatchToProps = {
  requestLogin,
  requestLogout
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LoginComponent));
