import {applyMiddleware, compose, createStore} from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './rootReducer';
import sagas from './sagas';

let store;

export const getStore = function() {
  if (!store) {
    // dev tools middleware
    const composeSetup = process.env.NODE_ENV !== 'production' && typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;

    // saga middleware
    const sagaMiddleware = createSagaMiddleware();

    store = createStore(
      rootReducer,
      composeSetup(applyMiddleware(sagaMiddleware))
    );
    //start all sagas
    sagas.forEach(sagaMiddleware.run);

  }
  return store;
};