import { createSelector } from 'reselect';
import { PROPS } from './approvals.const';

export const getApprovalSelector = state => state[PROPS.ROOT] || {};

export const getApprovalLoaderSelector = createSelector(
  [getApprovalSelector], (approval) => approval[PROPS.LOADING]
);

export const getBookingRequestErrorSelector = createSelector(
  [getApprovalSelector], (approval) => approval[PROPS.ERROR]
);

export const getBookingRequestSelector = createSelector(
  [getApprovalSelector], (approval) => approval[PROPS.BOOKING_REQUEST] || {}
);

export const getApprovalablePlaceListSelector = createSelector(
  [getApprovalSelector], (approval) => approval[PROPS.PLACE_LIST] || []
);

export const getApprovalResponseSelector = createSelector(
  [getApprovalSelector], (approval) => approval[PROPS.APPROVAL_RESPONSE]
);
