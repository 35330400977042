import React from 'react';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import BaseComponent from '../approval.base';
import HeaderContainer from '../../header/header.container';
import BrandingContainer from '../../branding/branding.container';
import If from '../../ifComponent/ifComponent';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import AccountCircle from '@material-ui/icons/AccountCircle';
import RoomOutlined from '@material-ui/icons/RoomOutlined';
import SubjectOutlined from '@material-ui/icons/SubjectOutlined';
import Create from '@material-ui/icons/Create';
import CommentIcon from '@material-ui/icons/Comment';
import TodayIcon from '@material-ui/icons/Today';
import Loop from '@material-ui/icons/Loop';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import TimelapseOutlined from '@material-ui/icons/TimelapseOutlined';
import AssignmentTurnedIn from '@material-ui/icons/AssignmentTurnedIn';
import { DISPLAY_DATE_TIME_FORMAT, IconSizes } from '../../../app.const';

export default class ApprovalMobileComponent extends BaseComponent {

  renderRequestor = () => {
    const { bookingRequest } = this.props;
    return (
      <ListItem>
        <ListItemAvatar>
          <AccountCircle color='primary' fontSize={IconSizes.Large} />
        </ListItemAvatar>
        <ListItemText primary='Requestor' secondary={bookingRequest.requesterId}/>
      </ListItem>
    );
  }

  renderRoomList = () => {
    const rooms = this.getRoomsList();
    return (
      <ListItem>
        <ListItemAvatar style={{alignSelf: 'flex-start'}}>
          <RoomOutlined color='primary' fontSize={IconSizes.Large} />
        </ListItemAvatar>
        <List className='room-list'>
          <ListItem className='p-0'>
            <ListItemText className='' primary='Room(s) requested' disableTypography/>
          </ListItem>
          {rooms}
        </List>
      </ListItem>
    );
  }

  renderStartDateTime() {
    const { bookingRequest } = this.props;
    return (
      <ListItem>
        <ListItemAvatar>
          <TodayIcon  color='primary' fontSize={IconSizes.Large} />
        </ListItemAvatar>
        <ListItemText primary='Starts'
          secondary={moment(bookingRequest.start).format(DISPLAY_DATE_TIME_FORMAT)}/>
      </ListItem>
    );
  }

  renderTimezone() {
    const { bookingRequest } = this.props;
    return (
      <ListItem>
        <ListItemAvatar>
          <TimelapseOutlined  color='primary'/>
        </ListItemAvatar>
        <ListItemText className='info-item' primary='Timezone'
          secondary={bookingRequest.timeZone}/>
      </ListItem>
    );
  }

  renderEndDateTime() {
    const { bookingRequest } = this.props;
    return (
      <ListItem>
        <ListItemAvatar>
          <TodayIcon  color='primary' fontSize={IconSizes.Large} />
        </ListItemAvatar>
        <ListItemText primary='Ends'
          secondary={moment(bookingRequest.end).format(DISPLAY_DATE_TIME_FORMAT)}/>
      </ListItem>
    );
  }

  renderRepeatSection() {
    const repeatText = this.getRecurrenceText();
    return (
      <ListItem>
        <ListItemAvatar>
          <Loop color='primary' fontSize={IconSizes.Large} />
        </ListItemAvatar>
        <ListItemText primary='Repeat' secondary={repeatText}/>
      </ListItem>
    );
  }

  renderSubject = () => {
    const { bookingRequest } = this.props;
    return (
      <ListItem>
        <ListItemAvatar>
          <Create color='primary' fontSize={IconSizes.Large} />
        </ListItemAvatar>
        <ListItemText primary='Subject' secondary={bookingRequest.subject}/>
      </ListItem>
    );
  }

  renderNotes = () => {
    const { bookingRequest } = this.props;
    return (
      <ListItem>
        <ListItemAvatar>
          <SubjectOutlined color='primary' fontSize={IconSizes.Large} />
        </ListItemAvatar>
        <ListItemText primary='Note by requestor'
          secondary={bookingRequest.note || '-'}/>
      </ListItem>
    );
  }

  renderStandbyCheckbox() {
    const { standby } = this.state;
    return (
      <ListItem>
        <ListItemAvatar>
          <AssignmentTurnedIn color='primary' fontSize={IconSizes.Large} />
        </ListItemAvatar>
        <Typography>
          <Checkbox
            style={{ marginRight: 8 }}
            name='standby'
            checked={standby}
            color='primary'
            onChange={this.checkboxClickHandler}
          />
          Opt-in for Stand-by mode
        </Typography>
      </ListItem>
    );
  }

  renderApproverCommentInput = () => {
    const { approverNotes } = this.state;
    return (
      <ListItem>
        <ListItemAvatar>
          <CommentIcon  color='primary' fontSize={IconSizes.Large} />
        </ListItemAvatar>
        <TextField
          label="Comments"
          name='approverNotes'
          multiline
          rows={4}
          variant="filled"
          value={approverNotes}
          onChange={this.fieldUpdateHandler}
        />
      </ListItem>
    );
  }

  renderActionButtons = () => {
    return (
      <ListItem className='submit-button-container'>
        <Button variant='outlined' className='submit-button'
          endIcon={<ClearIcon />} onClick={this.requestSubmitHandler.bind(this, false)}>
          REJECT
        </Button>
        <Button variant='contained' color='primary' className='submit-button'
          endIcon={<CheckIcon />} onClick={this.requestSubmitHandler.bind(this, true)}>
          APPROVE
        </Button>
      </ListItem>
    );
  }

  render() {
    const { approvalRequestLoading, errorMessage, bookingRequest } = this.props;
    const { showError, showSuccess } = this.state;
    let loaderContent;

    if (approvalRequestLoading) {
      loaderContent = this.getLoaderView();
    }

    return (
      <Container disableGutters className='container approval-container'>
        <HeaderContainer>
          Review reservation request
        </HeaderContainer>
        <If condition={showError}>
          <div className='content'>
            {loaderContent}
            <Grid container direction='column' justify='flex-start' alignItems='stretch' className='approval-container'>
              <Grid item className='booking-data-message'>
                <List>
                  <ListItemText secondary={errorMessage}/>
                </List>
              </Grid>
            </Grid>
          </div>
        </If>
        <If condition={showSuccess}>
          <div className='content'>
            {loaderContent}
            <Grid container direction='column' justify='flex-start' alignItems='stretch' className='approval-container'>
              <Grid item className='text-center'>
                <Typography component='div' className='sub-header'>
                  <Typography component='div' className='sub-header-text'>
                    Success
                  </Typography>
                </Typography>
              </Grid>
              <Grid item className='booking-data-message'>
                <List>
                  <ListItemText
                    secondary='The booking has been updated with your response and the requestor has been been noted.'/>
                </List>
              </Grid>
            </Grid>
          </div>
        </If>
        <If condition={!showError && !showSuccess && !isEmpty(bookingRequest)}>
          <div className='content'>
            {loaderContent}
            <Grid container direction='column' justify='flex-start' alignItems='stretch' className='approval-container'>
              <Grid item className='booking-data'>
                <List>
                  {this.renderRequestor()}
                  {this.renderRoomList()}
                  {this.renderStartDateTime()}
                  {this.renderEndDateTime()}
                  {this.renderTimezone()}
                  {this.renderRepeatSection()}
                  {this.renderSubject()}
                  {this.renderNotes()}
                  {this.renderStandbyCheckbox()}
                  {this.renderApproverCommentInput()}
                </List>
              </Grid>
            </Grid>
          </div>
          <div className='footer'>
            {this.renderActionButtons()}
          </div>
        </If>
        <BrandingContainer />
      </Container>
    );
  }
}