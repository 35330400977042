import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import NotificationComponent from './notificationComponent';
import { MESSAGE_TYPES } from '../notification/notification.const';

class Notification extends Component {

  error = (message) => {
    if (message) {
      this._unmount();
      this._render(message, MESSAGE_TYPES.ERROR);
    }
  }

  success = (message) => {
    if (message) {
      this._unmount();
      this._render(message, MESSAGE_TYPES.SUCCESS);
    }
    return;
  }

  _handleClose = () => {
    this._unmount();
  }

  _render (message, type) {
    this.notification = document.createElement('div');
    this.notification.classList.add('notification-container');
    document.body.appendChild(this.notification );
    ReactDOM.render(
      <NotificationComponent message={message} type={type} onClose={this._handleClose} />,
      this.notification
    );
  }

  _unmount() {
    if (!this.notification) {
      return;
    }
    ReactDOM.unmountComponentAtNode(this.notification);
    this.notification.remove();
    delete this.notification;
  }
}

export default new Notification();