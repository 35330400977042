import _ from 'lodash';
import React, { Component } from 'react';
import searchTree from '../../../utils/searchTree/searchTree';
import getListofParamsFromResourceHierarchy from '../../../utils/getListofParamsFromResourceHierarchy/getListofParamsFromResourceHierarchy';
import getPlaceHierarchy from '../../../utils/getPlaceHierarchy/getPlaceHierarchy';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import SearchOutlined from '@material-ui/icons/SearchOutlined';
import ClearOutlined from '@material-ui/icons/ClearOutlined';

const MINIMUM_SEARCH_STRING_LENGTH = 3;

export default class RoomSearchComponent extends Component {
  state = {
    searchString: ''
  }

  componentDidMount() {
    this.setState({
      searchString: ''
    }, () => {
      document.addEventListener('keydown', this.escapeKeyHandler);
    });
  }

  escapeKeyHandler = ( event ) => {
    if (event.key === 'Escape') {
      this.cancelSearchHandler();
    }
  }

  clearSearchResults = () => {
    this.setState({
      searchString: ''
    });
  }

  requestSearchResults = (event) => {
    const { searchResults, requestSearchedResources } = this.props;
    const value = event.target.value;
    this.setState({
      searchString: value
    });
    if ( value.length >= MINIMUM_SEARCH_STRING_LENGTH && !searchResults[value]) {
      requestSearchedResources(value);
    }
  }

  getRecentSearchView = () => {
    const { recentSearches } = this.props;
    if (recentSearches.length === 0) {
      return null;
    }
    const list = recentSearches.map(resource => {
      const subText = getPlaceHierarchy(resource.displayFQN);
      const checked = this.checkIfSelected(resource);
      return (
        <ListItem className='rows-container' key={resource.id}>
          <Paper component="div" className='row'>
            <Typography className='' component='div' onClick={this.checkboxClickHandler.bind(this, resource, checked)}>
              <Checkbox checked={checked}/>
            </Typography>
            <Typography component='div'>
              <div className='search-subText'>{subText}</div>
              <div className='search-text'>{resource.displayName}</div>
            </Typography>
          </Paper>
        </ListItem>
      );
    });
    return (
      <>
        <ListItem className='rows-container'>
          <Paper component="div" className='row'>
            <Typography component='div'>
              <div className='empty-search-results'>Recent searches </div>
            </Typography>
          </Paper>
        </ListItem>
        { list }
      </>
    );
  }

  getSearchResults = () => {
    const { searchString } = this.state;
    const { searchResults } = this.props;
    const searchedResources = searchResults[searchString];
    if (!searchedResources) {
      return null;
    }
    if (searchedResources.length === 0) {
      return (
        <ListItem className='rows-container'>
          <Paper component="div" className='row'>
            <Typography component='div'>
              <div className='empty-search-results'>No search results</div>
            </Typography>
          </Paper>
        </ListItem>
      );
    }

    const list = searchedResources.map(resource => {
      const subText = getPlaceHierarchy(resource.displayFQN);
      const checked = this.checkIfSelected(resource);
      return (
        <ListItem className='rows-container' key={resource.id}>
          <Paper component="div" className='row'>
            <Typography className='' component='div' onClick={this.checkboxClickHandler.bind(this, resource, checked)}>
              <Checkbox checked={checked}/>
            </Typography>
            <Typography component='div'>
              <div className='search-subText'>{subText}</div>
              <div className='search-text'>{resource.displayName}</div>
            </Typography>
          </Paper>
        </ListItem>
      );
    });
    return list;
  }

  cancelSearchHandler = () => {
    const { toggleSearch } = this.props;
    this.clearSearchResults();
    toggleSearch();
  }

  checkIfSelected = (resource) => {
    const { selectedResources } = this.props;
    const selectedId = selectedResources.find(resourceId => resourceId.toString() === resource.id.toString());
    return !!selectedId;
  }

  checkboxClickHandler = (resource, previouslyChecked, event) => {
    event.stopPropagation();
    event.preventDefault();

    const { resourceList, selectedResources, setSelectedResources, requestChildResources } = this.props;
    let updatedResources;
    let resourceIds = [resource.id.toString()];
    // get the original resource with its members
    // The search result does not give the child resources
    let completeResource = searchTree(resourceList, resource.id);
    if (completeResource) {
      if (resource.hasMembers) {
        resourceIds = getListofParamsFromResourceHierarchy(completeResource);
      }
    } else {
      // get the school from displayFQN and fetch the hierarchy
      const UNIQUE_NAME_SEPARATOR = '_';
      const PROPERTY = 'displayFQN';
      const fqnArray = resource[PROPERTY].split(UNIQUE_NAME_SEPARATOR);
      let partialFQNString;
      for ( let i=0; i< fqnArray.length; i++) {
        partialFQNString = partialFQNString ? partialFQNString+UNIQUE_NAME_SEPARATOR+fqnArray[i]: fqnArray[i];
        const place = searchTree(resourceList, partialFQNString, PROPERTY);
        if (place && place.hasMembers && place.members.length === 0) {
          // fetch the school hierarchy to which the selected resource belongs
          requestChildResources(place.id, { includeNestedPlaces: true });
          break;
        }
      }
    }
    if (previouslyChecked) {
      // Remove the selected and all the child resources from the resource list
      updatedResources = selectedResources.filter(item => !resourceIds.includes(item.toString()));
    } else {
      // Add the selected and all the child resources to the resource list
      updatedResources = _.union(selectedResources, resourceIds);
    }
    setSelectedResources(updatedResources);
  }

  formSubmitHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();
  }

  render() {
    const { searchString } = this.state;
    const view = searchString ? this.getSearchResults() : this.getRecentSearchView();
    return (
      <List className='fh-search'>
        <ListItem className='rows-container'>
          <Paper component="form" className='row form-row' onSubmit={this.formSubmitHandler}>
            <IconButton aria-label="clear">
              <SearchOutlined color='primary'/>
            </IconButton>
            <InputBase autoFocus placeholder="Search rooms by keyword..." className='search-input' value={searchString}
              onChange={this.requestSearchResults}/>
            <IconButton aria-label='clear' onClick={this.cancelSearchHandler} >
              <ClearOutlined color='primary'/>
            </IconButton>
          </Paper>
        </ListItem>
        <div className='search-results'>
          { view }
        </div>
      </List>
    );
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escapeKeyHandler);
  }
}