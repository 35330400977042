import LocationCityIcon from '@material-ui/icons/LocationCity';
import BusinessIcon from '@material-ui/icons/Business';
import ApartmentOutlined from '@material-ui/icons/ApartmentOutlined';
import MeetingRoom from '@material-ui/icons/MeetingRoom';

export const BASE_API_PATH = '/v1';

export const DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

export const DATE_FORMAT = 'YYYY-MM-DD';

export const TIME_FORMAT = 'hh:mm A';

export const DISPLAY_DATE_TIME_FORMAT = 'YYYY-MM-DD hh:mm A';

export const SUBJECT_MAXLENGTH = 255;

export const SUCCESS_MODAL_PROPS = {
  title: 'Request sent',
  content: 'We will email you the results of your request',
  buttonText: 'Return',
  disableBackdropClick: true,
  disableEscapeKeyDown: true
};

export const CONFIRM_MODAL_PROPS = {
  title: 'Confirmation',
  content: 'Are you sure you want to make this request?',
  disableBackdropClick: true,
  disableEscapeKeyDown: true
};

export const BOOKING_STATUSES = {
  OCCUPIED: 'occupied',
  HIGH_OCCUPANCY: 'highoccupancy',
  UNOCCUPIED: 'unoccupied'
};

export const IconSizes = {
  Large: 'large'
};

export const PLACE_TYPES = {
  district: {
    id: 'district',
    icon: LocationCityIcon
  },
  school: {
    id: 'school',
    icon: BusinessIcon
  },
  building: {
    id: 'building',
    icon: ApartmentOutlined
  },
  room: {
    id: 'room',
    icon: MeetingRoom
  },
  other: {
    id: 'other',
    icon: BusinessIcon
  }
};