export const REQUEST_TYPES = {
  ALL: {
    label: 'All',
    value: 'All'
  },
  BELL_SCHEDULE: {
    label: 'Bell schedule',
    value: 'BellSchedule'
  },
  USERS: {
    label: 'Users',
    value: 'Users'
  },
  PLACE_HIERARCHY: {
    label: 'Room hierarchy',
    value: 'PlaceHierarchy'
  },
  ZONE_APPROVERS: {
    label: 'Room approvers',
    value: 'ZoneApprovers'
  },
  VENSTAR_CONFIG: {
    label: 'Venstar Places',
    value: 'VenstarPlaces'
  }

};

export const MAX_FILE_SIZE = 20000000; //file size 20mb (in bytes)
