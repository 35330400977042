import { call, put, takeEvery } from 'redux-saga/effects';
import { DISTRICT_FETCH } from '../resources.actionTypes';
import { getResourceByTypeApi } from '../resources.apis';
import { resourcesLoading, fetchDistrictSuccess, fetchResourcesError } from '../resources.actions';
import notification from '../../../components/notification/notification';
import { PLACE_TYPES } from '../../../app.const';

export function * fetchDistrict() {
  try {
    yield put(resourcesLoading());
    const { data } = yield call(getResourceByTypeApi, PLACE_TYPES.district.id);
    yield put(fetchDistrictSuccess(data));
  } catch (e) {
    // TODO:- HTML code should not be mixed with redux code. Need to find a better place for this.
    const message = e.response && e.response.data ? e.response.data.message : e.message;
    yield call(notification.error, message);
    yield put(fetchResourcesError(message));
  }
}

function * fetchDistrictSaga() {
  yield takeEvery(DISTRICT_FETCH, fetchDistrict);
}


export default fetchDistrictSaga;