import React from 'react';
import BaseFooterComponent from '../branding.base';
import PoweredByFoghornLogo from '../../../../images/powered-by-foghorn.svg';
import getClientConfig from '../../../utils/getClientConfig/getClientConfig';

export default class MobileFooter extends BaseFooterComponent {
  render() {
    const clientConfig = getClientConfig() || {};
    const brandingImages = clientConfig.brandingImages || [];
    let images;
    if (brandingImages.length > 0 ) {
      images = brandingImages.map((ImageComponent, i) => <ImageComponent key={i} className='logo'/> );
    }

    return (
      <div className='branding'>
        {
          images ? <div className='brand-logo-container'>{images}</div>: null
        }
        <div className='foghorn-logo-container'>
          <PoweredByFoghornLogo className='logo'/>
        </div>
      </div>
    );
  }
}
