import { createSelector } from 'reselect';
import { PROPS } from './config.const';

export const getConfigSelector = state => state[PROPS.ROOT] || {};

export const getIsMobileSelector = createSelector(
  [getConfigSelector], (config) =>  config[PROPS.IS_MOBILE]
);

export const getLoadingSelector = createSelector(
  [getConfigSelector], (config) =>  config[PROPS.LOADING]
);

export const getDistrictSelector = createSelector(
  [getConfigSelector], (config) =>  config[PROPS.DISTRICT_FQN]
);

export const getTimezoneSelector = createSelector(
  [getConfigSelector], (config) =>  config[PROPS.DISTRICT_TIMEZONE]
);

export const getCurrentTheme = createSelector(
  [getConfigSelector], (config) =>  config[PROPS.THEME]
);
