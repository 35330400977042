import { put, takeEvery } from 'redux-saga/effects';
import { LOGOUT_REQUEST } from '../auth.actionTypes';
import { logout as logoutAction } from '../auth.actions';
import { clearStorage } from '../../session/session.actions';

export function * logout() {
  try {
    yield put(logoutAction());
    yield put(clearStorage());
  } catch (e) {
    // do nothing
  }
}

function * logoutSaga() {
  yield takeEvery(LOGOUT_REQUEST, logout);
}


export default logoutSaga;