import { call, put, takeEvery } from 'redux-saga/effects';
import { GET_SCHEDULES_REQUEST } from '../schedules.actionTypes';
import { getSchedulesApi } from '../schedules.apis';
import { getSchedulesLoading, getSchedulesSuccess, getSchedulesError } from '../schedules.actions';
import notification from '../../../components/notification/notification';

export function * fetchSchedules() {
  try {
    yield put(getSchedulesLoading());
    const { data } = yield call(getSchedulesApi);
    yield put(getSchedulesSuccess(data));
  } catch (e) {
    // TODO:- HTML code should not be mixed with redux code. Need to find a better place for this.
    const message = e.response && e.response.data ? e.response.data.message : e.message;
    yield call(notification.error, message);
    yield put(getSchedulesError(message));
  }
}

function * fetchSchedulesSaga() {
  yield takeEvery(GET_SCHEDULES_REQUEST, fetchSchedules);
}

export default fetchSchedulesSaga;