import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import AccountCircle from '@material-ui/icons/AccountCircle';
import './drawer.scss';

const DrawerComponent = (props) => {
  const { drawerOpen, toggleDrawer, profileInfo, getMenuItemsForUser } = props;
  let userName;
  let menuListHtml;
  if (profileInfo) {
    const firstName = profileInfo.firstname || '';
    const lastName = profileInfo.lastname || '';
    userName = `${firstName} ${lastName}`;
    const menuItems = getMenuItemsForUser();
    menuListHtml = menuItems.map((item, i) => {
      return (
        <ListItem key={i} button className='drawer-menu-item' onClick={item.clickHandler}>
          <span className='menu-item'>{item.text}</span>
        </ListItem>
      );
    });
  }

  return (
    <Drawer anchor='right' open={drawerOpen} onClose={toggleDrawer.bind(this, false)}>
      <div className='drawer'>
        <Typography component="div" className='user-details'>
          <Grid container spacing={2} justify="center">
            <Grid item xs={4}>
              <AccountCircle className='user-icon' color='primary'/>
            </Grid>
            <Grid item xs={8}>
              <div className='username'>{userName}</div>
            </Grid>
          </Grid>
        </Typography>
        <div>
          <List component="nav">
            { menuListHtml }
          </List>
        </div>
      </div>
    </Drawer>
  );
};

export default DrawerComponent;