import _ from 'lodash';
import { PROPS } from './resources.const';
import { updateObject } from '../utils/updateObject';
import searchTree from '../../utils/searchTree/searchTree';

const setTopResourceSuccess = (state, data) => {
  return updateObject(state, {
    [PROPS.HIERARCHY_LIST]: data,
    [PROPS.LOADING] : false
  });
};

const setChildResourceSuccess = (state, data, parentId) => {
  if ( data && data.length > 0) {
    const cloneList = _.cloneDeep(state[PROPS.HIERARCHY_LIST]);
    let parentNode = searchTree(cloneList, parentId);
    if (parentNode) {
      parentNode.hasMembers = true;
      parentNode.members = data;
      return updateObject(state, {
        [PROPS.HIERARCHY_LIST]: cloneList,
        [PROPS.LOADING] : false
      });
    }
  } else {
    return updateObject(state, {
      [PROPS.LOADING] : false
    });
  }
};

const setSearchedResourceSuccess = (state, data, searchString) => {
  const searchObj = state[PROPS.SEARCH_RESULTS] ? Object.assign({}, state[PROPS.SEARCH_RESULTS]) : {};
  searchObj[searchString] = data;
  return updateObject(state, {
    [PROPS.SEARCH_RESULTS]: searchObj,
    [PROPS.LOADING] : false
  });
};

const setSchoolsSuccess = (state, data) => {
  return updateObject(state, {
    [PROPS.SCHOOLS]: data,
    [PROPS.LOADING] : false
  });
};

const setDistrictSuccess = (state, data) => {
  return updateObject(state, {
    [PROPS.DISTRICT]: data,
    [PROPS.LOADING] : false
  });
};

const setResourceLoading = (state, flag) => {
  const obj = {
    [PROPS.LOADING] : flag
  };
  return updateObject(state, obj);
};

const setResourceError = (state, error) => {
  return updateObject(state, {
    [PROPS.ERROR]: error,
    [PROPS.LOADING] : false
  });
};

const setFetchLatestListFlag = (state, flag) => {
  return updateObject(state, {
    [PROPS.FETCH_LATEST_LIST]: flag
  });
};


export default {
  setTopResourceSuccess,
  setChildResourceSuccess,
  setSearchedResourceSuccess,
  setSchoolsSuccess,
  setDistrictSuccess,
  setFetchLatestListFlag,
  setResourceLoading,
  setResourceError
};