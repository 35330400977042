import React from 'react';
import BaseComponent from '../selectRooms.base';
import HeaderContainer from '../../header/header.container';
import BrandingContainer from '../../branding/branding.container';
import RoomSearchComponent from '../roomSearch/roomSearch';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import SearchOutlined from '@material-ui/icons/SearchOutlined';
import ArrowForward from '@material-ui/icons/ArrowForward';

export default class SelectRoomsDesktopComponent extends BaseComponent {

  getSearchView = () => {
    const { searchResults, resourceList, requestSearchedResources, requestChildResources, recentSearches } = this.props;
    const { resources } = this.state;
    return (
      <CardContent className='search-view'>
        <Paper component="div" className='search-form'>
          <RoomSearchComponent
            resourceList={resourceList}
            selectedResources={resources}
            searchResults={searchResults}
            recentSearches={recentSearches}
            requestSearchedResources={requestSearchedResources}
            requestChildResources={requestChildResources}
            toggleSearch={this.toggleSearch}
            setSelectedResources={this.setSelectedResources}
          />
        </Paper>
      </CardContent>
    );
  }

  getListView = () => {
    const { headerIcon, headerText } = this.getHeader();
    return (
      <>
        <div className='card-header'>
          <div className='search-panel' onClick={this.toggleSearch}>
            <span>
              { headerIcon }
              <span className='va-top'>{ headerText } </span>
            </span>
            <span className='search-icon'><SearchOutlined color='primary' /></span>
          </div>
        </div>
        <CardContent className='rooms-list'>
          <Grid item>
            {this.getTreeView()}
          </Grid>
        </CardContent>
      </>
    );
  }

  render() {
    const { resourceLoading } = this.props;
    const { showSearch, roomsCount } = this.state;
    let loaderContent;
    if (resourceLoading) {
      loaderContent = this.getLoaderView();
    }
    const view = showSearch ? this.getSearchView() : this.getListView();
    let roomsText = roomsCount > 1 ? 'rooms' : 'room';

    return (
      <div className='container'>
        <HeaderContainer />
        <div className='content'>
          <Container maxWidth="md">
            {loaderContent}
            <Grid container direction="column" justify="center" alignItems="stretch" className='select-rooms'>
              <Grid item className='title-container'>
                <span className='title'>Select room(s) to reserve</span>
                <span className='sub-title'>Use this list to find the room(s) you would like to reserve.</span>
              </Grid>
              <Grid item className='body-container'>
                <Card className='card'>
                  {view}
                </Card>
              </Grid>
            </Grid>
          </Container>
        </div>
        <div className='footer'>
          <Container maxWidth="md">
            <Grid container direction="row" justify="flex-end" alignItems="stretch">
              <Grid item>
                <Button color='primary' variant='contained' endIcon={<ArrowForward />}
                  disabled={roomsCount === 0} onClick={this.goToScheduleDetails}>
                  Reserve {roomsCount} {roomsText}
                </Button>
              </Grid>
            </Grid>
          </Container>
        </div>
        <BrandingContainer />
      </div>
    );
  }
}