import {
  REQUEST_MICRO_FRONTEND,
  MICRO_FRONTEND_LOADING,
  MICRO_FRONTEND_SUCCESS,
  MICRO_FRONTEND_ERROR
} from './microFrontends.actionTypes';

export const requestMicroFrontend = (mfHost) => {
  return {
    type: REQUEST_MICRO_FRONTEND,
    host: mfHost
  };
};

export const microFrontendLoading = () => {
  return {
    type: MICRO_FRONTEND_LOADING
  };
};

export const microFrontendSuccess = (manifest, mfHost) => {
  return {
    type: MICRO_FRONTEND_SUCCESS,
    data: {
      manifest: manifest,
      host: mfHost
    }
  };
};

export const microFrontendError = (error) => {
  return {
    type: MICRO_FRONTEND_ERROR,
    error
  };
};