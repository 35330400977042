import { call, put, takeEvery } from 'redux-saga/effects';
import { TOP_RESOURCES_FETCH } from '../resources.actionTypes';
import { getTopLevelResourcesApi } from '../resources.apis';
import { resourcesLoading, fetchTopLevelResourcesSuccess, fetchResourcesError } from '../resources.actions';
import notification from '../../../components/notification/notification';

export function * fetchTopLevelResources() {
  try {
    yield put(resourcesLoading());
    const { data } = yield call(getTopLevelResourcesApi);
    yield put(fetchTopLevelResourcesSuccess(data));
  } catch (e) {
    // TODO:- HTML code should not be mixed with redux code. Need to find a better place for this.
    const message = e.response && e.response.data ? e.response.data.message : e.message;
    yield call(notification.error, message);
    yield put(fetchResourcesError(message));
  }
}

function * fetchTopLevelResourcesSaga() {
  yield takeEvery(TOP_RESOURCES_FETCH, fetchTopLevelResources);
}


export default fetchTopLevelResourcesSaga;