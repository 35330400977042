import React, { Component } from 'react';
import moment from 'moment';
import 'moment-timezone';
import Box from '@material-ui/core/Box';
import combineDateTime from '../../utils/combineDateTime/combineDateTime';
import sortWeekDays from '../../utils/sortWeekDays/sortWeekDays';
import { DATE_FORMAT, TIME_FORMAT } from '../../app.const';
import { MAX_TIME, MIN_TIME, REPEAT_OPTIONS, DAYS_OF_WEEK, TIMEZONE_COUNTRY_CODE, DEFAULT_TIMEZONE } from './dateTime.const';

import './dateTime.scss';
export default class BookingDateTimeBaseComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      allDayFlag: false,
      disableDateTime: false,
      timezoneList: []
    };
    this.allDayFlagChangeHandler.bind(this);
    this.setDefaultDateTimeValues.bind(this);

  }

  componentDidMount() {
    this.setDefaultDateTimeValues();
  }

  setDefaultDateTimeValues = () => {
    const { setFieldValue, districtTimezone } = this.props;
    const timezoneList = moment.tz.zonesForCountry(TIMEZONE_COUNTRY_CODE);
    const currentDateTime = moment();
    const startDateString = currentDateTime.format(DATE_FORMAT);
    const startTimeString = currentDateTime.format(TIME_FORMAT);
    // adds one more hour to the updated time
    const endDateTime = currentDateTime.add(1, 'h');
    const endDateString = endDateTime.format(DATE_FORMAT);
    const endTimeString = endDateTime.format(TIME_FORMAT);
    const timezone = districtTimezone || DEFAULT_TIMEZONE;
    const repeatEndDateTime = endDateTime.add(1, 'd');
    const repeatEndDateString = repeatEndDateTime.format(DATE_FORMAT);
    this.setState({
      timezoneList: timezoneList
    }, () => {
      setFieldValue('startDate', startDateString);
      setFieldValue('startTime', startTimeString);
      setFieldValue('endDate', endDateString);
      setFieldValue('endTime', endTimeString);
      setFieldValue('timezone', timezone);
      setFieldValue('repeat', REPEAT_OPTIONS.NEVER);
      // Setting a default value for repeatEndDate so that it does not show today's date by default
      setFieldValue('repeatEndDate', repeatEndDateString);
    });
  }

  allDayFlagChangeHandler(event) {
    const { setFieldValue, startDate } = this.props;
    const el = event.target;
    if (el.checked ) {
      this.setState({
        allDayFlag: true,
        disableDateTime: true
      }, () => {
        setFieldValue('startTime', MIN_TIME);
        setFieldValue('endTime', MAX_TIME);
        // An all day event can be only for one day
        if (startDate) {
          setFieldValue('endDate', startDate);
        }
      });
    } else {
      this.setState({
        allDayFlag: false,
        disableDateTime: false
      });
    }
  }

  startDateChangeHandler = (startDateObj) => {
    const { setFieldValue } = this.props;
    const value = startDateObj && startDateObj.isValid() ? startDateObj.format(DATE_FORMAT): '';
    setFieldValue('startDate', value, this.setEndDate);
    setFieldValue('repeatEndDate', value, this.setEndDate);
  }

  endDateChangeHandler = (endDateObj) => {
    const { setFieldValue } = this.props;
    const value = endDateObj && endDateObj.isValid() ? endDateObj.format(DATE_FORMAT): '';
    setFieldValue('endDate', value);
  }

  startTimeChangeHandler = (startTimeObj) => {
    const { setFieldValue } = this.props;
    const value = startTimeObj && startTimeObj.isValid() ? startTimeObj.format(TIME_FORMAT): '';
    setFieldValue('startTime', value, this.setEndDate);
  }

  endTimeChangeHandler = (endTimeObj) => {
    const { setFieldValue } = this.props;
    const value = endTimeObj && endTimeObj.isValid() ? endTimeObj.format(TIME_FORMAT): '';
    setFieldValue('endTime', value);
  }

  repeatEndDateChangeHandler = (repeatEndDateObj) => {
    const { setFieldValue } = this.props;
    const value = repeatEndDateObj && repeatEndDateObj.isValid() ? repeatEndDateObj.format(DATE_FORMAT): '';
    setFieldValue('repeatEndDate', value);
  }

  repeatChangeHandler = (event) => {
    const { setFieldValue, startDate, fieldUpdateHandler } = this.props;
    if (event.target.value !== REPEAT_OPTIONS.NEVER) {
      setFieldValue('endDate', startDate);
    }
    fieldUpdateHandler(event);
  }

  setEndDate = () => {
    const { startDate, startTime, setFieldValue } = this.props;
    let endDate;
    if (startDate && startTime) {
      const startDateTime = combineDateTime(startDate, startTime);
      const endDateTime = startDateTime.add(1, 'h');
      endDate = endDateTime.format(DATE_FORMAT);
    } else {
      endDate = startDate;
    }
    setFieldValue('endDate', endDate);
  }

  timezoneChangeHandler = (event, newValue) => {
    const { setFieldValue } = this.props;
    setFieldValue('timezone', newValue);
  }

  getDaysOfWeekItems = () => {
    const { daysOfWeek } = this.props;
    const list = Object.keys(DAYS_OF_WEEK).map( key => {
      const classes = daysOfWeek.includes(DAYS_OF_WEEK[key]) ? 'days selected' : 'days';
      return (
        <Box key={key} className = {classes} onClick={this.toggleDay.bind(this, DAYS_OF_WEEK[key])}>
          { key }
        </Box>
      );
    });
    return list;
  }

  toggleDay = (value) => {
    const { daysOfWeek, setFieldValue } = this.props;
    let updatedValue;
    if (daysOfWeek.includes(value)) {
      updatedValue = daysOfWeek.filter(day => day!== value);
    } else {
      updatedValue = [value].concat(daysOfWeek);
    }

    updatedValue = sortWeekDays(updatedValue);
    setFieldValue('daysOfWeek', updatedValue);
  }
}