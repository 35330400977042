import moment from 'moment-timezone';
import { PROPS } from './config.const';
import { updateObject } from '../utils/updateObject';

export const setMobileFlag = (state, flag) => {
  return updateObject(state, {[PROPS.IS_MOBILE]: flag});
};

export const setLoadingFlag = (state, flag) => {
  return updateObject(state, { [PROPS.LOADING]: flag });
};

export const setConfig = (state, data) => {
  moment.tz.setDefault(data[PROPS.DISTRICT_TIMEZONE]);
  return updateObject(state, {
    [PROPS.LOADING]: false,
    [PROPS.DISTRICT_FQN]: data[PROPS.DISTRICT_FQN],
    [PROPS.DISTRICT_TIMEZONE]: data[PROPS.DISTRICT_TIMEZONE]
  });
};

export const setTheme = (state, data) => {
  return updateObject(state, {
    [PROPS.THEME]: data
  });
};