import _ from 'lodash';
import RequestorToolLocalStorage from './requestorToolLocalStorage';
import { MAX_RECENT_SEARCH_COUNT, ACTION_TYPE, FIELDS } from './localStorage.const';

const action = {
  type: ACTION_TYPE
};

export const updateRecentSearch = (resources) => {
  let savedSearches = RequestorToolLocalStorage.getProperty(FIELDS.RECENT_SEARCHES);
  savedSearches = Array.isArray(savedSearches) ? savedSearches : [];
  let updatedResults = _.unionBy(savedSearches, resources, 'id');
  if (updatedResults.length > MAX_RECENT_SEARCH_COUNT ) {
    let itemsToBeDeleted = updatedResults.length - MAX_RECENT_SEARCH_COUNT;
    updatedResults = updatedResults.slice(itemsToBeDeleted);
  }
  RequestorToolLocalStorage.setProperty(FIELDS.RECENT_SEARCHES, updatedResults);
  return action;
};

export const updateTheme = (newTheme) => {
  RequestorToolLocalStorage.setProperty(FIELDS.THEME, newTheme);
  return action;
};