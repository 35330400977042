import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import MainContainer from '../components/main/main.container';
import LoginContainer from '../components/login/login.container';
import SelectRoomsContainer from '../components/selectRooms/selectRooms.container';
import ScheduleDetailsContainer from '../components/scheduleDetails/scheduleDetails.container';
import VacateScheduleContainer from '../components/vacateRequest/vacateRequest.container';
import ImportUtilityContainer from '../components/importUtility/importUtility.container';
import ApprovalContainer from '../components/approvals/approval.container';
import ContactUsContainer from '../components/contactUs/contactUs.container';
// import BookingsContainer from '../components/bookings/bookings.container';
import isMobileDevice from '../utils/isMobileDevice/isMobileDevice';
import { LOGIN, SELECT_ROOMS, SCHEDULE_DETAILS, SCHEDULE_CHANGE, IMPORT_UTILITY, APPROVAL_ITEM,
  FORGOT_PASSWORD, RESET_PASSWORD, CHANGE_PASSWORD, CHANGE_EXPIRED_PASSWORD, CONTACT_US } from './routes.const';

export default class Routes extends Component {

  componentDidMount() {
    const {requestChangeTheme, originalTheme} = this.props;
    this.setMobileFlag();
    window.addEventListener('resize', this.setMobileFlag);
    requestChangeTheme(originalTheme);
  }

  componentDidUpdate( prevProps) {
    const { currentTheme: oldTheme} = prevProps;
    const { isMobile, currentTheme, setTheme, requestChangeTheme } = this.props;
    if (isMobile == undefined ) {
      this.setMobileFlag();
    }

    if ((currentTheme && !oldTheme) || (currentTheme && currentTheme.id !== oldTheme.id)) {
      setTheme(currentTheme);
      requestChangeTheme(currentTheme);
    }
  }

  setMobileFlag = () => {
    const mobileDeviceFlag = isMobileDevice();
    if (mobileDeviceFlag) {
      document.body.classList.add('mobile');
    } else {
      document.body.classList.remove('mobile');
    }
    this.props.setMobileFlag(mobileDeviceFlag);
  }

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path={APPROVAL_ITEM} component={ApprovalContainer} />
          <MainContainer>
            <Switch>
              {/* <Route exact path={PAST_BOOKINGS} component={BookingsContainer} /> */}
              <Route exact path={IMPORT_UTILITY} component={ImportUtilityContainer} />
              <Route exact path={SELECT_ROOMS} component={SelectRoomsContainer} />
              <Route exact path={SCHEDULE_DETAILS} component={ScheduleDetailsContainer} />
              <Route exact path={SCHEDULE_CHANGE} component={VacateScheduleContainer} />
              <Route exact path={CONTACT_US} component={ContactUsContainer} />
              <Route exact path={LOGIN} component={LoginContainer} />
              <Route exact path={FORGOT_PASSWORD} component={LoginContainer} />
              <Route exact path={RESET_PASSWORD} component={LoginContainer} />
              <Route exact path={CHANGE_PASSWORD} component={LoginContainer} />
              <Route exact path={CHANGE_EXPIRED_PASSWORD} component={LoginContainer} />
            </Switch>
          </MainContainer>
        </Switch>
      </BrowserRouter>
    );
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setMobileFlag);
  }

}
