import { call, put, takeEvery } from 'redux-saga/effects';
import { REQUEST_CONFIG } from '../config.actionTypes';
import { getConfigApi } from '../config.apis';
import { requestConfigLoading, requestConfigSuccess, requestConfigError } from '../config.actions';
import notification from '../../../components/notification/notification';

export function * fetchConfig() {
  try {
    yield put(requestConfigLoading());
    const { data } = yield call(getConfigApi);
    yield put(requestConfigSuccess(data));
  } catch (e) {
    // TODO:- HTML code should not be mixed with redux code. Need to find a better place for this.
    yield call(notification.error, e.message);
    yield put(requestConfigError(e.message));
  }
}

function * fetchConfigSaga() {
  yield takeEvery(REQUEST_CONFIG, fetchConfig);
}


export default fetchConfigSaga;