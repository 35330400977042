import { PROPS } from './schedules.const';
import { updateObject } from '../utils/updateObject';

export const setRequestLoading = (state, flag) => {
  const obj = {
    [PROPS.REQUEST_LOADING] : flag
  };
  return updateObject(state, obj);
};

export const createScheduleSuccess = (state, data) => {
  let list = state[PROPS.LIST] || [];
  list = list.concat([data]);
  let obj = {
    [PROPS.LIST]: list,
    [PROPS.REQUEST_LOADING] : false
  };
  return updateObject(state, obj);
};

export const setScheduleList = (state, data) => {
  const list = data && Array.isArray(data) ? data : [];
  let obj = {
    [PROPS.LIST]: list,
    [PROPS.REQUEST_LOADING] : false
  };
  return updateObject(state, obj);
};