const searchTree = (list, value, propertyName = 'id') => {
  if (!list || !Array.isArray(list) || list.length === 0 || !value) {
    return null;
  }
  for (let i = 0; i < list.length; i++) {
    let item = list[i];
    if (item[propertyName] && item[propertyName].toString() === value.toString()) {
      return item;
    }
    let result = searchTree(item.members, value, propertyName);
    if ( result ) {
      return result;
    }
  }
};

export default searchTree;