import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { requestAllResources, requestSearchedResources, requestChildResources } from '../../store/resources/resources.actions';
import { getResourceListSelector, getResourceLoadingSelector, getResourceErrorSelector, getResourceSearchSelector, getFetchLatestListSelector } from '../../store/resources/resources.selector';
import { getDecodedTokenSelector } from '../../store/auth/auth.selector';
import { saveResources, clearResources } from '../../store/session/session.actions';
import { getSavedResources } from '../../store/session/session.selector';
import { updateRecentSearch } from '../../store/localStorage/localStorage.actions';
import { getRecentSearches } from '../../store/localStorage/localStorage.selector';
import { getIsMobileSelector } from '../../store/config/config.selector';
import selectDeviceLayout from '../../utils/selectDeviceLayout/selectDeviceLayout';
import DesktopComponent from './desktop/selectRooms.desktop';
import MobileComponent from './mobile/selectRooms.mobile';

const mapStateToProps = (state) => {
  return {
    isMobile: getIsMobileSelector(state),
    decodedToken: getDecodedTokenSelector(state),
    resourceLoading: getResourceLoadingSelector(state),
    resourceError: getResourceErrorSelector(state),
    resourceList: getResourceListSelector(state),
    savedResources: getSavedResources(),
    searchResults:getResourceSearchSelector(state),
    fetchLatestListFlag: getFetchLatestListSelector(state),
    recentSearches: getRecentSearches()
  };
};

const mapDispatchToProps = {
  requestAllResources,
  requestChildResources,
  requestSearchedResources,
  saveResources,
  clearResources,
  updateRecentSearch
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(selectDeviceLayout(DesktopComponent, MobileComponent)));
