import axios from 'axios';
const BASE_URL = '/v1/requests';
import { addInterceptors, ejectInterceptors } from '../utils/api-interceptor';

export const getBookingRequestApi = async (token) => {
  const url = `${BASE_URL}/decision?token=${token}`;
  const headers = { 'Authorization': token };
  ejectInterceptors();
  const response = await axios.get(url, { headers: headers });
  addInterceptors();
  return response;
};

export const sendApprovalRequestApi = async (approvalObj, token) => {
  const url = `${BASE_URL}/decision`;
  const headers = { 'Authorization': token };
  ejectInterceptors();
  const response = await axios.post(url, approvalObj, { headers: headers });
  addInterceptors();
  return response;
};