import React, { Component } from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Routes from './router/routes.container';
import THEMES from './app.theme';
import { addInterceptors } from './store/utils/api-interceptor';
import { getCurrentThemeFromLocalStorage } from './store/localStorage/localStorage.selector';
import { updateTheme } from './store/localStorage/localStorage.actions';
import './index.scss';

addInterceptors();


export default class App extends Component {

  state = {
    theme: ''
  };

  componentDidMount() {
    let currentThemeFromLocalStorage = getCurrentThemeFromLocalStorage();
    const theme = currentThemeFromLocalStorage || THEMES.LIGHT;
    this.setState({
      theme: theme
    }, ()=>{
      document.body.classList.add(theme.className);
      updateTheme(theme);
    });
  }

  setTheme = (newTheme) => {
    const oldTheme = Object.assign({}, this.state.theme);
    const currentTheme = newTheme || THEMES.LIGHT;
    this.setState({
      theme: currentTheme
    }, ()=>{
      updateTheme(currentTheme);
      if (currentTheme  && oldTheme && currentTheme.id !== oldTheme.id) {
        document.body.classList.remove(oldTheme.className);
        document.body.classList.add(currentTheme.className);
      }
    });
  }

  render () {
    const { theme } = this.state;
    if (theme) {
      const themeObj = createMuiTheme(theme.theme);
      return (
        <ThemeProvider theme={themeObj}>
          <CssBaseline />
          <Routes originalTheme={theme} setTheme={this.setTheme} />
        </ThemeProvider>
      );
    }
    return null;
  }
}