import { Component } from 'react';
import { SCHEDULE_CHANGE, IMPORT_UTILITY, PAST_BOOKINGS, LOGIN, SELECT_ROOMS, CHANGE_PASSWORD, CONTACT_US } from '../../router/routes.const';
import {
  VACATE_SCHEDULE,
  CALENDAR_IMPORT_UTILITY,
  PAST_BOOKING_REQUESTS,
  CONTACT_US_PAGE,
  SIGNOUT,
  USER_MENU,
  CHANGE_PASSWORD_REQUESTS
} from './header.const';

import './header.scss';

export default class BaseHeaderComponent extends Component {

  constructor(props) {
    super(props);
    this.menuList = {
      [VACATE_SCHEDULE]:  {
        text: 'Request schedule change',
        clickHandler: this.showVacateSchedule.bind(this)
      },
      [CALENDAR_IMPORT_UTILITY]: {
        text: 'Calendar import utility',
        clickHandler: this.showImportUtility.bind(this)
      },
      [PAST_BOOKING_REQUESTS]: {
        text: 'My booking requests',
        clickHandler: this.showPastBookings.bind(this)
      },
      [CHANGE_PASSWORD_REQUESTS]: {
        text: 'Change password',
        clickHandler: this.showChangePassword.bind(this)
      },
      [CONTACT_US_PAGE]: {
        text: 'Support',
        clickHandler: this.showContactUs.bind(this)
      },
      [SIGNOUT]: {
        text: 'Sign out',
        clickHandler: this.signout.bind(this)
      }
    };
  }

  getMenuItemsForUser = () => {
    const { profileInfo } = this.props;
    const defaultMenuItems = [this.menuList[CONTACT_US_PAGE], this.menuList[SIGNOUT]];

    if (!profileInfo || !profileInfo.scope || !Array.isArray(profileInfo.scope)) {
      return [ ...defaultMenuItems ];
    }
    let menuItems = [this.menuList[CHANGE_PASSWORD_REQUESTS]];
    Object.keys(USER_MENU).forEach((role) => {
      if (profileInfo.scope.includes(role)) {
        const menuItemsForRole = USER_MENU[role].map((item) => {
          return this.menuList[item];
        });
        menuItems = menuItems.concat(menuItemsForRole);
      }
    });
    // menuItems.push(this.menuList[SIGNOUT]);
    menuItems = menuItems.concat(defaultMenuItems);
    return menuItems;
  }

  showHome = () => {
    this.props.history.push(SELECT_ROOMS);
  }

  showLogin = () => {
    this.props.history.push(LOGIN);
  }

  showChangePassword = () => {
    this.props.history.push(CHANGE_PASSWORD);
  }

  showVacateSchedule = () => {
    this.props.history.push(SCHEDULE_CHANGE);
  }

  showPastBookings = () => {
    this.props.history.push(PAST_BOOKINGS);
  }

  showImportUtility = () => {
    this.props.history.push(IMPORT_UTILITY);
  }

  showContactUs = () => {
    this.props.history.push(CONTACT_US);
  }

  signout = () => {
    this.props.requestLogout();
  }

}