import {
  AUTH_DETAILS_REQUEST,
  AUTH_DETAILS_LOADING,
  AUTH_DETAILS_SUCCESS,
  AUTH_DETAILS_ERROR,
  LOGIN_REQUEST,
  LOGIN_LOADING,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  REFRESH_TOKEN_REQUEST,
  SET_DECODED_TOKEN,
  LOGOUT_REQUEST,
  LOGOUT
} from './auth.actionTypes';

export const requestAuthDetails = () => {
  return {
    type: AUTH_DETAILS_REQUEST
  };
};

export const authDetailsLoading = () => {
  return {
    type: AUTH_DETAILS_LOADING
  };
};

export const authDetailsSuccess = (data) => {
  return {
    type: AUTH_DETAILS_SUCCESS,
    data: data
  };
};

export const authDetailsError = (error) => {
  return {
    type: AUTH_DETAILS_ERROR,
    error: error
  };
};

export const requestLogin = (tokens) => {
  return {
    type: LOGIN_REQUEST,
    tokens: tokens
  };
};

export const loginLoading = () => {
  return {
    type: LOGIN_LOADING
  };
};

export const loginSuccess = (data) => {
  return {
    type: LOGIN_SUCCESS,
    data: data
  };
};

export const loginError = (error) => {
  return {
    type: LOGIN_ERROR,
    error: error
  };
};

export const requestRefreshToken = (refreshToken) => {
  return {
    type: REFRESH_TOKEN_REQUEST,
    refreshToken
  };
};

export const refreshTokenSuccess = (data) => {
  return {
    type: LOGIN_SUCCESS,
    data: data
  };
};

export const requestLogout = () => {
  return {
    type: LOGOUT_REQUEST
  };
};

export const logout = () => {
  return {
    type: LOGOUT
  };
};

export const decodeAuthToken = (tokens) => {
  try {
    const encodedStringArr = tokens.access_token.split('.');
    if (encodedStringArr.length > 1) {
      const decodedString = atob(encodedStringArr[1]);
      const response = JSON.parse(decodedString);
      return {
        type: SET_DECODED_TOKEN,
        data: response
      };
    }
  } catch (e){
    return {
      type: LOGOUT
    };
  }
};
