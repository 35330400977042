import React from 'react';
import BaseFooterComponent from '../branding.base';
import getClientConfig from '../../../utils/getClientConfig/getClientConfig';

class DesktopFooter extends BaseFooterComponent {
  render() {

    const clientConfig = getClientConfig() || {};
    const brandingImages = clientConfig.brandingImages || [];
    let images;
    if (brandingImages.length > 0 ) {
      images = brandingImages.map((ImageComponent, i) => <ImageComponent key={i} className='logo'/> );
      return (
        <div className='branding'>{images}</div>
      );
    }
    return null;
  }
}
export default DesktopFooter;